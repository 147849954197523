.custom-primary-cta-button { 
    @include mix-button--primary();
    @include mix-button-is-slim();
    
    // Needed for the whole button to be the link
    padding: 0 !important;
    
    a {
        padding: .5em 1.3em;
        display: block;
        color: inherit;
    }
}