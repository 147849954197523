.breadcrumbs {
    position: relative;
    z-index: 20;
}

.breadcrumbs__list {
    display: flex;
    align-items: center;
    list-style-type: none;
    font-size: 0.75rem;
    padding: 1rem 0;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    @include hide-scrollbars;
}

.breadcrumbs__item {
    scroll-snap-align: start;
    flex: none;
    svg {
        color: black;
        margin: 0 1em;
        @include mix-content-min-width($breakpoint-w-lion) {
            margin: 0 0.4em;
        }
    }
    &:first-child {
        padding-left: $gutter;
        @media (min-width: $breakpoint-w-elephant) {
            padding-left: $gutter * 1.5;
        }
    }
    &:last-child {
        padding-right: $gutter;
        @media (min-width: $breakpoint-w-elephant) {
            padding-right: $gutter * 1.5;
        }
    }
}

.breadcrumbs__link {
    color: inherit;
    &:hover {
        color: black;
    }
}
