.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 30;
    pointer-events: none;
    &.fade-enter,
    &.fade-enter-active {
        pointer-events: auto;
    }
}
