$product-selector-height: 70px;
.product-selector {
    position: relative;
    width: 100%;
    height: $product-selector-height;
    z-index: 10;

    @include mix-boxes__background;
}

.product-selector__select {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    box-shadow: 0px 0px 22px 0px rgba($black, 0);
    transition: all 0ms $easeInOutQuad;
    will-change: all;

    .product-selector--selectable & {
        cursor: pointer;
    }

    .product-selector--open & {
        transition-duration: 150ms;
        width: 105%;
        height: auto;
        @include themer($theme-gyldendal) {
            border-radius: 5px;
        }
        @include mix-content-min-width($breakpoint-w-dog) {
            width: 108%;
        }
        box-shadow: 0px 0px 22px 0px rgba($black, 0.45);
    }
}

.product-selector__item {
    @include reset__button("true");
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0px;
    transition: all 0ms $easeInOutQuad;
    will-change: all;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    text-align: left;
    &:focus-visible .product-selector__item-inner {
        outline: solid 2px $black;
        outline-offset: -3px;
    }

    .product-selector--open & {
        height: $product-selector-height;
        transition-duration: 150ms;
    }

    &:last-child {
        border-bottom-width: 0;
    }
}

.product-selector__item--current {
    height: $product-selector-height;
}

.product-selector__item-inner {
    @include mix-clearfix-table;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 36px 0 18px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @include mix-boxes__background;
    @include mix-boxes__border-color;
    border-bottom-width: 1px;
    border-bottom-style: solid;

    transition: background 220ms $easeInQuad;

    .product-selector__item--current & {
        @include mix-boxes__background--active;
    }

    .product-selector--selectable & {
        &:hover {
            @include mix-boxes__background--hover;
        }
    }
}

.product-selector-label__type {
    @include mix-theme-headline-color;
    font-weight: $fontweight-gu-bold;
}

.product-selector-label__version {
    @include mix-theme-label-text();
}

.product-selector__item-status {
    color: $color-gu-green;
    float: right;
    font-weight: normal;
}

.product-selector__arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 18px;
    display: block;

    width: 10px;
    height: 10px;
    border-top: 1px solid $color-gu-dark-grey;
    border-left: 1px solid $color-gu-dark-grey;
    transform: translateY(-3px) rotate(225deg);
    cursor: pointer;

    @include themer($theme-hansreitzel) {
        border-color: $color-hr-blue;
    }

    @include themer($theme-munksgaard) {
        border-color: $color-mg-blue;
    }

    .product-selector--open & {
        transform: translateY(0) rotate(45deg);
    }
}
