

.error-access-denied{
    margin-bottom: $gutter*2;

    p+p{
        margin-top: 1em;
    }
    .button{
        margin: .5em 1em;
    }
    &__login{
        @extend .content-trumpet !optional;
        text-transform: none;
        font-size: 14px;
    }

}

.error-profile-access {
    margin-bottom: $gutter*2;

    p + p {
        margin-top: 1em;
    }

    .button {
        margin: .5em 1em;
        width: 300px;
    }

    &__login {
        @extend .content-trumpet !optional;
        text-transform: none;
        font-size: 14px;
    }
}

.error-access-denied__image{
    max-width: 820px;
    margin: 0 auto;
    padding-bottom: 25%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-image: url("/dist/assets/gfx/500_all.png");
    @media (min-width: $breakpoint-w-rhino) {
        background-size: auto 250px;
        padding-bottom: 250px;
    }

    @include themer($theme-gyldendal){
        background-image: url("/dist/assets/gfx/AccessDenied_gu.png");
    }
    @include themer($theme-hansreitzel){
        background-image: url("/dist/assets/gfx/AccessDenied_hr.png");
    }
    @include themer($theme-munksgaard){
        background-image: url("/dist/assets/gfx/AccessDenied_mg.png");
    }

}
