@use "sass:math";

.article-quote {
    position: relative;
    color: $color-gu-fade-25;
    padding: 0 2em;
    display: inline-block;
    font-weight: $fontweight-gu-light;
    line-height: 1.5em;
    .article-quote-byline {
        margin-left: 0.5em;
    }
}
.article-quote-large {
    font-size: math.div(22, 16) * 1em;
    @media (min-width: $breakpoint-w-dog) {
        font-size: math.div(30, 16) * 1em;
    }
    position: relative;
    color: $color-gu-fade-25;
    margin: 0.5em 0 0.5em;
    display: inline-block;
    font-weight: $fontweight-gu-light;
    line-height: 1.3em;
    text-indent: $gutter * 2.3;
    &:before {
        position: absolute;
        top: 0.135em;
        //left:-.25em;
        left: -($gutter * 2.35);
        content: "“";
        font-weight: $fontweight-gu-bold;
        font-size: 95px;
        @include mix-theme-primary-color-props(color);
        opacity: 1;
        z-index: -1;
        font-family: $font-family-gotham;
    }

    @media (min-width: $breakpoint-w-wolf) {
        text-indent: 0;
    }
}

.article-quote-byline {
    @include mix-theme-primary-color-props(color);
    display: inline-block;
}
