.image-text-cta {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
}

.image-text-cta-inner {
    display: flex;
    flex-direction: column;
    padding: 0;

    @include mix-content-min-width($breakpoint-w-cat) {
        flex-direction: row;

        .image-text-cta--isDirectionFlipped & {
            flex-direction: row-reverse;
        }
    }
}

.image-text-cta__image-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 $gutter-small;
    margin-bottom: $gutter;

    @include mix-content-min-width($breakpoint-w-cat) {
        padding: 0 $gutter;
        margin-bottom: 0;
    }

    img {
        width: 100%;
        height: auto;
    }
}

.image-text-cta__text-wrapper {
    padding: 0 $gutter-small;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include mix-content-min-width($breakpoint-w-cat) {
        padding: 0 $gutter;
        width: 50%;
    }
    @include mix-content-min-width($breakpoint-w-lion) {
        width: 33.3333333%;
    }
}
