.profile-unilogin-info__head {
    @include mix-clearfix-block();
}

.profile-unilogin-info__head__logo {
    float: left;
    img {
        user-select: none;
        margin-top: 2px;
        height: 20px;
        width: auto;
        display: block;
    }
}

.profile-unilogin-info__head__status {
    float: right;
}

.profile-unilogin-info__indicator {
    display: inline-block;
    width: 1em;
    height: 1em;
    background-color: gray;
    border-radius: 50%;
    margin-top: -.15em;
    vertical-align: middle;
    margin-right: .3em;
    transform: scale(.7);
    background-color: $color-gu-red;

    &.indicator--on {
        background-color: $color-gu-green;
    }
}

.profile-unilogin-info__content ul,
.profile-unilogin-info__list {

    list-style-type: none;
}

.profile-unilogin-info__content li,
.profile-unilogin-info__listitem {
    padding: 0;
    padding-left: 2em;
    position: relative;
    margin: .3em 0;

}

.profile-unilogin-info__content li i,
.profile-unilogin-info__content li em,
.list-indicator {
    position: absolute;
    top: 0.02em;
    left: 0;
    line-height: 0;
    width: 1.4em;
    height: 1.4em;
    border-radius: 50%;

    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        width: 1em;
        height: 2px;
        border-radius: 1px;
        background-color: #fff;

    }

}

.profile-unilogin-info__content li em,
.list-indicator--false {
    background-color: $color-gu-red;
    &:before,
    &:after {
        top: 50%;
        left: 50%;
    }
    &:before {
        transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}

.profile-unilogin-info__content li i,
.list-indicator--true {
    background-color: $color-gu-green;
    &:before,
    &:after {
        top: 70%;
        left: 45%;
        transform-origin: 0 50%;
    }
    &:before {
        width: .8em;
        transform: rotate(-55deg);
    }
    &:after {
        width: .5em;
        transform: rotate(-130deg);
    }
}
