.content-seperator{
    overflow: hidden;
    margin: $gutter*.7 0;
    text-align:center;
    span{
       position: relative;
        display: inline-block;
        padding: 0 1em;
        &:before, &:after{
            position: absolute;
            content:"";
            display: block;
            width: 600px;
            height:1px;
            top:50%;
            background-color: $color-gu-fade-20;
        }
        &:before{
            left:100%;
        }
        &:after{
            right:100%;
        }
    }

}