@use "sass:math";

.product-picker-typelist__navigation {
    background-color: $color-gu-dark-grey;
    width: 25px;
    display: block;

    a {
        display: block;
        width: 100%;
        text-align: center;
        padding: 0.4em 0 0.2em;
        background-color: darken($color-gu-dark-grey, 10);
        svg {
            stroke: #fff;
            fill: transparent;
        }
    }

    @include themer($theme-gyldendal) {
        border-bottom-left-radius: 4px;
    }
}

.product-picker-typelist__content {
    flex-grow: 1;
}

.product-picker-typelist__item {
    background-color: $lightgrey;
    display: flex;
    padding: math.div($gutter, 2);
    flex-wrap: nowrap;
    align-items: center;

    .left {
        flex-grow: 1;
    }
    .right {
        text-align: right;
    }
    & + .product-picker-typelist__item {
        border-top: 1px solid $color-gu-fade-20;
    }
}

.product-picker-typelist__item__Label {
    @include mix-theme-bold-bodyfont();
    @include mix-theme-headline-color();
}
.product-picker-typelist__item__summary {
    display: block;
}

.product-picker-typelist__selection {
    display: flex;
}
