@use "sass:math";

.hero-rich-text {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    border-bottom: 1px solid $color-gu-fade-20;

    .main-content & {
        border-top: 1px solid $color-gu-fade-20;
    }

    @media (min-width: $breakpoint-header) {
        flex-direction: row;

        &--is-direction-flipped {
            flex-direction: row-reverse;
        }
    }

    &--has-bg-color {
        border: none !important;
    }

    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

.hero-rich-text__picture-wrapper {
    overflow: hidden;
    line-height: 0; // Remove ghost space

    @media (min-width: $breakpoint-header) {
        position: relative;
        z-index: 2;
        width: 50%;
        min-height: 350px;
    }
}

.hero-rich-text__picture {
    @media (min-width: $breakpoint-header) {
        position: absolute;
        width: auto;
        height: 100%;
        left: 50%;

        img {
            transform: translate(-50%, 0);
            max-height: 100%;
            max-width: none;
        }
    }

    @media (min-width: $breakpoint-w-elephant + 730px) {
        position: absolute;
        height: 100%;
        width: auto;
        max-width: none;
        top: 50%;
        left: 0;

        img {
            transform: translate(0, -50%);
            max-height: none;
            max-width: 100%;
        }
    }
}

.hero-rich-text__content-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @media (min-width: $breakpoint-header) {
        width: 50%;
    }

    .hero-rich-text--is-direction-flipped & {
        justify-content: flex-start;
    }
}

.hero-rich-text__content {
    flex-grow: 1;
    position: relative;
    padding: 0 $gutter-small;

    @include mix-content-min-width($breakpoint-w-cat) {
        padding: 0 $gutter;
    }
    @media (min-width: $breakpoint-header) {
        z-index: 5;
        display: table;
        max-width: math.div($max-width, 2);
    }

    .hero-rich-text--has-image & {
        @media (min-width: $breakpoint-header) {
            height: 1px; // for FF
            min-height: 350px;
        }
    }
}

.hero-rich-text__content-inner {
    display: table-cell;
    vertical-align: middle;
    padding: $gutter 0;

    @include mix-content-min-width($breakpoint-w-cat) {
        padding: 40px;
        padding-left: 0;
    }
    @include mix-content-min-width($breakpoint-w-lion) {
        padding: 50px;
        padding-left: 0;
    }
}

.hero-rich-text__gradient {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -20%;
    left: 100%;
    margin-left: -1px; // To avoid odd px line
    background: linear-gradient(to right, #fff, transparent);

    @media (min-width: $breakpoint-header) {
        display: block;
    }

    &[class*="--text"] {
        background: linear-gradient(to right, currentColor, transparent);
    }
    .hero-rich-text--is-direction-flipped & {
        right: 100%;
        left: -20%;
        margin-right: -1px; // To avoid odd px line
        background: linear-gradient(to left, #fff, transparent);

        &[class*="--text"] {
            background: linear-gradient(to left, currentColor, transparent);
        }
    }
}

.hero-rich-text__trumpet {
    @include mix-theme-trumpet();

    @include themer($theme-gyldendal) {
        @include mix-subtheme-primary-color-props("color");
    }
}

.hero-rich-text__title {
    color: #000;
    //GU
    @include themer($theme-gyldendal) {
        font-size: 24px;
        margin: 0.4em 0;

        @include mix-content-max-width($breakpoint-w-cat) {
            @include mix-theme-like-h2();
        }
        @include mix-content-min-width($breakpoint-w-cat) {
            @include mix-gu-header-styling();
            font-size: 2rem;
            line-height: 1.25;
            font-weight: $fontweight-gu-light;
            margin: 0.4em 0;

            span {
                color: #000;
            }
        }
    }
    //munk
    @include themer($theme-munksgaard) {
        @include mix-theme-like-h2();
        margin-bottom: 0.4em;
        line-height: 1.3em;

        @include mix-content-min-width($breakpoint-w-cat) {
            margin-bottom: 0.2em;
        }
        @include mix-content-min-width($breakpoint-w-lion) {
            @include mix-theme-like-h1();
            font-weight: $fontweight-mg-light;
        }
    }
    //hans
    @include themer($theme-hansreitzel) {
        @include mix-theme-like-h2();
        margin-bottom: 0.6em;
        line-height: 1.3em;

        @include mix-content-min-width($breakpoint-w-cat) {
            margin-bottom: 0.4em;
        }
        @include mix-content-min-width($breakpoint-w-lion) {
            @include mix-theme-like-h1();
            font-weight: $fontweight-hr-regular;
        }
    }
}

.hero-rich-text__cta {
    margin-top: 1em;
}

.hero-rich-text__cta a {
    @include mix-button--primary();
    @include mix-button-is-slim();
}
