.select-input{
    position: relative;
    width: 100%;
    &:after{
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 5px;
        display: block;
        z-index: 1;
        background-color: $lightgrey;
        border: 1px solid $color-gu-fade-20;
    }

    select{
        position: relative;
        z-index:2;
        background-color: transparent;
        border: none;
        width: 100%;
        height: 40px;
        font-size: 16px;
        margin: 0;
        padding: 0 1.5em;
        font-weight: $fontweight-gu-bold;
    }
}

.select-input__currentselection{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}