.profile-my-digital-products-list-desktop {
    display: none;
    @include mix-content-min-width($breakpoint-w-lion) {
        display: block;
    }
}
.profile-my-digital-products-list-desktop__headers {
    grid-area: headers;
    display: grid;
    grid-template-columns: 80px 1fr 1fr 1fr 1fr;
    row-gap: 0.5rem;
    column-gap: 2rem;
    padding: 0.625rem 2rem;

    border: 1px solid $color-gu-fade-20;
    border-radius: $border-radius $border-radius 0px 0px;

    background-color: $color-gu-fade-00;
    color: $color-gu-dark-grey;

    .profile-my-digital-products-list-desktop__header {
        font-size: 0.875rem;
        font-weight: $fontweight-gu-light;
    }
}
.profile-my-digital-products-list-item-desktop {
    border: 1px solid $color-gu-fade-20;
    border-top: none;
    color: $dark-grey;
    font-size: 0.875rem;
    &:hover {
        background-color: lighten($color-gu-fade-00, 1%);
    }

    &:nth-of-type(odd) {
        background-color: $color-gu-fade-00;
        &:hover {
            background-color: darken($color-gu-fade-00, 1%);
        }
    }
    &:last-child {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
}
.profile-my-digital-products-list-item-desktop__row {
    color: inherit;
    grid-area: item;
    display: grid;
    grid-template-columns: 80px 1fr 1fr 1fr 1fr;
    row-gap: 0.5rem;
    column-gap: 2rem;
    padding: 2rem 2rem;

    [role="cell"] {
        @include mix-theme-bold-bodyfont;
    }
}
.profile-my-digital-products-list-item-desktop__owner {
    @include mix-theme-regular-bodyfont;
    font-size: 0.75rem;
}
.profile-my-digital-products-list-item-desktop__image {
    width: 80px;
    height: 120px;
    object-fit: contain;
    object-position: top;
    filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.08)) drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.16));
}

.profile-my-digital-products-list-item-desktop__link {
    color: inherit;
}
