// RADIO BUTTONS
.radio {
    cursor: pointer;
    display: inline-block;
    padding-left: 2em;
    position: relative;

    &.radio--block {
        display: block;
        margin: 1em 0;
    }
    &[disabled] {
        opacity: 0.55;
        pointer-events: none;
        cursor: default;
    }
}

.radio__input {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    opacity: 0;
}
.radio__input:checked ~ .radio__indicator:before,
.radio__indicator--selected .radio__indicator:before {
    background-color: $color-gu-orange;

    @include themer($theme-hansreitzel) {
        background-color: $color-hr-red;
    }
    @include themer($theme-munksgaard) {
        background-color: $color-cta;
    }
}

.radio__indicator--selected .radio__indicator {
    @include mix-primary-theme-border(1px, solid);
}

.radio__indicator {
    display: block;
    position: absolute;
    top: -0.05em;
    left: 0;
    width: 1.5em;
    height: 1.5em;
    border: 1px solid $color-gu-fade-20;
    background-color: #fff;
    border-radius: 50%;
    vertical-align: middle;
    &:before {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: block;
        top: 0;
        left: 0;
        transform: scale(0.5);
        position: absolute;
    }
    .radio__input:focus ~ & {
        box-shadow: 0 0 5px $color-gu-orange;
    }
}
