.add-to-basket {
    position: relative;
    display: flex;
    z-index: 2;
}

.add-to-basket__input {
    position: relative;
    flex-basis: 70px;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 50%;

    .add-to-basket__hasLabel & {
        flex-basis: 70px;
    }

    input {
        position: relative;
        width: 100%;
        height: 50px;
        padding-bottom: 4px;
        color: $white;
        text-align: center;
        font-size: 16px;
        font-weight: $fontweight-gu-medium;
        line-height: 50px;
        border: none;
        background-color: $color-gu-orange-light;
        z-index: 1;
        outline: none;

        @include mix-content-min-width($breakpoint-w-dog) {
            height: 40px;
            line-height: 40px;
        }

        @include themer($theme-gyldendal) {
            border-radius: 5px 0 0 5px;
        }

        @include themer($theme-hansreitzel) {
            border-radius: 0; // Needed on mobile
            background-color: $color-hr-red-light;
        }

        @include themer($theme-munksgaard) {
            border-radius: 0; // Needed on mobile
            background-color: $color-mg-green-dark;
        }

        .add-to-basket__is-open & {
            border-radius: 0;
        }
    }
}

.add-to-basket__input__increment,
.add-to-basket__input__decrement {
    position: absolute;
    left: 0;
    width: 100%;
    height: 25px;
    color: $white;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    transform: translateY(0%);
    transition: transform 250ms $easeOutQuint;
    cursor: pointer;
    background-color: $color-gu-orange;

    @include themer($theme-hansreitzel) {
        background-color: $color-hr-red;
    }

    @include themer($theme-munksgaard) {
        background-color: $color-mg-green-dark;
        color: $color-mg-blue;
    }
}

.add-to-basket__input__increment {
    top: 0;

    @include themer($theme-gyldendal) {
        border-radius: 5px 5px 0 0;
    }
    .add-to-basket__is-open & {
        transform: translateY(-100%);
    }
}

.add-to-basket__input__decrement {
    bottom: 0;

    @include themer($theme-gyldendal) {
        border-radius: 0 0 5px 5px;
    }
    .add-to-basket__is-open & {
        transform: translateY(100%);
    }
}

.add-to-basket__button {
    transition: opacity 250ms $easeOutQuint;
    overflow: hidden;
    position: relative;
    border-radius: $border-radius;
    @include mix-button-is-slim();
    @include mix-button-buy;
    max-height: 40px;

    margin-left: 1rem;

    svg {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-55%, -50%);
        * {
            stroke: $white;
            stroke-width: 1px;
        }

        @include mix-content-min-width($breakpoint-w-dog) {
            width: 28px;
            height: 28px;
        }

        @include themer($theme-munksgaard) {
            // stroke: $color-mg-blue;
        }
    }

    .loader {
        span,
        &:before,
        &:after {
            background-color: $white;

            @include themer($theme-munksgaard) {
                background-color: $color-mg-blue;
            }
        }
    }

    &:disabled {
        opacity: 0.8;
    }
}
