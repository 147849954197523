$dp-border-color: #aeaeae;
$dp-background-color: #f0f0f0;
$dp-selected-color: $color-gu-orange;
$dp-highlighted-color: $color-gu-dark-grey;
$dp-muted-color: #ccc;
$dp-text-color: #000;

$dp-font-size: 0.8rem;
$dp-border-radius: 0.3rem;
$dp-item-size: 1.7rem;
$dp-day-margin: 0.166rem;
$dp-triangle-size: 8px;
$dp-datepicker__margin: 0.4rem;
$dp-navigation-size: 0.45rem;

%triangle-arrow {
    margin-left: -$dp-triangle-size;
    position: absolute;

    &,
    &::before {
        box-sizing: content-box;
        position: absolute;
        border: $dp-triangle-size solid transparent;

        height: 0;
        width: 1px;
    }

    &::before {
        content: "";
        z-index: -1;
        border-width: $dp-triangle-size;

        left: -$dp-triangle-size;
        border-bottom-color: $dp-border-color;
    }
}

%triangle-arrow-up {
    @extend %triangle-arrow;

    top: 0;
    margin-top: -$dp-triangle-size;

    &,
    &::before {
        border-top: none;
        border-bottom-color: $dp-background-color;
    }

    &::before {
        top: -1px;
        border-bottom-color: $dp-border-color;
    }
}

%triangle-arrow-down {
    @extend %triangle-arrow;

    bottom: 0;
    margin-bottom: -$dp-triangle-size;

    &,
    &::before {
        border-bottom: none;
        border-top-color: #fff;
    }

    &::before {
        bottom: -1px;
        border-top-color: $dp-border-color;
    }
}

.react-datepicker {
    // font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-family: inherit;
    font-size: $dp-font-size;
    background-color: #fff;
    color: $dp-text-color;
    border: 1px solid $dp-border-color;
    border-radius: $dp-border-radius;
    display: inline-block;
    position: relative;
}

.react-datepicker__triangle {
    position: absolute;
    left: 50px;
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle {
    @extend %triangle-arrow-up;
}

.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle {
    @extend %triangle-arrow-down;
}

.react-datepicker__tether-element-attached-bottom.react-datepicker__tether-element {
    margin-top: -20px;
}

.react-datepicker__header {
    text-align: center;
    background-color: $dp-background-color;
    border-bottom: 1px solid $dp-border-color;
    border-top-left-radius: $dp-border-radius;
    border-top-right-radius: $dp-border-radius;
    padding-top: 8px;
    position: relative;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll {
    display: inline-block;
    margin: 0 2px;
}

.react-datepicker__current-month {
    text-transform: capitalize;
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: $dp-font-size * 1.18;
}

.react-datepicker__navigation {
    line-height: $dp-item-size;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    width: 0;
    border: $dp-navigation-size solid transparent;
    background-color: transparent;
    z-index: 1;

    &-icon {
        display: none;
    }

    &--previous {
        left: 10px;
        border-right-color: $dp-muted-color;
        font-size: 0;

        &:hover {
            border-right-color: darken($dp-muted-color, 10%);
        }
    }

    &--next {
        right: 10px;
        border-left-color: $dp-muted-color;
        font-size: 0;
        &:hover {
            border-left-color: darken($dp-muted-color, 10%);
        }
    }

    &--years {
        position: relative;
        top: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;

        &-previous {
            top: 4px;
            border-top-color: $dp-muted-color;

            &:hover {
                border-top-color: darken($dp-muted-color, 10%);
            }
        }

        &-upcoming {
            top: -4px;
            border-bottom-color: $dp-muted-color;

            &:hover {
                border-bottom-color: darken($dp-muted-color, 10%);
            }
        }
    }
}

.react-datepicker__month-container {
    display: inline;
    float: left;
}

.react-datepicker__month {
    margin: $dp-datepicker__margin;
    text-align: center;
}

.react-datepicker__week-number {
    color: $dp-muted-color;
    display: inline-block;
    width: $dp-item-size;
    line-height: $dp-item-size;
    text-align: center;
    margin: $dp-day-margin;
}

.react-datepicker__day-name,
.react-datepicker__day {
    text-transform: capitalize;
    color: $dp-text-color;
    display: inline-block;
    width: $dp-item-size;
    line-height: $dp-item-size;
    text-align: center;
    margin: $dp-day-margin;
}

.react-datepicker__day {
    cursor: pointer;

    &:hover {
        border-radius: $dp-border-radius;
        background-color: $dp-background-color;
    }

    &--today {
        font-weight: bold;
    }

    &--highlighted {
        border-radius: $dp-border-radius;
        background-color: $dp-highlighted-color;
        color: #fff;

        &:hover {
            background-color: darken($dp-highlighted-color, 5%);
        }
    }

    &--selected,
    &--in-selecting-range,
    &--in-range {
        border-radius: $dp-border-radius;
        background-color: $dp-selected-color;
        color: #fff;

        &:hover {
            background-color: darken($dp-selected-color, 5%);
        }
    }

    &--keyboard-selected {
        border-radius: $dp-border-radius;
        background-color: lighten($dp-selected-color, 10%);
        color: #fff;

        &:hover {
            background-color: darken($dp-selected-color, 5%);
        }
    }

    &--in-selecting-range:not(&--in-range) {
        background-color: rgba($dp-selected-color, 0.5);
    }

    &--in-range:not(&--in-selecting-range) {
        .react-datepicker__month--selecting-range & {
            background-color: $dp-background-color;
            color: $dp-text-color;
        }
    }

    &--disabled {
        cursor: default;
        color: $dp-muted-color;

        &:hover {
            background-color: transparent;
        }
    }
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;

    input {
        padding: 0;
    }
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view {
    border: 1px solid transparent;
    border-radius: $dp-border-radius;

    &:hover {
        cursor: pointer;

        .react-datepicker__year-read-view--down-arrow,
        .react-datepicker__month-read-view--down-arrow {
            border-top-color: darken($dp-muted-color, 10%);
        }
    }

    &--down-arrow {
        @extend %triangle-arrow-down;
        border-top-color: $dp-muted-color;
        float: right;
        margin-left: 20px;
        top: 8px;
        position: relative;
        border-width: $dp-navigation-size;
    }
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown {
    background-color: $dp-background-color;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    text-align: center;
    border-radius: $dp-border-radius;
    border: 1px solid $dp-border-color;

    &:hover {
        cursor: pointer;
    }

    &--scrollable {
        height: 150px;
        overflow-y: scroll;
    }
}

.react-datepicker__year-option,
.react-datepicker__month-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:first-of-type {
        border-top-left-radius: $dp-border-radius;
        border-top-right-radius: $dp-border-radius;
    }

    &:last-of-type {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-bottom-left-radius: $dp-border-radius;
        border-bottom-right-radius: $dp-border-radius;
    }

    &:hover {
        background-color: $dp-muted-color;

        .react-datepicker__navigation--years-upcoming {
            border-bottom-color: darken($dp-muted-color, 10%);
        }

        .react-datepicker__navigation--years-previous {
            border-top-color: darken($dp-muted-color, 10%);
        }
    }

    &--selected {
        position: absolute;
        left: 15px;
    }
}

.react-datepicker__close-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    height: 0;
    outline: 0;
    padding: 0;
    vertical-align: middle;

    &::after {
        background-color: $dp-selected-color;
        border-radius: 50%;
        bottom: 0;
        box-sizing: border-box;
        color: #fff;
        content: "\00d7";
        cursor: pointer;
        font-size: 12px;
        height: 16px;
        width: 16px;
        line-height: 1;
        margin: -8px auto 0;
        padding: 2px;
        position: absolute;
        right: 7px;
        text-align: center;
        top: 50%;
    }
}

.react-datepicker__today-button {
    background: $dp-background-color;
    border-top: 1px solid $dp-border-color;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
}

.react-datepicker__tether-element {
    z-index: 2147483647;
}

.react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;

    .react-datepicker__day-name,
    .react-datepicker__day {
        width: 3rem;
        line-height: 3rem;
    }

    // Resize for small screens
    @media (max-width: 400px), (max-height: 550px) {
        .react-datepicker__day-name,
        .react-datepicker__day {
            width: 2rem;
            line-height: 2rem;
        }
    }

    .react-datepicker__current-month {
        font-size: $dp-font-size * 1.8;
    }

    .react-datepicker__navigation {
        border: 1.8 * $dp-navigation-size solid transparent;
    }

    .react-datepicker__navigation--previous {
        border-right-color: $dp-muted-color;

        &:hover {
            border-right-color: darken($dp-muted-color, 10%);
        }
    }

    .react-datepicker__navigation--next {
        border-left-color: $dp-muted-color;

        &:hover {
            border-left-color: darken($dp-muted-color, 10%);
        }
    }
}

.datepicker-field {
    position: relative;
    z-index: 1;
}

input[class*="button"] {
    &::placeholder {
        color: rgba(255, 255, 255, 0.6);
    }
}
