.navigation-mobile {
    position: fixed;
    inset: $navigation-header-height 0 0;
    transform: translateX(-100%);
    transition: transform 500ms ease;
    background-color: $white;
    @include mix-theme-headline-color;
    padding: 3rem $gutter;
    max-width: 400px;
    overflow-y: auto;
    &--isOpen {
        transform: translateX(0);
    }

    @include mix-content-min-width($breakpoint-navigation) {
        display: none;
    }

    @media (min-width: $breakpoint-w-elephant) {
        padding: 3rem $gutter * 1.5;
    }
}

.navigation-mobile__links {
    display: flex;
    flex-direction: column;
    list-style-type: none;

    .navigation-header-item {
        font-size: 1rem;
        text-transform: none;
        margin-bottom: 0.75rem;
        svg {
            margin-left: 0.5em;
            margin-bottom: -1px;
            fill: none;
            stroke: currentColor;
        }
        path {
            stroke-width: 1px;
        }
        &:hover path {
            stroke-width: 2px;
        }
        &--isActive,
        &:hover {
            path {
                stroke-width: 2px;
            }
            @include themer($theme-gyldendal) {
                font-weight: $fontweight-gu-medium;
            }
            @include themer($theme-hansreitzel) {
                font-weight: $fontweight-hr-medium;
            }
            @include themer($theme-munksgaard) {
                font-weight: $fontweight-mg-regular;
            }
        }
    }
}

.navigation-mobile__title {
    display: inline-block;
    font-size: 2rem;
    margin-bottom: 1rem;
    @include themer($theme-gyldendal) {
        font-weight: $fontweight-gu-medium;
    }
    @include themer($theme-hansreitzel) {
        font-weight: $fontweight-hr-medium;
    }
    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-regular;
    }
}

.navigation-mobile__back-button {
    @include button-reset;
    position: absolute;
    top: calc(1rem + #{$navigation-header-height});
    left: $gutter;
    z-index: 10;
    font-size: 1rem;
    width: 1em;
    transform: translateX(-3rem);
    transition: transform 300ms ease;
    .navigation-header--mobileMenuIsOpen & {
        transform: translateX(0);
    }

    @media (min-width: $breakpoint-w-elephant) {
        left: $gutter * 1.5;
    }

    svg {
        width: 1em;
        height: 1.4em;
        transform: scaleX(-1);
    }
    @include mix-content-min-width($breakpoint-navigation) {
        display: none;
    }
    @include themer($theme-hansreitzel) {
        color: $black;
    }
}
