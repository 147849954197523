.checkout-header {
    position: relative;
    width: 100%;
    height: $gutter*2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-gu-fade-40;

    @include themer($theme-hansreitzel) {
        background-color: $color-hr-fade-10;
    }
    @include themer($theme-munksgaard) {
        background-color: $color-mg-blue-light;
    }
    @media (min-width: $breakpoint-w-cat) {
        height: 100px;
    }
    @media (max-width: $breakpoint-w-dog - 1px) {
        height: auto;
        display: block;
    }
    
}

.checkout-header__logo {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: $breakpoint-w-dog - 1px) {
        height: 60px;
    }
    img {
        max-height: 80%;

        @include mix-content-min-width($breakpoint-w-cat) {
            max-height: 70%;
        }
    }
}

.checkout-header__backlink {
    @include mix-button-is-slim;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    font-weight: $fontweight-gu-bold;
    text-transform: uppercase;
    color: #fff;
    background-color: $color-gu-dark-grey;
    border-bottom-right-radius: 5px;

    @include themer($theme-hansreitzel) {
        background-color: $color-hr-blue;
        border-bottom-right-radius: 2px;
    }

    @include themer($theme-munksgaard) {
        background-color: $color-mg-blue-dusty;
        border-bottom-right-radius: 2px;
    }

    @include mix-content-max-width($breakpoint-w-cat) {
        display: none;
    }
}

.checkout-header__contact__item {
    padding: 20px 40px;
    position: absolute;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    
    @include themer($theme-gyldendal) {
        background-color: $color-gu-fade-25;
    }

    @include themer($theme-munksgaard) {
        background-color: $color-mg-blue-dusty-light;
    }
    @include themer($theme-hansreitzel) {
        background-color: $color-hr-beige;
    }

    @include mix-content-min-width(1100px) {
        font-size: 16px;
    }
    @media (max-width: $breakpoint-w-wolf) {
        padding: 20px 15px;
    }
    @media (max-width: $breakpoint-w-dog - 1px) {
        width: 100%;
        height: 60px;
        padding: 10px;
        position: static;
    }
    
    &__inner{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        &::hover{
            svg{
                transform: scale(1.1);
            }
        }
    }
    a{
        color: white;
    }

    &__icon {
        margin-right: .8em;
        height: 70%;
        @media (max-width: $breakpoint-w-dog - 1px) {
            height: 100%;
        }
        
        svg {            
            transition: transform 0.3s ease;
            height: 100%;
            stroke: #fff;
            @include themer($theme-hansreitzel) {
                stroke: $color-hr-red;
            }
        }
    }
    &__text{
        display: flex;
        flex-flow: column;
        height: auto;
        @media (max-width: $breakpoint-w-dog - 1px) {
            flex-flow: row;
            align-items: center;
        }
    }
    &__header {
        line-height: .9em;
        font-size: 2em;
        font-weight: 100;
        
        @include mix-content-min-width($breakpoint-w-cat) {
            font-size: 1.75em;
        }
        @include mix-content-min-width($breakpoint-w-dog) {
            font-size: 1.2em;
        }
        @include mix-content-min-width(1170px) {
            font-size: 1.75em;
        }
        @include mix-content-min-width($breakpoint-w-elephant) {
            font-size: 2em;
        }

        @include themer($theme-hansreitzel) {
            color: #000;
            font-family: $header-font-family;
        }
        @media (max-width: $breakpoint-w-dog - 1px) {
            line-height: 2em;
            font-size: 1.4em;
        }
        a {
            color: #fff;
            @include themer($theme-hansreitzel) {
                color: #000;
            }
        }
    }

    &__subtext {
        font-size: 1em;
        line-height: 1.2;
        margin-bottom: .15em;
        opacity: 0.6;
        @include themer($theme-hansreitzel) {
            color: $color-hr-red;
        }
        @media (max-width: $breakpoint-w-dog - 1px) {
            line-height: 2em;
            font-size: 1.2em;
            margin-bottom: 0px;
            margin-right: 10px;
        }
    }
}

