.products-errors {
    padding-top: 40px;

    @include mix-content-min-width($breakpoint-w-dog) {
        padding-top: 100px;
    }
}

.products-empty {
    text-align: center;

    a {
        cursor: pointer;
    }
}