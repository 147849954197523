.title-intro-rich-cta-section {
    .title {
        margin-bottom: $gutter-small;

        @include mix-content-min-width($breakpoint-w-cat) {
            margin-bottom: 20px;
        }

        &--small {
            margin-bottom: $gutter-small;
        }
    }

    > :last-child {
        margin-bottom: 0;
    }
}

.title-intro-rich-cta-section__rich-text {
    margin-bottom: 20px;
    a[name] {
        scroll-margin-top: $navigation-header-height + 85px;
    }
}
