#header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1500;
    max-width: 100vw;
    // @include navigation-push();
    button *,
    a * {
        pointer-events: none;
    }
}

.header__content {
    color: #fff;
    min-height: 50px;

    @media (min-width: $breakpoint-navigation) {
        //background: transparent;
    }
    @media (max-width: $breakpoint-header) {
        box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
    }
}

.header__content__logo {
    position: absolute;
    width: 100%;
    height: 50px;
    z-index: 2;
    text-align: center;

    @include themer($theme-gyldendal) {
        background-color: $color-gu-fade-30;
    }

    @include themer($theme-hansreitzel) {
        background-color: $color-hr-fade-10;
    }
    @include themer($theme-munksgaard) {
        background-color: $color-mg-blue-light;
    }

    img {
        max-height: 40px;
        width: auto;
        margin: 5px auto 0;
    }
    @media (min-width: $breakpoint-navigation) {
        display: none;
    }
}
