.system-details-container {
    max-width: $max-width;
    margin: auto;
    overflow: auto;
    margin-bottom: $gutter*3;
}

.system-details__img {
    order: 1;
    z-index: 1;
    width: 100%;
    height: 100%; // fix for IE
    position: relative;
    padding: 35px 0 55px; // due to the rotated images

    @include mix-content-min-width($breakpoint-w-lion) {
        padding: $gutter*2 $gutter $gutter*3;
        width: 25%;
        display: block;
        float: left;
    }
}

.system-details__img__inner {
    margin: 0 auto;
    position: relative;
    width: 25%;

    @include mix-content-min-width($breakpoint-w-cat) {
        width: 15%;
    }
    @include mix-content-min-width($breakpoint-w-lion) {
        width: 45%;
    }

    img {
        @include mix-shadow-product-img();
        transform-origin: bottom left;
        position: relative;
        left: 0;
        bottom: 0;
        display: block;

        &:first-child:nth-last-child(3) { // Item count 3 >> first
            transform: rotate(-15deg);
            z-index: 1;
            position: absolute;
        }
        &:nth-child(3):last-child { // Item count 3 >> last
            transform: rotate(15deg);
            z-index: -1;
            position: absolute;
        }

        &:first-child:nth-last-child(2) { // Item count 2 >> first
            transform: rotate(-15deg);
            z-index: 1;
            position: absolute;
        }
    }
}

.system-details__meta {
    order: 2;
    width: 100%;
    padding: $gutter $gutter-small 0 $gutter-small;

    @include mix-content-min-width($breakpoint-w-cat) {
        padding: $gutter $gutter 0 $gutter;
    }

    @include mix-content-min-width($breakpoint-w-lion) {
        float: left;
        width: 37.5%;
    }
}

.system-details__meta__title {
    @include mix-theme-like-h2();
    margin-bottom: 0;
}

.system-details__meta__subtitle {
    @include mix-theme-manchet-text();
    margin-bottom: 15px;
}

.system-details__facts-container {
    padding: $gutter $gutter-small;

    @include mix-content-min-width($breakpoint-w-cat) {
        padding: $gutter;
    }

    @include mix-content-min-width($breakpoint-w-lion) {
        width: 37.5%;
        float: right;
        display: inline-block;
    }
}

.system-details__facts__title {
    font-size: 12px;
    font-weight: $fontweight-gu-bold;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.system-details__facts__list {
    @include mix-boxes__background();
    @include mix-boxes__border();
    list-style: none;
    border-radius: 5px;
}

.system-details__facts__item {
    padding: 14px 20px 14px 15px;
    display: flex;
    justify-content: space-between;

    &:not(:first-child) {
        border-top: 1px solid;
        @include mix-boxes__border-color;
    }
}

.system-details__facts__icon {
    display: inline-block;
    width: 30px;
    height: 20px;
    margin-right: 10px;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;

    &.material {
        background-image: url("/dist/assets/gfx/iconBook.svg");
    }
    &.subsystems {
        background-image: url("/dist/assets/gfx/iconSystem.svg");
    }
    &.levels {
        background-image: url("/dist/assets/gfx/iconClass.svg");
    }
}

.system-details__facts__value {
    font-weight: 700;
    text-align: right;
    margin-left: 5px;
    flex-grow: 1;
}

