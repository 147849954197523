.main-layout {
    position: relative;

    * + .main-content {
        // e.g. If the main-content comes after a hero module
        margin-top: 0;
    }
}

#scPageExtendersForm {
    position: relative;
    z-index: 9999;
}
