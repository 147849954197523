//Utils
@mixin themer($themes...) {
    @each $theme in $themes {
        @if $site == $theme {
            @content;
        }
    }
}


@mixin themer-sub($themes...) {
    @each $theme in $themes {
        .#{$theme} & {
            @content;
        }
    }
}


@mixin themer-sub-on($themes...) {
    @each $theme in $themes {
        &.#{$theme} {
            @content;
        }
    }
}
