@import "base/reset";
@import "base/body";
@import "base/main";
@import "base/typography";
@import "base/accessibility";

@import "base/forms/radio-input";
@import "base/forms/checkbox-input";
@import "base/forms/text-input";
@import "base/forms/amount-input";
@import "base/forms/select-input";
@import "base/forms/react-super-select";
@import "base/forms/dropdown-checkbox";
@import "base/forms/datepicker";
@import "base/forms/error";

@import "base/tags";
@import "base/layout";
@import "base/button";
@import "base/color-class-themer";
@import "base/icons-css";
@import "base/boxes";
@import "base/view-more-section";
@import "base/loader";
@import "base/load-overlay";
@import "base/footer/footer";
@import "base/footer/footer-contact";
@import "base/footer/footer-blocklinks";
@import "base/footer/footer-widgets";
@import "base/content/content-base";
@import "base/content/content-seperator";
@import "base/content/content-text";
@import "base/content/content-icon-block";

@import "vendor/slick-slider";

@import "components/banners/banner-newsletter-signup"; //content todo: rename
@import "components/banners/newsletter-banner";

@import "components/articles/article-content";
@import "components/articles/article-content-images";
@import "components/articles/article-content-factbox";
@import "components/articles/article-content-quotes";
@import "components/articles/article-intro";
@import "components/articles/article-tags";
@import "components/articles/article-header";
@import "components/articles/article-preview";
@import "components/articles/article-image-addon";
@import "components/articles/article-archive-filter";

@import "components/searchpage/searchpage-header";
@import "components/searchpage/searchpage-results";
@import "components/searchpage/searchpage-pagination";

@import "components/errorpages/error-404";
@import "components/errorpages/error-500";
@import "components/errorpages/error-access-denied";

@import "components/newsletter/newsletter-sub-unsub";
@import "components/scrollbars/custom-scrollbars";
@import "components/product/product-img-fallback";

@import "utils/text-utils";
@import "utils/divider";

#__bs_notify__ {
    top: auto !important;
    right: auto !important;
    bottom: 0 !important;
    left: 0 !important;
    border-radius: 0 15px 0 0 !important;
}
