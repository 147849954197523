@mixin mix-scrollbars() {
    &::-webkit-scrollbar {
        width: 10px;
        height: 0;
        z-index: 12;
    }
    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.05);
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.3);
        background: $color-gu-orange;
    }
    &::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(0, 0, 0, 0.1);
    }
}

@mixin mix-scrollbar-colors($track-color, $thumb-color) {
    &::-webkit-scrollbar-track {
        background: $track-color;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba($thumb-color, 0.5);
    }
    &:hover::-webkit-scrollbar-thumb {
        background: $thumb-color;
    }
    &::-webkit-scrollbar-thumb:window-inactive {
        background: rgba($thumb-color, 0.2);
    }
}

@mixin mix-custom-scrolltrack-vertical-widths(
    $widthDefault,
    $beforeElementHover: null,
    $pushOnMouseOut: null
) {
    &:hover .custom-scrollbar-vertical-container {
        overflow-y: auto;
    }

    &:before,
    .custom-scrollbar-vertical-container {
        transition: width 0.3s ease;
    }

    .custom-scrollbar-vertical-container::-webkit-scrollbar {
        width: $widthDefault;
        height: 0;
        z-index: 12;
    }

    @if ($pushOnMouseOut) {
        &:before {
            width: $beforeElementHover - 5;
        }

        &:not(:hover) .custom-scrollbar-vertical-container {
            width: calc(100% + 5px);
        }

        &:hover {
            &:before {
                width: $beforeElementHover;
            }
        }
    } @else {
        &:before {
            width: $beforeElementHover;
        }
    }
}

@mixin hide-scrollbars {
    &::-webkit-scrollbar {
        display: none;
    }
    & {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}
