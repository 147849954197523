.profile-information {
    h2 {
        @include mix-theme-like-h5;
    }
}

.profile-information__success-modal {
    background-color: white;
    width: fit-content;
}

.profile-information__success-modal__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    button {
        @include mix-button-is-slim;
        @include mix-button--primary;
        margin-top: 1.5rem;
        width: fit-content;
        @include mix-content-max-width($breakpoint-w-dog) {
            width: 100%;
        }
    }
}

.profile-information__masterdata {
    .profile-information__masterdata-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem 1.5rem;

        @include mix-content-max-width($breakpoint-w-lion) {
            grid-template-columns: 1fr;
        }

        button {
            @include mix-button-is-slim;
            @include mix-button--primary;
            height: fit-content;
            width: fit-content;
            @include mix-content-max-width($breakpoint-w-lion) {
                width: 100%;
            }
        }
    }
}

.profile-information__institution,
.profile-information__newsletter {
    margin-top: 2.5rem;
}

.profile-information__institution {
    > div {
        margin-top: 1rem;
    }
    h2 {
        font-weight: 700;
    }
}

.profile-information__newsletter {
    div {
        margin-top: 1.25rem;
    }
}

.profile-information__remove-institution {
    margin-top: rem(8);
    color: $color-error;
}

.profile-information__password {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem 1.5rem;

    @include mix-content-min-width($breakpoint-w-dog) {
        div:nth-of-type(1n + 2) {
            grid-row-start: 2;
        }
    }

    @include mix-content-max-width($breakpoint-w-dog) {
        grid-template-columns: 1fr;
        gap: 0;

        > div {
            margin-bottom: 0.5rem;
            &:first-of-type {
                margin-bottom: 1rem;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.profile-information__purchase-relation {
    margin-top: 3rem;
    input {
        margin: 1em 0.5em 1em 0;
        height: 1.5rem;
        width: 1.5rem;
        border-color: #bdc6cd;
    }
    input[type="radio"]:checked {
        background-color: $navigation-bg-color; /* Change the color of the dot when checked */
    }
    input[type="radio"] {
        accent-color: $navigation-bg-color;
    }
    &--gu {
        input[type="radio"] {
            // Harcoded on purpose, $color-gu-orange does not work for accent-color
            accent-color: #e87315;
        }
    }
}
