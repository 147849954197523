@use "sass:math";

@mixin mix-content-padding() {
    padding: 20px;

    @media (min-width: $breakpoint-w-cat) {
        padding: $gutter;
    }

    @media (min-width: $breakpoint-w-elephant) {
        padding: $gutter * 1.5;
    }
}

@mixin mix-content-padding-sides() {
    padding: 0 $gutter;
    @media (min-width: $breakpoint-w-elephant) {
        padding: 0 $gutter * 1.5;
    }
}

@mixin mix-content-column--gutter() {
    padding: 0 math.div($gutter, 2);
    @media (min-width: $breakpoint-w-elephant) {
        padding: 0 $gutter;
    }
    &:first-of-type {
        padding-left: 0;
    }
    &:last-of-type:not(:first-of-type) {
        padding-right: 0;
    }
}

.content-module {
    @include mix-clearfix-table;

    .main-content & {
        // Only add margin, if the module is in main content
        // (this enables the user to put a module outside as a hero)
        margin-top: $gutter;
        margin-bottom: $gutter;

        @include mix-content-min-width($breakpoint-w-cat) {
            margin-bottom: $gutter * 2;
        }
        @include mix-content-min-width($breakpoint-w-rhino) {
            margin-top: $gutter;
            margin-bottom: $gutter * 2;
        }
    }

    &--no-margin-top {
        margin-top: $gutter !important;
    }
    &--no-margin-bottom {
        margin-bottom: $gutter !important;
    }

    &--bg {
        padding: $gutter 0;

        @include mix-content-min-width($breakpoint-w-rabbit) {
            padding: $gutter * 2 0;
        }
        @include mix-content-min-width($breakpoint-w-dog) {
            padding: $gutter * 3 0;
        }
        &.content-module--no-margin-top {
            margin-top: 0 !important;
        }
        &.content-module--no-margin-bottom {
            margin-bottom: 0 !important;
        }
    }
}

.content-module-header {
    margin-bottom: $gutter-small;

    @include mix-content-min-width($breakpoint-w-cat) {
        margin-bottom: $gutter-small * 2;
    }
}

.content-module__landingpage-inner {
    width: 100%;
    margin: auto;

    @include mix-content-min-width($breakpoint-w-rhino) {
        padding: 0 $gutter;
        width: calc((100% / 4 * 3) + #{$gutter * 2});
    }
}

.content-row {
    @include mix-grid-section;
    @include mix-clearfix-block;
    @media (min-width: $breakpoint-w-elephant) {
        margin: 0 auto;
        padding: 0 $gutter;
    }
    &--push-bottom {
        margin-bottom: 2em;
    }
    &--50 {
        @include mix-half-grid-section;
    }
    &--no-padding {
        padding: 0;
    }

    .content-row {
        padding: 0;
    }

    a:not([class]):not([class*="button"]) {
        @include mix-theme-like-a();
    }

    img {
        max-width: 100%;
        display: block;
    }
}

.content-trumpet {
    @include mix-theme-trumpet;
    @include mix-subtheme-primary-color;
    display: inline-block;

    @include mix-content-min-width($breakpoint-w-horse) {
        margin-bottom: 5px;
    }
}

.content-header {
    @include mix-theme-like-h2();

    @include themer($theme-gyldendal) {
        & + * {
            margin-top: $gutter;
        }
    }
    @include themer($theme-hansreitzel, $theme-munksgaard) {
        & + * {
            margin-top: 15px;
        }
    }
}

.content-block {
    border: 1px solid $color-gu-fade-20;

    @include themer($theme-gyldendal) {
        border-radius: 5px;
    }

    margin-bottom: $gutter;

    @media (min-width: $breakpoint-w-cat) {
        margin-bottom: $gutter * 1.2;
    }
}

.content-block--max-width-half {
    max-width: math.div($max-width, 2);
}

.content-inner,
.content-block__inner {
    @include mix-content-padding();

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    > p {
        margin: 1em 0;
    }

    > p + p {
        margin-top: 1em;
    }
}

.content--white {
    background-color: #fff;
}

.content--dark,
.content-block__inner--dark {
    background-color: $color-gu-fade-10;
    @include mix-input-autofill-background($color-gu-fade-10);
}

.content--darker {
    background-color: $color-gu-fade-15;
}

.content-flex-row {
    max-width: $max-width;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin: 0 auto;
}

.content-flex-column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-wrap: nowrap;
    //flex-basis: 100%; // Messes with IE11 hence with instead
    width: 100%;
    min-width: 300px;

    > * {
        flex-grow: 1;
    }
}

.content-flex-column__bottom {
    margin-bottom: auto;
    flex-grow: 0;
}

.content-flex-column--gutter {
    @include mix-content-column--gutter();
}

@each $name, $bp in $breakpoints {
    @media only screen and (max-width: #{$bp}) {
        .hide-below#{$name} {
            display: none;
        }
    }
    @media only screen and (min-width: #{$bp}) {
        .content-flex-row--#{$name} {
            outline: 1px solid red;
            max-width: $max-width;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            margin: 0 auto;
        }

        .content-flex-column--#{$name} {
            display: flex;
            outline: 1px solid lime;
            flex-direction: column;
            flex-grow: 1;
            flex-basis: 100%;
            flex-shrink: 1;
            flex-wrap: nowrap;
            min-width: 300px;
        }
        .content-flex-column--gutter-#{$name} {
            @include mix-content-column--gutter();
        }
        .content-column--gutter--#{$name} {
            @include mix-content-column--gutter();
        }

        .content-column--#{$name}-33 {
            width: 33.333332%;
            float: left;
        }
        .content-column--#{$name}-50 {
            width: 50%;
            float: left;
        }
        .content-column--#{$name}-66 {
            width: 66.66652%;
            float: left;
        }

        .content-column--#{$name}-push-33 {
            margin-left: 33.333332%;
        }
        .content-column--#{$name}-push-50 {
            margin-left: 50%;
        }
        .content-column--#{$name}-push-66 {
            margin-left: 66.66652%;
        }
    }
}

.content-block__head {
    padding: math.div($gutter, 2) $gutter;
}

.content-block__aligncontent-vertical {
    @include mix-content-min-width($breakpoint-w-lion) {
        // The flex-direction: column; will mess up Safari, when the elements
        // break to be above each other and not next to each other. Check profile-work-info.js.
        // REvisit if this class needs to be used in different context!!
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
    }
}

.content-info {
    position: relative;
    line-height: 1.2em;
    font-size: math.div(14, 16) * 1rem;
    margin: 0.5em 0 0.5em;
    padding-left: $gutter * 1.2;

    &:before {
        font-family: "Georgia", "serif";
        font-style: italic;
        font-weight: 900;
        font-size: math.div(14, 16) * 1rem;
        content: "i";
        color: #fff;
        background-color: $color-gu-fade-30;
        display: block;
        position: absolute;
        top: -0.15em;
        left: 0;
        width: $gutter * 0.8;
        height: $gutter * 0.8;
        line-height: $gutter * 0.8;
        border-radius: 50%;
        text-align: center;
    }
    &.no-icon {
        padding-left: 0;

        &:before {
            display: none;
        }
    }

    a {
        @include mix-theme-like-a;
    }
}

.content--border-top {
    border-top: 1px solid $color-gu-fade-20;
}

.content--border-bottom {
    border-bottom: 1px solid $color-gu-fade-20;
}

.content-arrow {
    position: absolute;
    display: block;
    border: 1px solid transparent;
    height: $gutter;
    width: $gutter;
    z-index: 50;
    background-color: #fff;
    border-right-color: $color-gu-fade-20;
    border-bottom-color: $color-gu-fade-20;

    &.content-arrow--dark {
        background-color: $color-gu-fade-10;
    }
}

.content--left-push {
    padding-left: 2em;
}

.content--push {
    &-top {
        margin-top: 1em;
    }
    &-top-more {
        margin-top: 2em;
    }
    &-bottom {
        margin-bottom: 1em;
    }
    &-bottom-more {
        margin-bottom: 2em;
    }
    &-left {
        margin-left: 1em;
    }
    &-left-more {
        margin-left: 2em;
    }
    &-right {
        margin-right: 1em;
    }
    &-right-more {
        margin-right: 2em;
    }
    &-left-right {
        margin-left: 1em;
        margin-right: 1em;
    }
    &-left-right-more {
        margin-left: 2em;
        margin-right: 2em;
    }
}

.content--fullheight {
    height: 100%;
}

.content--float-right {
    float: right;
}

.content--float-left {
    float: left;
}

.content--list-after-separator {
    display: inline-block;

    &:not(:only-child):not(:last-of-type):after {
        content: "\00b7";
        padding: 0 5px;
        color: $color-gu-dark-grey;
    }
}

.content--justify-content {
    &-left {
        justify-content: flex-start;
    }
    &-center {
        justify-content: center;
    }
    &-right {
        justify-content: flex-end;
    }
}
