div.account-verification-missing-modal {
    border: 1px solid black;
    margin-inline: auto !important;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h2 {
        @include mix-theme-like-h4;
        @include mix-theme-primary-color-props("color");
        font-size: 1.5rem;
        line-height: 1;
        text-align: center;
        margin-bottom: 1rem;
    }

    p {
        text-align: center;
    }

    button[type="button"] {
        @include mix-button-is-slim;
        margin-top: 1.5rem;
    }
}
