@use "sass:math";

.footer-widget {
    font-size: 16px;
    padding: 0 20px $gutter * 1.5;
    line-height: 1.5em;

    @include mix-content-min-width($breakpoint-w-cat) {
        padding: 0 $gutter $gutter * 1.5;
    }

    @include mix-content-min-width(500px) {
        float: left;
        width: 50%;
    }

    @include mix-content-min-width(940px) {
        width: 25%;
    }
    @include themer($theme-gyldendal, $theme-munksgaard) {
        @include mix-form-invert();
    }

    &:last-child {
        display: none;
    }

    a {
        text-decoration: underline;

        @media (hover: hover) {
            &:hover {
                text-decoration: none;
            }
        }

        &:focus-visible {
            text-decoration: none;
        }

        @include themer($theme-gyldendal) {
            color: $color-gu-orange;
        }

        @include themer($theme-hansreitzel) {
            color: $color-hr-red;
        }

        @include themer($theme-munksgaard) {
            color: inherit;
        }
    }
}

.footer-widget__header {
    font-size: 1em;
    font-weight: bold;
    color: inherit;
    margin-bottom: 1rem;
    // @include themer($theme-hansreitzel) {
    //     color: #000;
    //     font-size: math.div(20, 16) * 1em;
    // }
}

.footer-widget__list {
    margin: 0;
    list-style: none;
    li {
        margin: 0 0 1.35em;
    }
    a {
        color: inherit;
        text-decoration: none;
    }
    // @include themer($theme-gyldendal) {
    //     li {
    //         @include mix-arrow-right-before();

    //         a {
    //             // color: #fff;
    //         }
    //     }
    // }
}

// .widget__list__link {
//     @include themer($theme-gyldendal) {
//         color: inherit;
//         &:hover {
//             // color: $color-gu-orange;
//         }
//     }
// }

.footer-widget__button {
    min-width: 150px;

    @include themer($theme-gyldendal) {
        @include mix-button();
    }
    @include themer($theme-hansreitzel) {
        @include mix-button($color-hr-red, $white);
    }
    @include themer($theme-munksgaard) {
        @include mix-button($color-mg-green-dark, $white);
    }
    @include mix-button-is-slim();
}

.footer-widget__form-terms {
    margin: 1.25rem 0;
}

.footer-widget__form-terms__text {
    font-size: 0.8em;
    line-height: 1.3em;
    position: relative;
}
