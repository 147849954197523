$headerlogin-basecolor: $color-gu-dark-grey;
$headerlogin-textcolor: #fff;
$headerprofile-basecolor: $color-gu-green;
$headerprofile-textcolor: #fff;

@if $site == $theme-hansreitzel {
    $headerlogin-basecolor: $color-hr-red;
    $headerlogin-textcolor: #fff;
    $headerprofile-basecolor: $color-hr-green;
    $headerprofile-textcolor: $color-hr-green-dark;
}

@if $site == $theme-munksgaard {
    $headerlogin-basecolor: $color-mg-blue;
    $headerlogin-textcolor: #fff;
    $headerprofile-basecolor: $color-mg-green-dark;
    $headerprofile-textcolor: $color-mg-blue;
}

.header-login {
    overflow: visible;
    position: static;
    height: 50px;
    min-width: 50px;
    z-index: 20;
    border-right: none !important;

    @media (max-width: $breakpoint-header) {
        flex-grow: 0;
    }

    @media (min-width: $breakpoint-header) {
        position: relative;
        flex-shrink: 0;
    }
}

.header-login__toggle {
    border: 0;
    margin: 0;
    padding: 0;
    text-align: center;
    overflow: hidden;
    line-height: 0;
    max-width: 50px;
    display: inline-block;
    cursor: pointer;

    @media (min-width: $breakpoint-header) {
        display: none;
    }

    .header__item__icon {
        width: 50px;
    }
}

.header-login__inner {
    @media (max-width: $breakpoint-header) {
        position: absolute;
        right: 0;
        width: 100%;
        top: 100%;
        max-width: 500px;
    }
}

.header-login__choices {
    @media (max-width: $breakpoint-header) {
        position: relative;
    }
}

.header-login__choices__inner {
    @media (max-width: $breakpoint-header) {
        position: relative;
    }
    @media (min-width: $breakpoint-header) {
        display: flex;
        flex-direction: row-reverse;
    }
}

.header-login__choice {
    cursor: pointer;
    display: block;
    width: auto;
    border: none;
    height: 50px;
    line-height: 50px;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 1em;
    text-overflow: ellipsis;
    text-align: center;
    color: #FFf;
    text-transform: uppercase;
    background-color: transparent;
    transition: all .5s;

    &:hover {
        background-color: rgba(255, 255, 255, .1);
    }

    @media (min-width: $breakpoint-header) {
        //float: right;
    }

    @include themer($theme-gyldendal) {
        font-weight: $fontweight-gu-medium;
    }

    @include themer($theme-hansreitzel) {
        font-weight: $fontweight-hr-semibold;
        line-height: 52px;
    }

    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-semibold;
    }
}

.header-login__button--hightlight {
    background: $headerprofile-basecolor;
    color: $headerprofile-textcolor;

    &:hover {
        background-color: lighten($headerprofile-basecolor, 5);
    }
}

//------------------------------------------------------
// Profiles when logged in
//------------------------------------------------------
.header-profile {
    position: absolute;
    top: 0;
    right: 50px;
    width: auto;
    transform: translateX(100%);
    transition: transform .3s $easeInOutExpo, right .3s $easeInOutExpo;
    z-index: 900;
    text-align: left;
    max-width: 100%;
    min-width: 325px;
    background-color: $headerprofile-basecolor;
    color: $headerprofile-textcolor;

    &.open {
        right: 0;
        transform: translateX(0);
    }
    button {
        cursor: pointer;
    }
}

.header-profile__header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    max-width: 100%;
    background-color: inherit;
}

.header-profile__content {
    overflow: hidden;
    background-color: transparent;
}

.header-profile__content__inner {
    transition: transform .3s $easeInOutExpo;
    transform: translateY(-100%);
    position: relative;
    top: 20px;
    max-height: calc(100vh - 100px);
    overflow-y: auto;

    .open & {
        top: 0;
        transform: translateY(0);
    }
}

.header-profile__toggle-button {
    border: none;
    position: relative;
    padding-left: 50px;
    height: 50px;
    margin: 0;
    background: none;
    flex-grow: 1;
    color: inherit;

    svg {
        stroke: $headerprofile-textcolor;
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 50px;
    }
}

.header-profile__username {
    display: inline-block;
    margin-right: 1em;
    padding: 0;
    white-space: nowrap;
    color: inherit;
    text-transform: uppercase;
    font-size: 16px;
    max-width: 160px;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.header-profile__logout__button {
    border: none;
    background-color: rgba(#000, .15);
    padding: 0 1em;
    white-space: nowrap;
    color: inherit;
    text-transform: uppercase;
    height: 50px;
    font-size: 1rem;
}

///quickacces
.header-profile__content__links {
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    li {
        display: flex;
        align-items: center;
    }
    li + li {
        border-top: 1px solid rgba(#fff, .2);
    }
    a {
        position: relative;
        color: inherit;
        padding: 13px 1em;
        transition: background-color .3s $easeInOutExpo;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;

        &:hover {
            background-color: rgba(0, 0, 0, .2);
        }
    }
}

.link-icon-after {
    display: none; // Removed for now since we only have one level in the menu
    position: absolute;
    top: 50%;
    right: .8em;
    transform: translateY(-50%);

    svg {
        fill: none;
        stroke: $headerprofile-textcolor;
        stroke-width: 2px;
        height: 10px;
        width: 1em;
        vertical-align: baseline;
    }
}

.header-profile__content__toggle {
    border: none;
    border-bottom-left-radius: 4px;
    display: block;
    position: relative;
    width: 100%;
    height: 20px;
    transition: background-color .3s $easeInOutExpo;
    background-color: $headerprofile-basecolor;

    .open & {
        background-color: lighten($headerprofile-basecolor, 2);
    }
    svg {
        transition: all .3s $easeInOutExpo;
        top: 5px;
        left: 25px;
        transform-origin: 50% 50%;
        transform: translateX(-50%);
        position: absolute;
        width: 15px;
        height: auto;
        stroke: $headerprofile-textcolor;
        fill: none;
        stroke-width: 1px;
        .open & {
            left: 50%;
            transform: translateX(-50%) rotate(180deg);
        }
    }
}

//------------------------------------------------------
// Creating profile
//------------------------------------------------------
.header-profile-create,
.header-profile-uniprompt {
    position: relative;
    background-color: $headerlogin-basecolor;

    @media (min-width: $breakpoint-header) {
        width: 160px;
        transition: width .15s $easeInOutCubic, max-width .15s $easeInOutCubic;
        transition-delay: .25s;
    }
}

.loginCreate-isOpen .header-profile-create,
.loginUniPrompt-isOpen .header-profile-uniprompt {
    @media (min-width: $breakpoint-header) {
        width: 340px;
        transition-delay: 0s;
        a {
            width: 100%;
        }
    }
}

.header-profile-create__content {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;

    @media (min-width: $breakpoint-header) {
        width: 340px;
        top: 50px;
    }
}

.header-profile-create__wrap {
    background-color: #fff;
    border: 1px solid $color-gu-fade-20;
    color: $color-gu-dark-grey;

    a {
        width: 100%;
    }

    @media (max-width: $breakpoint-header) {
        max-height: calc(100vh - 155px);
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        @include mix-scrollbars();
    }
}

.header-profile-create__wrap__content {
    padding: $gutter;
}

.header-profile-create__info {
    margin-top: 10px;
    font-size: 14px;
    background-color: $color-gu-fade-10;
    position: relative;
    border-top: 1px solid $color-gu-fade-20;

    ul {
        padding-left: 1.1em;
        margin-top: .5em;
    }
    p:not(:first-child) {
        margin-top: .5em
    }
    &:after {
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        display: block;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        background-color: inherit;
        border-top: 1px solid $color-gu-fade-20;
        border-left: 1px solid $color-gu-fade-20;

    }
}