// Read more to understand the difference here: 
// https://stackoverflow.com/questions/211383/what-methods-of-clearfix-can-i-use/1633170#1633170

@mixin mix-clearfix-block(){
    &:after{
        content: "";
        display: block;
        clear: both;
    }
}

@mixin mix-clearfix-table() {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}