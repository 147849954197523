.profile-information-section {
    display: grid;
    grid-template-columns: 1fr;
    padding: 2.5rem 0;
    border-top: 1px solid $color-gu-fade-20;

    @include mix-content-min-width($breakpoint-w-lion) {
        grid-template-columns: 2fr 6fr;
    }

    @include mix-content-min-width($breakpoint-w-horse) {
        grid-template-columns: 2fr 5fr;
    }

    @include mix-content-min-width($breakpoint-w-rhino) {
        grid-template-columns: 3fr 5fr;
    }

    &:first-of-type {
        padding-top: 0;
        border: none;
    }

    &:last-of-type {
        padding-bottom: 0;
    }

    h2 {
        @include mix-theme-like-h6;
        text-transform: uppercase;

        @include mix-content-max-width($breakpoint-w-lion) {
            margin-bottom: 1rem;
        }
    }
}

.profile-information-section__actions {
    margin-top: 2.5rem;
    display: flex;
    justify-content: end;

    &--hasBothButtons {
        display: grid;
        grid-template-columns: repeat(2, auto);
        gap: 1.5rem;
    }

    @include mix-content-max-width($breakpoint-w-dog) {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    button {
        @include mix-button-is-slim;
        min-width: 12.5rem;
        &.cancel-button {
            @include mix-button-hollow--secondary;
            @include mix-content-max-width($breakpoint-w-dog) {
                order: 2;
            }
            border-width: 1px;
            &:hover {
                border-width: 1px;
            }
        }

        &.submit-button {
            @include mix-button--primary;
            @include mix-content-max-width($breakpoint-w-dog) {
                order: 1;
            }
        }
    }
}
