.featured-article__trumpet {
    margin-bottom: 1em;
}

.faq-section {
    border: 1px solid $color-gu-fade-20;
    border-right: none;
    border-left: none;
    background-color: #fafafa;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
    @include mix-content-min-width($breakpoint-w-cat) {
        padding-top: 2em;
        padding-bottom: 2em;
        margin-top: 2em;
        margin-bottom: 2em;
    }
    @include mix-content-min-width($breakpoint-w-rhino) {
        padding-top: 3em;
        padding-bottom: 3em;
        margin-top: 3em;
        margin-bottom: 3em;
    }
}

.faq-item {
    border: 1px solid $color-gu-fade-20;
    border-radius: 5px;
    overflow: hidden;

    & + .faq-item {
        margin-top: 1em;
    }
}
.faq-item__title {
    @include reset__button("true");
    background-color: #fff;
    color: #000;
    font-weight: $fontweight-gu-medium;
    font-family: inherit;
    font-size: inherit;
    padding: 1em 2.5em 1em 1.5em;
    cursor: pointer;
    position: relative;
    width: 100%;
    text-align: left;
}
.faq-item__title__arrow {
    position: absolute;
    top: 50%;
    right: 1.5em;
    transform: translateY(-50%);
    transition: transform 0.3s;

    svg {
        fill: transparent;
        stroke: $color-gu-dark-grey;
    }
    .isOpen & {
        transform: translateY(-50%) rotate(-180deg);
    }
}

.faq-item__content {
    background-color: $lightgrey;
}
.faq-item__content__inner {
    padding: 1em 1.5em;
}
