.tooltip {
    height: 100%;
    align-items: center;
    display: flex;
}

.tooltip__reference-element {
    background: none;
    border: none;
    outline: none;
    padding: 0;
    display: inline-flex;
    color: inherit;
    &:focus,
    &:hover {
        svg {
            @include mix-theme-primary-color-props(color);
            transform: scale(1.05);
        }
    }
}

.tooltip__popper-element {
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    border-radius: $border-radius;
    padding: 1rem;
    font-size: 0.75rem;
    line-height: 1.16;
    color: $dark-grey;
    width: max-content;
    max-width: 60ch;
    z-index: 1000;

    &--hidden {
        visibility: hidden;
        pointer-events: none;
    }
}
