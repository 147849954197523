.custom-scrollbar-vertical-wrapper {
    overflow: hidden;

    .no-touch & {
        @include themer($theme-gyldendal) {
            &:not(.custom-scrollbar--IE,.custom-scrollbar--EDGE) {
                @include mix-custom-scrolltrack-vertical-widths(8px, 8px, true)
            }

            &.custom-scrollbar--IE {
                @include mix-custom-scrolltrack-vertical-widths(18px, 18px)
            }
            &.custom-scrollbar--EDGE {
                @include mix-custom-scrolltrack-vertical-widths(12px, 12px)
            }
        }
        @include themer($theme-hansreitzel) {
            &:not(.custom-scrollbar--IE,.custom-scrollbar--EDGE) {
                @include mix-custom-scrolltrack-vertical-widths(8px, 66px, true)
            }
        }
        @include themer($theme-munksgaard) {
            &:not(.custom-scrollbar--IE,.custom-scrollbar--EDGE) {
                @include mix-custom-scrolltrack-vertical-widths(8px, 66px, true)
            }
        }
        @include themer($theme-hansreitzel, $theme-munksgaard) {
            &.custom-scrollbar--IE {
                @include mix-custom-scrolltrack-vertical-widths(18px, 76px)
            }
            &.custom-scrollbar--EDGE {
                @include mix-custom-scrolltrack-vertical-widths(12px, 70px)
            }
        }
    }
}

.custom-scrollbar-vertical-container {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch; /* lets it scroll lazy */

    .no-touch & {
        @include themer($theme-hansreitzel) {
            @include mix-scrollbar-colors(rgba(0, 0, 0, 0.5), $color-hr-green);
        }

        @include themer($theme-munksgaard) {
            @include mix-scrollbar-colors(rgba(0, 0, 0, 0.5), $color-mg-green-dark);
        }

        @include themer($theme-gyldendal) {
            @include mix-scrollbar-colors(rgba(0, 0, 0, 0.5), $color-gu-orange);
        }
    }
}