.navigation-overview__link-list-container {
    margin: $gutter * 2 0;
}

.navigation-overview__link-list {
    max-width: $max-width;
    margin: 0 auto;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    @include mix-content-min-width($breakpoint-w-cat) {
        padding: 0 15px;
    }
}

.navigation-overview__link-list__item {
    margin: $gutter-small 0;
    display: flex;
    width: 100%;
    padding: 0 $gutter-small;

    @include mix-content-min-width($breakpoint-w-cat) {
        width: 50%;
    }
    @include mix-content-min-width($breakpoint-w-lion) {
        width: 33.3333333%;
    }
    @include mix-content-min-width($breakpoint-w-rhino) {
        width: 25%;
    }

    a {
        flex-grow: 1;
        @include mix-button--primary();

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
