.title {
    @include mix-theme-like-h2();

    &--small {
        // Font-size like teaser-list
        @include themer($theme-gyldendal) {
            font-size: 20px;
        }
        @include themer($theme-munksgaard, $theme-hansreitzel) {
            font-size: 24px;
        }
        @include mix-content-max-width($breakpoint-w-cat) {
            font-size: 20px;
        }
    }
}