.icon-teaser-grid-container {
    @include mix-grid-section;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .custom-primary-cta-button a {
        margin-top: 0;
    }

    .icon-teaser-grid__content {
        width: 100%;
        height: 100%;
        display: flex;

        @include mix-content-min-width($breakpoint-w-dog) {
            justify-content: center;
            align-items: center;
        }
    }

    .icon-teaser-grid__icon {
        svg {
            path {
                stroke: currentColor;
            }
        }
    }

    .icon-teaser-grid__item {
        box-sizing: border-box;
        width: 33%;
        min-width: 300px;
        padding: 1rem;

        .icon-teaser-grid__inner-container {
            padding: 1.5rem;
            overflow: hidden;
        }

        /* styling for type: Icon */
        &.icon-teaser-grid__item--icon {
            border: 1px solid $color-gu-dark-grey;
            height: 100%;
            @include themer($theme-gyldendal) {
                border-radius: $border-radius;
            }

            width: calc(33% - 1rem);
            margin: 0.5rem;

            .icon-teaser-grid__text-container {
                display: flex;
                align-items: center;
                flex-direction: column;
                width: 100%;
            }

            .icon-teaser-grid__title {
                color: $color-gu-dark-grey;
                font-size: 1.5rem;
                font-weight: 500;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }

            .icon-teaser-grid__icon {
                width: 45px;
                min-height: 45px;
                margin: 15px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                flex: none;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        /* styling for type: Image */
        &.icon-teaser-grid__item--image {
            max-width: 550px;
            width: 100%;

            .icon-teaser-grid__inner-container {
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
            }

            .icon-teaser-grid__trumpet {
                font-size: 1rem;
                font-weight: 600;
            }

            .icon-teaser-grid__image {
                min-width: 200px;
                flex: 1;
                align-self: stretch;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }

            .icon-teaser-grid__text-container {
                padding: 10px;
                width: 70%;
            }

            .icon-teaser-grid__title {
                font-size: 25px;
                font-weight: 600;
            }

            .icon-teaser-grid__button-container {
                margin-top: 30px;
            }
        }

        /* styling for type: background */
        &.icon-teaser-grid__item--background {
            box-sizing: border-box;

            .icon-teaser-grid__text-container {
                width: 100%;
            }

            .icon-teaser-grid__title {
                font-size: 2rem;
                overflow-wrap: anywhere;
            }

            .icon-teaser-grid__button-container {
                display: flex;
                justify-content: center;
                margin-top: 2rem;
            }
        }
    }

    &.icon-teaser-grid--normal {
        .icon-teaser-grid__icon {
            margin: 5px auto !important;
        }
        .icon-teaser-grid__button-container {
            margin-top: auto;
        }
    }

    &.icon-teaser-grid--wide {
        margin: 0 auto;
        width: 83%;
        max-width: 1195px;
        .icon-teaser-grid__item {
            &.icon-teaser-grid__item--icon {
                width: 100%;

                .icon-teaser-grid__inner-container {
                    @include mix-content-min-width($breakpoint-w-dog) {
                        padding: 0;
                    }
                }

                .icon-teaser-grid__icon {
                    @include mix-content-max-width($breakpoint-w-dog) {
                        margin: auto;
                    }
                }

                .icon-teaser-grid__title {
                    padding: 0 1rem;
                    word-break: break-word;
                    @include mix-content-max-width($breakpoint-w-dog) {
                        text-align: center;
                        font-size: 1.2rem;
                        padding: 0;
                    }
                }

                .icon-teaser-grid__text-container {
                    align-items: center;
                    @include mix-content-min-width($breakpoint-w-dog) {
                        flex-direction: row;
                    }
                }

                .icon-teaser-grid__button-container {
                    @include mix-content-min-width($breakpoint-w-dog) {
                        margin-left: auto;
                    }
                }

                @include mix-content-min-width($breakpoint-w-dog) {
                    padding: 1rem;
                }
            }
        }
    }
}
