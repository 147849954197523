@mixin reset__button($outline: "false") {
    //Native button reset
    @if ($outline == "false") {
        outline: none;
    }
    font: inherit;
    padding: 0;
    margin: 0;
    box-shadow: none;
    border: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    background: none;
}
