.video-player-root{
    position: fixed;
    top: 0;
    left: 0;
    z-index:3000;
    &.isActive{
        width: 100%;
        height: 100%;
    }
}
.video-player-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.7);
}
.video-player-overlay__content{
    position: absolute;
    top: 50%;
    left: 50%;
    max-width:640px;
    max-height:500px;
    width: 100%;
    transform: translate(-50%, -50%);
    iframe{
        max-width: 100%;
        max-height: 90vh;
    }
}

.video-player-overlay__content__close{
    position: absolute;
    top:-$gutter*2;
    right:0;
    width: $gutter*2;
    height: $gutter*2;
    overflow: hidden;
    text-indent: -999em;
    cursor: pointer;
    background-color: $color-gu-orange;

    @include themer($theme-hansreitzel){
        background-color: $color-hr-red;
    }
    @include themer($theme-munksgaard){
        background-color: $color-mg-green-dark;
    }

    &:before, &:after{
        content:"";
        position: absolute;
        display: block;
        width:$gutter;
        height:3px;
        left:25%;
        top:50%;
        transform-origin: 50% 50%;
        background-color: #fff;
        @include themer($theme-munksgaard){
            background-color: $color-mg-blue;
        }
    }
    &:before{
        transform: rotate(45deg);
    }
    &:after{
        transform: rotate(-45deg);
    }
}