//Breakpoint
$breakpoint-w-ant: 0;
$breakpoint-w-rabbit: 480px;
$breakpoint-w-cat: 640px;
$breakpoint-w-dog: 768px;
$breakpoint-w-wolf: 840px;
$breakpoint-w-lion: 920px;
$breakpoint-w-horse: 1024px;
$breakpoint-w-rhino: 1200px;
$breakpoint-w-elephant: 1440px;

$breakpoint-h-hummingbird: 320px;
$breakpoint-h-sparrow: 480px;
$breakpoint-h-dove: 640px;
$breakpoint-h-eagle: 768px;
$breakpoint-h-pelican: 1024px;
$breakpoint-h-albatross: 1200px;

$breakpoints: (
    small: $breakpoint-w-ant,
    medium: $breakpoint-w-cat,
    large: $breakpoint-w-lion,
    xlarge: $breakpoint-w-rhino,
    xxlarge: $breakpoint-w-elephant
);

//navigation
$breakpoint-navigation: 1240px;
$breakpoint-navigation-tablet: 1200px;
$navigation-width: 300px;

$breakpoint-header: 767px;
