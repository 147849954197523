.load-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($white, 0.89);
    z-index: 10;
    display: flex;
    justify-content: center;
    padding-top: 50px;

    @include mix-content-min-width($breakpoint-w-dog) {
        padding-top: 100px;
    }

    &.load-overlay--center-content {
        padding-top: 0;
        align-items: center;
        flex-direction: column;
    }
    &.fade-exit-active {
        pointer-events: none;
    }
}

.load-overlay__text {
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    color: $color-gu-dark-grey;

    @include themer($theme-hansreitzel) {
        color: $color-hr-blue;
    }

    @include themer($theme-munksgaard) {
        color: $color-mg-blue-dusty;
    }

    .load-overlay--center-content & {
        margin-top: 0;
    }
}
