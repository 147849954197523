//test

.hamburger {

    @include reset__button();
    position: relative;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0.1);

    @media(max-width: $breakpoint-header) {
        position: absolute;
    }

    @media(min-width: $breakpoint-navigation) {
        display: none;
    }

    @include themer($theme-hansreitzel) {
        background-color: $color-hr-fade-15;
    }
    @include themer($theme-munksgaard) {
        background-color: $color-mg-fade-10;
    }

    span {
        position: absolute;
        display: block;
        width: 25px;
        height: 1px;
        top: 50%;
        left: 12px;
        background-color: $white;
        transition: transform 320ms $easeOutQuad;
        transform-origin: center center;

        &:nth-child(1) {
            transform: translate(0, -6px);
        }

        &:nth-child(2) {
            transform: translate(0, -1px);
        }

        &:nth-child(3) {
            transform: translate(0, 4px);

        }
    }

    &--active {
        width: 52px;
        span {

            &:nth-child(1) {
                transform: translate(1px, -1px) rotate(45deg);
            }

            &:nth-child(2) {
                transform: translate(1px, -1px) scale(0.4);
                opacity: 0;
            }

            &:nth-child(3) {
                transform: translate(1px, -1px) rotate(-45deg);
            }
        }
    }
}

