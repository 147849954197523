.teaser-grid {
    max-width: none;
    &.content-no-margin-top {
        margin-top: 0;
    }
    &.content-no-margin-bottom {
        margin-bottom: 0;
    }
    .title-link-top__inner {
        margin-bottom: 0.5rem;
    }
    &[class*="theme-bg"] {
        padding: 3rem 0;
    }
}
.teaser-grid-inner {
    max-width: $max-width;
    margin: auto;
}

.teaser-grid__items {
    display: flex;
    flex-wrap: wrap;
    padding: 0 25px;
    margin: -1rem; // Compensating for item padding
}
.teaser-grid__item {
    scroll-margin-top: $navigation-header-height;

    @include mix-teaser-grid-100-col;

    @include mix-content-min-width($breakpoint-w-cat) {
        @include mix-teaser-grid-50-col;
    }

    // Template 1
    .teaser-grid--template-1 & {
        @include mix-content-min-width($breakpoint-w-horse) {
            @include mix-teaser-grid-25-col;
        }

        // 2 items
        @include mix-number-of-children(2) {
            @include mix-teaser-grid-100-col;

            @include mix-content-min-width($breakpoint-w-cat) {
                @include mix-teaser-grid-50-col;
            }
        }

        // 3 items
        @include mix-number-of-children(3) {
            @include mix-content-min-width($breakpoint-w-dog) {
                @include mix-teaser-grid-33-col;
            }
        }
    }

    // Template 2
    // default: 100%, above cat: 50%

    // Template 3
    .teaser-grid--template-3 & {
        @include mix-content-min-width($breakpoint-w-cat) {
            &:nth-child(6n + 1),
            &:nth-child(6n + 6) {
                @include mix-teaser-grid-100-col;
            }
        }
        @include mix-content-min-width($breakpoint-w-dog) {
            @include mix-teaser-grid-33-col;
            &:nth-child(6n + 1),
            &:nth-child(6n + 6) {
                @include mix-teaser-grid-33-col;
            }
        }

        @include mix-content-min-width($breakpoint-w-horse) {
            @include mix-teaser-grid-25-col;
            &:nth-child(6n + 1),
            &:nth-child(6n + 6) {
                @include mix-teaser-grid-50-col;
            }
        }
    }

    // Template 4
    .teaser-grid--template-4 & {
        @include mix-content-min-width($breakpoint-w-cat) {
            &:nth-child(6n + 3),
            &:nth-child(6n + 4) {
                @include mix-teaser-grid-100-col;
            }
        }
        @include mix-content-min-width($breakpoint-w-dog) {
            @include mix-teaser-grid-33-col;
            &:nth-child(6n + 3),
            &:nth-child(6n + 4) {
                @include mix-teaser-grid-33-col;
            }
        }

        @include mix-content-min-width($breakpoint-w-horse) {
            @include mix-teaser-grid-25-col;
            &:nth-child(6n + 3),
            &:nth-child(6n + 4) {
                @include mix-teaser-grid-50-col;
            }
        }
    }

    // Template 5
    .teaser-grid--template-5 & {
        @include mix-content-min-width($breakpoint-w-cat) {
            &:first-child {
                @include mix-content-min-width($breakpoint-w-cat) {
                    @include mix-teaser-grid-100-col;
                }
                @include mix-content-min-width($breakpoint-w-lion) {
                    @include mix-teaser-grid-100-col-side-by-side;
                }
            }
        }
        @include mix-content-min-width($breakpoint-w-horse) {
            @include mix-teaser-grid-25-col;
        }
    }
}

.teaser-grid--template-1,
.teaser-grid--template-2,
.teaser-grid--template-3,
.teaser-grid--template-4,
.teaser-grid--template-5 {
    .teaser-grid__item {
        // 1 item
        @include mix-number-of-children(1) {
            @include mix-content-min-width($breakpoint-w-cat) {
                @include mix-teaser-grid-100-col;
            }
            @include mix-content-min-width($breakpoint-w-dog) {
                @include mix-teaser-grid-100-col-side-by-side;
            }
        }
    }
}
