.delivery-costs-modal {
    border-radius: $border-radius;
    background-color: $white;
    color: $dark-grey;
}

.delivery-costs-modal__close-action {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
}

.delivery-costs-modal__content {
    h2 {
        @include mix-theme-like-h2();
    }

    .text {
        margin-top: 1.5rem;
        color: black;
    }
}
