.return-order {
    max-width: 450px;
    margin-inline: auto;
    display: grid;
    grid-template-columns: [full-width-start] 1rem [content-start] auto [content-end] 1rem [full-width-end];
    row-gap: 2rem;
    margin-bottom: 4rem;

    > * {
        grid-column: content;
    }

    &--select-products {
        max-width: 550px;
    }

    &__header {
        text-wrap-style: pretty;

        > *:not(.return-order__error-message) {
            text-align: center;
        }
    }

    &__headline {
        margin: 3rem 0;
    }

    &__product-item-content {
        padding-top: 5px;
    }

    &__product-title {
        color: #000;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.2;
        font-weight: 700;
    }

    &__product-fully-returned {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        font-size: 12px;
        margin-top: 0.5rem;
    }

    &__find-order-form {
        display: grid;
        gap: 2rem;

        .input-field {
            display: grid;
            grid-template-rows: auto auto;
            gap: 0.5rem;
            label {
                grid-row: 1/2;
                margin: 0;
            }
            input,
            .input-field__icon {
                grid-row: 2/3;
            }
        }
    }

    &__select-products-form {
        display: grid;
        gap: 2rem;
        grid-column: full-width;
        background-color: #fafafa;
        padding: 1rem;

        @media (min-width: 550px) {
            grid-column: content;
            padding: 0;
            background-color: transparent;
        }
    }

    &__error-message {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: start;
        gap: 1rem;
        border-width: 1px;
        border-style: solid;
        border-color: $color-gu-blue-dusty;
        border-radius: 3px;

        * {
            margin: 0 !important;
        }

        svg {
            color: $color-gu-blue-dusty;
            flex: none;
        }
    }

    &__buttons {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 1rem;

        @media (min-width: 550px) {
            flex-direction: row;
        }

        button,
        a {
            flex: 1;
            min-width: max-content;
        }
    }

    &__product-list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: grid;
        row-gap: 1rem;

        @media (min-width: 550px) {
            padding: 0;
            border-top: solid 1px $color-gu-fade-20;
            row-gap: 0;
        }

        li {
            display: grid;
            grid-template-rows: auto auto;
            // row-gap: 1rem;
            border: solid 1px $color-gu-fade-20;
            overflow: hidden;
            border-radius: 6px;

            @media (min-width: 550px) {
                grid-template-rows: auto;
                border: none;
                border-bottom: solid 1px $color-gu-fade-20;
                border-radius: 0;
                grid-template-columns: auto 1fr;
                margin-top: 1rem;
                padding-left: 1rem;
            }

            svg {
                color: $color-gu-blue-dusty;
            }
        }
    }

    &__product-list-content {
        background-color: $white;
        padding: 1rem;
        display: grid;
        grid-template-columns: 80px auto;
        gap: 1rem;
        grid-template-rows: auto 1fr;
        grid-row: 1/2;

        @media (min-width: 550px) {
            grid-row: 1;
            grid-template-columns: 80px 1fr auto;
            grid-template-rows: auto;
            padding-bottom: 0;
            padding-top: 0;
        }

        img {
            grid-row: span 2;
            height: auto;
            @include mix-shadow-product-img();

            @media (min-width: 550px) {
                grid-row: span 1;
                height: 80px;
                width: 80px;
                object-fit: cover;
                object-position: 0% 0%;
                align-self: end;
            }
        }
    }

    &__product-list-checkbox {
        background-color: rgba(245, 245, 245, 1);
        grid-row: 2/3;
        padding: 0.75rem;
        border-top: solid 1px $color-gu-fade-20;

        @media (min-width: 550px) {
            background-color: transparent;
            grid-row: 1;
            padding: 0;
            align-self: start;
            border: none;
        }

        .custom-checkbox {
            justify-self: center;

            @media (min-width: 550px) {
                grid-row: 1;
                align-self: center;
            }

            label {
                gap: 0.5rem;
                align-items: center;

                span {
                    font-size: 1rem;
                }
            }

            .content-rich-text {
                @media (min-width: 550px) {
                    position: absolute;
                    opacity: 0;
                }
            }
        }
    }

    .amount-input {
        align-self: start;
        justify-self: start;

        &__input,
        &__label-text {
            @media (max-width: 550px) {
                min-width: 150px;
            }
        }
    }
}
