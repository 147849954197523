@use "sass:math";

.article-content {
    position: relative;
    max-width: math.div($max-width, 2);
    margin-left: auto !important;
    margin-right: auto !important;

    @include themer($theme-editor) {
        border: 1px solid $lightgrey !important;
    }

    @include mix-rich-text-markup;

    h1 {
        margin: 3rem 0rem;
        text-align: center;
    }
}

.article-content__left {
    float: left;
    width: 50%;
    margin-right: 1em;
}
.article-content__right {
    float: right;
    width: 50%;
    margin-left: 1em;
}
