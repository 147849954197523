.checkout-content {
    margin-top: $gutter;

    @media (min-width: $breakpoint-w-lion) {
        margin-top: $gutter*1.5;
    }

    &--adjust-height {
        @media (min-width: $breakpoint-w-rhino) and (min-height: $breakpoint-h-eagle) {
            margin-top: $gutter*3;
        }
        @media (min-width: $breakpoint-w-rhino) and (min-height: $breakpoint-h-pelican) {
            margin-top: $gutter*5;
        }
    }
    .content-block {
        .button {
            width: 100%;
            min-width: 230px;

            @media (min-width: $breakpoint-w-cat) {
                width: auto;
            }
        }
    }
}

.checkout-login-box {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &.content-block {
        .button {
            @media (min-width: $breakpoint-w-horse) {
                width: 100%;
            }
        }
    }
}

.checkout-button-column {
    @media (min-width: $breakpoint-w-rhino) {
        width: calc(50% - 12px);
        display: inline-block;
        margin-top: 0 !important;
        margin-bottom: 0 !important;

        & + .checkout-button-column {
            margin-left: 24px;
        }
    }
}

.checkout-content__box-head {
    padding-bottom: 0;
    padding-top: 0;
    @media (max-width: $breakpoint-w-horse) {
        padding-bottom: $gutter;
    }
}

.checkout__shipping-address {
    .checkbox__text {
        font-weight: 600;
    }
}

.checkout__paymentcards-img {
    height: 19px;
    width: auto;
    display: inline-block !important;
    vertical-align: middle;
    margin-left: .5em;
}

.checkout-basket {
    padding: 0;
}

.checkout-basket__submit__terms {
    padding: $gutter;
}

.checkout-basket__submit__button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100% !important;
}

.checkout-content__header-container {
    margin-bottom: 25px;

    @include mix-content-min-width($breakpoint-w-cat) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .button {
        @include mix-content-max-width($breakpoint-w-cat) {
            margin-top: 15px;
        }    
    }
}

.checkout-confirmation-btn-container {
    @include mix-content-min-width($breakpoint-w-cat) {
        padding-left: $gutter;
    }
}