.trial-license-media-type-list {
    display: flex;
    flex-direction: column;
}

.trial-license-media-type-list__button {
    margin: 2rem auto 0;
}

.trial-license-media-type-radio-btn {
    @include reset__button("true");
    text-align: left;
}
