.price-box-list {
    width: 100%;
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.price-box-list__inner {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 0;
}

.price-box-list__list {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include mix-content-min-width($breakpoint-w-rabbit) {
        flex-flow: row wrap;
        justify-content: center;
    }
}

.price-box-list__disclaimer-text {
    font-size: 0.8em;
    display: block;
    margin: 0 auto;

    .price-box-list--hasHighlightedItem & {
        @include mix-content-min-width($breakpoint-w-rabbit) {
            margin-top: $gutter;
        }
    } 
    @include themer($theme-gyldendal) {
        color: $color-gu-fade-23;
    }
    @include themer($theme-hansreitzel) {
        color: $color-hr-blue;
    }
    @include themer($theme-munksgaard) {
        color: $color-mg-fade-40;
    }
}


