.mini-basket {
    display: flex;
    flex-direction: column;
    width: 100%;

    a * {
        #header & {
            // FIX for IE11 / Edge
            pointer-events: auto;
        }
    }

    .header-basket & {
        @include mix-content-max-width($breakpoint-w-cat) {
            max-height: calc(100vh - 150px);
            position: fixed;
            z-index: 3000;

            .hide & {
                max-height: calc(100vh - 100px);
            }
        }

        @include mix-content-min-width($breakpoint-w-cat) {
            height: auto;
            max-height: calc(100vh - 100px - 100px);
        }
    }

    .price--discounted .original-price {
        @include mix-theme-discounted-old-price;
    }
    .discount-price {
        @include mix-theme-discounted-new-price;
    }
}

.mini-basket__scrollable {
    max-height: 60vh; // Fallback for IE11
    width: 100%;
}

.mini-basket__sticky {
    border-top: 1px solid $color-gu-fade-20;
    padding: 15px 20px;

    @include mix-content-max-width($breakpoint-w-cat) {
        padding: 15px $gutter-small;
    }

    .header-basket & {
        background: $lightgrey;
    }
    .content-block & {
        background: $color-gu-fade-15;
    }
    span {
        display: inline-block;
    }
}

.mini-basket__list-container {
    flex: 1 1 auto;

    .header-basket & {
        background: #fff;
    }
    .content-block & {
        background: $lightgrey;
    }
}

.mini-basket-item {
    display: flex;

    &:not(:last-child) {
        border-bottom: 1px solid $color-gu-fade-20;
    }
}

.mini-basket-item__meta-wrapper {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    @include mix-content-max-width($breakpoint-w-cat) {
        padding: 15px $gutter-small;
    }
}

.mini-basket-item__info {
    margin-right: 10px;
    text-align: left;
}

.mini-basket-item__info__anchor {
    text-decoration: none;
}

.mini-basket-item__info__title__container {
    display: flex;
    align-items: center;
}

.mini-basket-item__info__title {
    @include mix-theme-headline-color;
    font-weight: $fontweight-gu-bold;
    overflow: hidden;
    padding-right: 15px;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 20px;
        background: rgba(255, 255, 255, 0);

        .header-basket & {
            background: linear-gradient(to left, #fff 25%, rgba(255, 255, 255, 0));
        }
        .content-block & {
            background: linear-gradient(to left, $lightgrey 25%, rgba(255, 255, 255, 0));
        }
    }
}

.mini-basket-item__info__type-tag {
    margin-right: 4px;
    @include mix-tags--secondary;

    &.type-tag--inspection-copy {
        background-color: $color-gu-orange;
    }
}

.mini-basket-item__info__subtitle {
    @include mix-theme-label-text();

    white-space: nowrap;
    overflow: hidden;
    padding-right: 15px;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 20px;
        background: rgba(255, 255, 255, 0);

        .header-basket & {
            background: linear-gradient(to left, #fff 25%, rgba(255, 255, 255, 0));
        }
        .content-block & {
            background: linear-gradient(to left, $lightgrey 25%, rgba(255, 255, 255, 0));
        }
    }
}

.mini-basket-item__total-price {
    text-align: right;
    flex: none;
}

.mini-basket-item__total-price__price {
    @include mix-theme-headline-color;
    font-weight: $fontweight-gu-bold;
    white-space: nowrap;
    display: inline-block;
    position: relative;

    .content-block & {
        margin: 0;
    }

    &.price--discounted .original-price {
        color: $color-gu-dark-grey;
    }
    .discount-price {
        margin-left: 5px;
        display: block;
    }
}

.mini-basket-item__total-price__calculus {
    @include mix-theme-label-text();
}

.mini-basket-item__actions {
    @include reset__button("true");
    position: relative;
    background: $lightgrey;
    overflow: hidden;
    width: 0;
    transition: width 0.3s;
    border-left: 1px solid $color-gu-fade-20;

    .mini-basket-item:hover &,
    .mini-basket-item--isRemoving & {
        width: 50px;
    }
    .touch & {
        width: 50px;
    }
    @include mix-content-max-width($breakpoint-w-cat) {
        width: 50px;
    }

    .delete-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        svg {
            stroke-width: 1px;
            stroke: $color-gu-dark-grey;

            @include themer($theme-hansreitzel) {
                stroke: $color-hr-blue;
            }

            @include themer($theme-munksgaard) {
                stroke: $color-mg-blue;
            }
        }
    }
}

.mini-basket__summation__items {
    @include mix-theme-label-color;
    border-top: 1px solid $color-gu-fade-20;
    padding: 20px;

    @include mix-content-max-width($breakpoint-w-cat) {
        padding: $gutter-small;
    }

    .header-basket & {
        background: $lightgrey;
    }
    .content-block & {
        background: $color-gu-fade-15;
    }
}

.mini-basket__summation__item {
    line-height: 2;
    display: flex;
    justify-content: space-between;

    &.summation-final-price {
        @include mix-theme-headline-color;
        font-weight: $fontweight-gu-bold;
    }

    &.summation-VAT {
        @include mix-theme-label-color;
    }
}

.mini-basket__summation__item__price {
    .discount-price {
        padding-left: 5px;
    }
}

.mini-basket__button-container {
    flex: 0 0 auto;
    display: flex;
}

.mini-basket__button {
    width: 50%;
    color: #fff;
    font-size: 16px;
    font-family: $body-font-family;
    font-weight: 600;
    text-transform: uppercase;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1em;
    padding: 0.75em 1.5em;

    &.go-to-basket {
        border-radius: 0;

        // COLORS: mix-button--secondary
        @include themer($theme-gyldendal) {
            border-bottom-left-radius: 5px;
            background-color: $color-gu-dark-grey;

            &:hover {
                background-color: darken($color-gu-dark-grey, 4);
            }
        }

        @include themer($theme-hansreitzel) {
            border-bottom-left-radius: 3px;
            background-color: $color-hr-blue;

            &:hover {
                background-color: darken($color-hr-blue, 4);
            }
        }

        @include themer($theme-munksgaard) {
            border-bottom-left-radius: 3px;
            background-color: $color-mg-blue-dusty;

            &:hover {
                background-color: darken($color-mg-blue-dusty, 4);
            }
        }
    }
    &.go-to-checkout {
        border-radius: 0;

        // COLORS: mix-button--primary
        @include themer($theme-gyldendal) {
            border-bottom-right-radius: 5px;
            background-color: $color-gu-orange;

            &:hover {
                background-color: darken($color-gu-orange, 4);
            }
        }

        @include themer($theme-hansreitzel) {
            border-bottom-right-radius: 3px;
            background-color: $color-hr-red;

            &:hover {
                background-color: darken($color-hr-red, 4);
            }
        }

        @include themer($theme-munksgaard) {
            border-bottom-right-radius: 3px;
            background-color: $color-mg-green-dark;
            color: $color-mg-blue;

            &:hover {
                background-color: darken($color-mg-green-dark, 4);
            }
        }
    }
}

.empty-mini-basket {
    background: #fff;
    color: $color-gu-dark-grey;
    padding: 50px;
    text-align: center;
}

.mini-basket-spinner {
    text-align: center;
}
