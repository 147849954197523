.related-products-list-container {
    border-top: 1px solid $color-gu-fade-20;
    border-bottom: 1px solid $color-gu-fade-20;
}

.related-products-list {
    margin-top: -1px;
    margin-bottom: -1px;
    @include mix-content-min-width($breakpoint-w-cat) {
        width: 100%;
    }

    @include mix-content-min-width($breakpoint-w-lion) {
        display: flex;
        flex-wrap: wrap;
    }
}

.related-products-list__item {
    list-style: none;
    position: relative;
    border: 1px solid $color-gu-fade-20;
    border-left: none;
    overflow: auto;
    display: flex;

    // 1 column
    @include mix-content-min-width($breakpoint-w-cat) {
        width: 100%;
        border-right: none;
    }
    &:not(:nth-child(1)) {
        @include mix-content-min-width($breakpoint-w-cat) {
            border-top-color: transparent;
        }
        @include mix-content-min-width($breakpoint-w-lion) {
            border-top-color: $color-gu-fade-20;
        }
    }

    // 2 column
    @include mix-content-min-width($breakpoint-w-lion) {
        width: 50%;
        border-right: 1px solid $color-gu-fade-20;
    }
    &:nth-child(n + 3) {
        //not first row (first 2)
        @include mix-content-min-width($breakpoint-w-lion) {
            border-top-color: transparent;
        }
        @include mix-content-min-width($breakpoint-w-elephant) {
            border-top-color: $color-gu-fade-20;
        }
    }
    &:not(:nth-child(2n - 1)) {
        //not first column (2)
        @include mix-content-min-width($breakpoint-w-lion) {
        }
        @include mix-content-min-width($breakpoint-w-elephant) {
        }
    }

    // 3 column
    @include mix-content-min-width($breakpoint-w-elephant) {
        width: 33.3333333%;
    }
    &:nth-child(n + 4) {
        //not first row (first 3)
        @include mix-content-min-width($breakpoint-w-elephant) {
            border-top-color: transparent;
        }
    }
    &:not(:nth-child(3n - 2)) {
        //not first column (3)
        @include mix-content-min-width($breakpoint-w-elephant) {
        }
    }
}

.related-products-list-container__view-more-btn {
    height: 50px;
    width: 100%;
    padding-top: 20px;
    cursor: pointer;
    display: none;
    background: $color-gu-blue-dusty;

    @include themer($theme-hansreitzel) {
        background: $color-hr-red-light;
    }
    @include themer($theme-munksgaard) {
        background: $color-mg-blue-dusty;
    }

    @include mix-content-min-width($breakpoint-w-cat) {
        display: inline-block;
    }
    @include mix-content-min-width($breakpoint-w-elephant) {
        width: 99.99%;
    }
    .arrow-icon {
        border-color: #fff;
    }
}
