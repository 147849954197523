@use "sass:math";
.order-details__header-container {
    @include mix-grid-section();
    margin-bottom: $gutter;

    @include mix-content-min-width($breakpoint-w-dog) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.order-details__header {
    @include mix-theme-like-h2();
}

.order-details__button {
    @include mix-content-max-width($breakpoint-w-dog) {
        margin-top: 15px;
    }
}

.order-details__meta {
    @include mix-grid-section();
    margin-bottom: $gutter * 2;

    @include mix-content-min-width($breakpoint-w-dog) {
        display: flex;
    }
}

.order-details__meta-inner {
    flex-grow: 1;
}

.order-details__meta__order-date,
.order-details__meta__order-no,
.order-details__meta__order-status,
.order-details__meta__order-delivery {
    display: flex;
    margin-bottom: 0.5em;
}

.order-details__meta__label {
    font-weight: bold;
    min-width: 130px;

    @include mix-content-min-width($breakpoint-w-cat) {
        min-width: 150px;
    }
}

.order-details_products-wrapper {
    padding-top: $gutter * 2;
    padding-bottom: $gutter * 2;
    background: $color-gu-fade-00;
    border-top: 1px solid $color-gu-fade-20;
    border-bottom: 1px solid $color-gu-fade-20;
}

.order-details_products-container {
    max-width: $max-width;
    margin: 0 auto;
}

.order-details__products__info-container {
    @include mix-grid-section();
    margin-bottom: $gutter * 2;

    @include mix-content-min-width($breakpoint-w-lion) {
        padding-right: calc((100% / 3) + 25px);
    }
    @include mix-content-min-width($breakpoint-w-elephant) {
        padding-right: $gutter;
        width: 33.3333333%;
        float: left;
    }
}

.order-details__product-list-container {
    overflow: auto;
}

.order-details__product-list__empty {
    @include mix-grid-section();
    margin-bottom: $gutter * 2;
    text-align: center;
}

// BUNDLES
.order-list_bundle-item {
    display: block;
}
.order-list__bundle-item__header {
    padding: 20px;
    display: flex;

    .basket-list__item__meta__thumb {
        margin-top: 0;
    }

    ~ .basket-list__item {
        border-left: none;
        border-right: none;
        background-color: $lightgrey;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 10px;
            background: $color-gu-dark-grey;
        }

        .basket-list__item__info__title:after {
            background: rgba(245, 245, 245, 0);
            background: linear-gradient(to left, $lightgrey 25%, rgba(245, 245, 245, 0));
        }

        .mobile-list & {
            border-bottom: none;
            margin-bottom: 0;
            border-radius: 0;
        }
    }
}
/*
.profile-my-orders-list-desktop {
    .order-details-container {
        border-top: 1px solid $color-gu-fade-20;
        padding-top: $gutter;
        background-color: $white;
    }
    .order-details__header-container {
        display: none;
    }

    .basket-list-wrapper {
        padding-inline: 0;
    }

    .basket-list {
        padding: 0 2.5rem 0 2.5rem;
        * {
            border-radius: 0;
            border-left: none;
            border-right: none;
        }
    }

    .basket-list__item:first-child {
        border-top: none;
    }

    .basket-list__item__total-price {
        padding-right: 0;
    }

    .basket-list__item__total-price__price {
        font-weight: inherit;
        font-size: inherit;
    }

    .basket-list__item__unit-price__price {
        font-weight: inherit;
    }

    .basket-list__item__meta {
        // overflow: visible;
    }

    .basket-list__item__meta__thumb {
        // margin-left: 0;
    }

    .basket-list-header {
        padding-top: 0.75rem;
        background-color: $color-gu-fade-00;
        font-size: 0.875rem;
        font-weight: $fontweight-gu-light;
    }
    .order-list_bundle-item.basket-list__item {
        margin-top: $gutter;
        border: 1px solid $color-gu-fade-20;
        border-radius: $border-radius;
        overflow: hidden;
        .basket-list__item__total-price {
            padding-right: 0.5rem;
        }
    }

    .order-list__bundle-item__header {
        ~ .basket-list__item {
            background-color: $color-gu-fade-00;
            background-color: inherit;
            .basket-list__item__info__title:after {
                background: rgba($white, 0);
                background: linear-gradient(to left, $white 25%, rgba($white, 0));
            }
        }
    }

    .order-details__meta {
        margin-bottom: $gutter;
        padding-inline: 2.5rem;
    }

    .basket-list-header__item {
        &:first-child {
            padding-right: 0;
            padding-left: 2.5rem;
        }
        &:nth-child(2) {
            width: calc(12.5% + 2rem);
        }
        &:last-child {
            padding-right: 2.5rem;
        }
    }

    .order-details__meta-inner {
        &:has(.order-details__meta__order-date) {
            display: none;
        }
    }

    .order-details__meta__order-delivery {
        flex-direction: column;
    }

    .order-details_products-wrapper {
        background-color: transparent;
        padding-top: 0;
        border-top: none;
        border-bottom: none;
    }

    .order-details_products-container {
        display: grid;
        grid-template-areas: "list list" "my-digital-products summation";
        grid-template-columns: 1fr 1fr;
        column-gap: $gutter;
    }
    .order-details__products__info-container {
        grid-area: my-digital-products;
        width: 100%;
        padding-right: 0;
        padding-left: 2.5rem;
    }
    .order-details__product-list-container {
        grid-area: list;
    }
    .basket-summation-container {
        grid-area: summation;
        margin: 0;
        padding-right: 2.5rem;
    }

    .basket-summation {
        width: 100%;
        margin: 0;
    }

    .summation-item__delimiter {
        border-bottom-color: $color-gu-fade-20;
    }
    // ==============

    // .order-details__button {
    //     @include mix-content-max-width($breakpoint-w-dog) {
    //         margin-top: 15px;
    //     }
    // }

    // .order-details__meta {
    //     @include mix-grid-section();
    //     margin-bottom: $gutter * 2;

    //     @include mix-content-min-width($breakpoint-w-dog) {
    //         display: flex;
    //     }
    // }

    // .order-details__meta-inner {
    //     flex-grow: 1;
    // }

    // .order-details__meta__order-date,
    // .order-details__meta__order-no,
    // .order-details__meta__order-status,
    // .order-details__meta__order-delivery {
    //     display: flex;
    //     margin-bottom: 0.5em;
    // }

    // .order-details__meta__label {
    //     font-weight: bold;
    //     min-width: 130px;

    //     @include mix-content-min-width($breakpoint-w-cat) {
    //         min-width: 150px;
    //     }
    // }

    // .order-details_products-wrapper {
    //     padding-top: $gutter * 2;
    //     padding-bottom: $gutter * 2;
    //     background: $color-gu-fade-00;
    //     border-top: 1px solid $color-gu-fade-20;
    //     border-bottom: 1px solid $color-gu-fade-20;
    // }

    // .order-details_products-container {
    //     max-width: $max-width;
    //     margin: 0 auto;
    // }

    // .order-details__products__info-container {
    //     @include mix-grid-section();
    //     margin-bottom: $gutter * 2;

    //     @include mix-content-min-width($breakpoint-w-lion) {
    //         padding-right: calc((100% / 3) + 25px);
    //     }
    //     @include mix-content-min-width($breakpoint-w-elephant) {
    //         padding-right: $gutter;
    //         width: 33.3333333%;
    //         float: left;
    //     }
    // }

    // .order-details__product-list-container {
    //     overflow: auto;
    // }

    // .order-details__product-list__empty {
    //     @include mix-grid-section();
    //     margin-bottom: $gutter * 2;
    //     text-align: center;
    // }

    // // BUNDLES
    // .order-list_bundle-item {
    //     display: block;
    // }
    // .order-list__bundle-item__header {
    //     padding: 20px;
    //     display: flex;

    //     .basket-list__item__meta__thumb {
    //         margin-top: 0;
    //     }

    //     ~ .basket-list__item {
    //         border-left: none;
    //         border-right: none;
    //         background-color: $lightgrey;
    //         position: relative;

    //         &:before {
    //             content: "";
    //             position: absolute;
    //             top: 0;
    //             left: 0;
    //             height: 100%;
    //             width: 10px;
    //             background: $color-gu-dark-grey;
    //         }

    //         .basket-list__item__info__title:after {
    //             background: rgba(245, 245, 245, 0);
    //             background: linear-gradient(to left, $lightgrey 25%, rgba(245, 245, 245, 0));
    //         }

    //         .mobile-list & {
    //             border-bottom: none;
    //             margin-bottom: 0;
    //             border-radius: 0;
    //         }
    //     }
    // }
}
*/
