.error-message{
    padding: .6em;
    background-color: $color-error;
    color: #fff;
    font-weight: 100;
    width: auto;
}

.error-success{
    padding: .6em;
    background-color: $color-gu-green;
    color: #fff;
    font-weight: 100;
    width: auto;
}
