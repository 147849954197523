.divider {
    margin: $gutter 0;
    border-bottom: 1px solid $color-gu-fade-20;
    width: auto;

    @include mix-content-min-width($breakpoint-w-dog) {
        margin: $gutter*2 0;
    }
    @include mix-content-min-width($breakpoint-w-lion) {
        margin: $gutter*3 0;
    }
    @include mix-content-min-width($breakpoint-w-rhino) {
        margin: $gutter*4 0;
    }
}
