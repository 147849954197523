.checkout-receipt-basket {
    margin-bottom: $gutter * 2;
}

.checkout-receipt-basket__inner {
    border-top: 1px solid $color-gu-fade-20;
    border-bottom: 1px solid $color-gu-fade-20;

    padding: $gutter * 2 0;
}
.checkout-receipt-basket_products-container {
    max-width: $max-width;
    margin: 0 auto $gutter;
}
.checkout-receipt-basket__info-container {
    @include mix-grid-section();
    margin-bottom: $gutter * 2;

    @include mix-content-min-width($breakpoint-w-lion) {
        padding-right: calc((100% / 3) + 25px);
    }
    @include mix-content-min-width($breakpoint-w-elephant) {
        padding-right: $gutter;
        width: 33.3333333%;
        float: left;
    }
}
.checkout-receipt-basket__product-list-container {
    overflow: auto;
}
