.article-preview {
    margin-top: 1em;
    margin-bottom: 1em;

    .teaser {
        margin-top: .5em;
    }
    .teaser__meta-wrapper:after {
        display: none;
    }
}