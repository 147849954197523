.two-column-grid {
    @include mix-grid-section;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1rem;
    @include mix-content-min-width($breakpoint-w-cat) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.two-column-grid-column {
    background-color: #f2f2f2;
    padding: 3rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 3px;

    @include mix-content-max-width($breakpoint-w-dog) {
        padding: 1rem;
    }

    img {
        justify-self: center;
        margin-top: auto 0;
    }

    .two-column-grid-column__video {
        @supports not (aspect-ratio: auto) {
            width: 100%;
            padding-bottom: 56.25%; // aspect-ratio: 16:9;
            position: relative;

            div {
                position: absolute;
                inset: 0;
            }

            iframe {
                display: block;
                width: 100%;
                height: 100%;
            }
        }

        @supports (aspect-ratio: auto) {
            width: 100%;

            iframe {
                aspect-ratio: 16 / 9;
                display: block;
                width: 100%;
            }
        }
    }

    .two-column-grid-column__title {
        @include mix-theme-like-h2();
        text-transform: uppercase;
    }
    .two-column-grid-column__teaser {
        font-size: 1rem;
        margin: 1rem 0;
    }

    .two-column-grid-column__richText {
        margin-top: 1.5rem;
        ul {
            padding: 0 3rem;
            list-style: none;

            li {
                position: relative;
                margin-bottom: 2rem;

                &:last-child {
                    margin-bottom: 0;
                }

                &::before {
                    content: "";
                    background-image: url("/dist/assets/icons/icon-checkmark-gu-orange.svg");
                    background-size: 1.2rem 1.2rem;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-color: transparent;
                    position: absolute;
                    left: -1.5rem;
                    transform: translateX(-50%);
                    width: 1.5rem;
                    height: 1.5rem;
                    border: 2px solid $color-gu-orange;
                    border-radius: 1rem;
                }
            }
        }
    }

    .two-column-grid-column__cta {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        padding-top: 3rem;
        margin-top: auto;

        &:empty {
            display: none;
        }

        div {
            a {
                @include mix-button-is-slim();
                @include mix-button-is-fluid;
            }

            &.two-column-grid-column--primary {
                a {
                    @include mix-button--primary();
                }
            }
            &.two-column-grid-column--secondary {
                a {
                    @include mix-button-hollow--secondary();
                    border-color: currentColor;
                    color: currentColor;
                }
            }
        }
    }

    &.two-column-grid-column--image {
        color: #fff;

        .two-column-grid__teaser {
            margin-bottom: 3rem;
        }

        .two-column-grid-column__title {
            color: #fff;
        }
    }

    &.two-column-grid-column--coverImage {
        padding: 0;
        background-color: unset;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            margin-top: 0;
        }
    }
}
