.image-text-cta-list {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;

    .title {
        scroll-margin-top: $navigation-header-height + 10px;
    }
}

.image-text-cta-list__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    // Compensating for the item padding
    margin-top: -$gutter-small;
    margin-bottom: -$gutter;

    @include mix-content-min-width($breakpoint-w-cat) {
        flex-flow: row wrap;
        justify-content: center;
        // Compensating for the item padding
        margin-top: -$gutter;
    }
}

.image-text-cta-list__list-item {
    padding: $gutter-small $gutter-small $gutter;
    display: flex;
    flex-direction: column;

    .title-intro-rich-cta-section {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .title-intro-rich-cta-section__action-wrapper {
        margin-top: auto;
    }

    @include mix-content-min-width($breakpoint-w-cat) {
        padding: $gutter;
        width: 50%;
    }
    @include mix-content-min-width($breakpoint-w-dog) {
        width: 33.3333333%;
    }
    @include mix-content-min-width($breakpoint-w-horse) {
        width: 25%;
    }

    &:only-child, // One item
    &:first-child:nth-last-child(2), // Two items
    &:first-child:nth-last-child(2) ~ .image-text-cta-list__list-item {
        @include mix-content-min-width($breakpoint-w-dog) {
            width: 50% !important;
        }

        .image-text-cta-list--keep-image-aspect-ratio & {
            .image-text-cta-list__image-wrapper {
                height: 250px;
            }
        }
    }
    &:first-child:nth-last-child(3), // Three items
    &:first-child:nth-last-child(3) ~ .image-text-cta-list__list-item {
        @include mix-content-min-width($breakpoint-w-dog) {
            width: 50%;
        }
        @include mix-content-min-width($breakpoint-w-lion) {
            width: 33.3333333% !important;
        }

        .image-text-cta-list--keep-image-aspect-ratio & {
            .image-text-cta-list__image-wrapper {
                height: 200px;
            }
        }
    }
}

.image-text-cta-list__image-wrapper {
    position: relative;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    width: 175px;
    height: 175px;

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @include object-fit(cover);
    }

    .image-text-cta-list--keep-image-aspect-ratio & {
        width: 100%;
        max-width: 250px;

        img {
            position: relative;
            width: auto;
            height: auto;
            max-height: 100%;
            transform: translate(-50%, -100%);
            top: 100%;
            margin: auto;
        }
    }
}
