@mixin mix-boxes__background() {

  background-color:$color-gu-fade-10;

  @include themer($theme-hansreitzel) { background-color:$color-hr-blue-light; }

  @include themer($theme-munksgaard) { background-color:$color-mg-fade-30; }
}

@mixin mix-boxes__background--inverted() {

  background-color:$color-gu-dark-grey;

  @include themer($theme-hansreitzel) { background-color:$color-hr-blue; }

  @include themer($theme-munksgaard) { background-color:$color-mg-blue-light; }
}

@mixin mix-boxes__background--active() {

  background-color: mix($color-gu-dark-grey, $color-gu-fade-10, 5);

  @include themer($theme-hansreitzel) {
    background-color: mix($color-hr-blue,$color-hr-blue-light, 5);
  }

  @include themer($theme-munksgaard) {
    background-color:mix($color-mg-blue-light, $color-mg-fade-30, 30);
  }
}

@mixin mix-boxes__background--hover() {

  background-color: mix($color-gu-dark-grey, $color-gu-fade-10, 13);

  @include themer($theme-hansreitzel) {
    background-color: mix($color-hr-blue,$color-hr-blue-light, 13);
  }

  @include themer($theme-munksgaard) {
    background-color:mix($color-mg-blue-light, $color-mg-fade-30, 40);
  }
}

@mixin mix-boxes__border() {

  border:1px solid $color-gu-fade-20;

  @include themer($theme-hansreitzel) {
    border:1px solid shade($color-hr-blue-light, 6);
  }

  @include themer($theme-munksgaard) {
    border:1px solid $color-mg-fade-40;
  }
}

@mixin mix-boxes__border-color() {
  border-color:$color-gu-fade-20;

  @include themer($theme-hansreitzel) {
    border-color:$color-gu-fade-20;
  }

  @include themer($theme-munksgaard) {
    border-color:$color-mg-fade-40;
  }
}