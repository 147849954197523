.navigation-search {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(0%);
    transition: transform 200ms ease;
    height: $navigation-header-height;
    background-color: $navigation-search-background-color;
    color: $navigation-search-text-color;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 40;
    &--isOpen {
        transform: translateY(100%);
    }
    .inline-search__input {
        background-color: $navigation-search-input-background-color;
    }
}
