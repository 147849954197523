.navigation-basket {
    height: 100%;
    display: flex;
    align-items: center;
    color: inherit;
    transform: scale(1);

    svg {
        display: block;
    }

    &--updating {
        animation-name: basket-scale-animation;
        animation-duration: 0.4s; // if you change this, remember to change the duration in the JS as well
        animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
        animation-iteration-count: 4; // if you change this, remember to change the duration in the JS as well
        animation-direction: alternate;
        animation-fill-mode: forwards; /* Ensures the animation stays at the last keyframe */
    }
}

@keyframes basket-scale-animation {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.4);
    }
}

.navigation-basket__inner {
    position: relative;
}

.navigation-basket__count {
    font-size: 0.8rem;
    font-weight: $fontweight-gu-bold;
    position: absolute;
    left: 55%;
    top: -0.1em;
    transform: translateX(-50%);
    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-regular;
    }
}
