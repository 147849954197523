@use "sass:math";

.loader {
    display: inline-block;

    span,
    &:before,
    &:after {
        display: inline-block;
        width: 5px;
        height: 5px;
        margin: 0 1px;
        border-radius: 50%;
        vertical-align: middle;
        content: "";
        opacity: 0;
        animation: loader 1.4s both infinite;
        background-color: $color-gu-dark-grey;

        @include themer($theme-hansreitzel) {
            background-color: $color-hr-blue;
        }

        @include themer($theme-munksgaard) {
            background-color: $color-mg-blue-dusty;
        }
    }

    span {
        animation-delay: 200ms;
    }

    &:after {
        animation-delay: 400ms;
    }

    .button-is-loading & {
        transition: all 250ms $easeInOutQuart;
        width: 100%;
        display: block;
        transform: translateY(-100%);
    }
}

.loader--large {
    span,
    &:before,
    &:after {
        width: 10px;
        height: 10px;
        margin: 0 2px;
    }
}

.loader--block {
    background-color: #fff;
    display: inline-block;
    padding: math.div($gutter, 3) math.div($gutter, 2);
    border-radius: 5px;
    min-width: 5em;
    margin: 5px;
}

@keyframes loader {
    0% {
        opacity: 0.2;
    }

    20% {
        opacity: 1;
    }

    100% {
        opacity: 0.2;
    }
}
