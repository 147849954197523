.profile-my-digital-products-list-mobile {
    display: none;
    @include mix-content-max-width($breakpoint-w-lion) {
        display: block;
    }
}

.profile-my-digital-products-list-item-mobile {
    margin-bottom: 1rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border: 1px solid $color-gu-fade-20;
    border-radius: $border-radius;
    color: $dark-grey;
    font-size: 0.875rem;
    &:last-of-type {
        margin-bottom: 0;
    }
}
.profile-my-digital-products-list-item-mobile__header {
    display: flex;
    gap: 1.5rem;
    font-size: 1rem;
    padding: 1rem 2rem 1rem 1rem;
    img {
        width: 60px;
        height: 80px;
        object-fit: contain;
        object-position: top;
        filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.08)) drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.16));
    }
}
.profile-my-digital-products-list-item-mobile__title {
    @include mix-theme-bold-bodyfont;
    font-size: 1rem;
    line-height: 1;
    color: $dark-grey;
}

.profile-my-digital-products-list-item-mobile__button {
    background-color: $color-grey-100;
    width: 100%;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-my-digital-products-list-item-mobile__order-information {
    background-color: $color-grey-100;
    padding: 0.5rem 2rem 2rem 2rem;
    @include mix-theme-bold-bodyfont;

    > div {
        margin-top: 1.25rem;
        &:first-of-type {
            margin-top: 0;
        }
    }
}
.profile-my-digital-products-list-item-mobile__owner {
    @include mix-theme-regular-bodyfont;
}
