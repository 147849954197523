@use "sass:math";

.text-cta-container {
    @include mix-grid-section;

    .title {
        @include mix-theme-like-h1();
    }
}

.text-cta {
    max-width: math.div($max-width, 2);
    margin-left: auto;
    margin-right: auto;
}
