.unic-user-info-card {
    display: grid;
    grid-template-columns: auto 1fr;
    color: $black;
    font-size: 0.875rem;
    line-height: 1.4;
    background-color: $unic-userinfo-card-background-color;
    border-radius: $border-radius;
    padding: 1rem 1.5rem;
    margin-bottom: 1.5rem;
    grid-gap: 0.5rem 1.2rem;
    .label {
        font-weight: $fontweight-gu-bold;
    }
}
