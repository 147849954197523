.product-teaser {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
}

.product-teaser-inner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0;

    // Compensating for the item margin
    margin-bottom: -$gutter-small;
    
    @include mix-content-min-width($breakpoint-w-cat) {
        flex-direction: row;
        flex-wrap: wrap;

        // Compensating for the item margin
        margin-bottom: -$gutter;
    }
}