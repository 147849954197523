@use "sass:math";

.basket-container {
    margin-bottom: $gutter * 2;
}
.basket-header-container {
    @include mix-grid-section();
    margin-bottom: $gutter;
    margin-top: $gutter;

    @include mix-content-min-width($breakpoint-w-cat - 200) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.basket-header__trumpet {
    @include mix-theme-trumpet();
}
.basket-header__header {
    @include mix-theme-like-h2();
}

// ----------- Basket List -----------
.basket-list-container {
    background: $color-gu-fade-00;
    border-top: 1px solid $color-gu-fade-20;
    border-bottom: 1px solid $color-gu-fade-20;
    padding: $gutter 0;

    @include mix-content-min-width($breakpoint-w-lion) {
        padding: $gutter * 2 0;
    }
}
.basket-list-wrapper {
    @include mix-grid-section();
    margin-bottom: $gutter;

    @include mix-content-min-width($breakpoint-w-cat) {
        margin-bottom: $gutter * 2;
    }
}
.basket-list {
    width: 100%;
    border-collapse: separate;
    list-style: none;

    .price--discounted .original-price {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            top: 50%;
            border-bottom: 1px solid $color-gu-dark-grey;
        }
    }
    .discount-price {
        color: $color-gu-green;
    }

    > :first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    > :last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom: 1px solid $color-gu-fade-20;
    }
}
.basket-list-header {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    font-weight: 600;

    .mobile-list & {
        display: none;
    }
}
.basket-list-header__item {
    &:nth-child(1) {
        flex-grow: 1;
        padding: 0 20px 10px;
    }
    &:nth-child(2) {
        min-width: 160px;
        width: calc(37.5% - 25%);

        .basket--editable & {
            width: calc(37.5% - 25% - 50px);
        }
    }
    &:nth-child(3) {
        text-align: right;
        width: 25%;
        padding-right: 20px;

        .basket--editable & {
            width: calc(25% + 50px);
            padding-right: calc(20px + 50px);
        }
    }
}
.basket-list__item {
    display: flex;
    background: #fff;
    border: 1px solid $color-gu-fade-20;
    border-bottom: none;

    .mobile-list & {
        display: block;
        border-radius: 5px;
        margin-bottom: 20px;
        border: 1px solid $color-gu-fade-20;
    }
}
.basket-list__item__meta {
    flex-grow: 1;
    display: flex;
    overflow: hidden;
}

.basket-list__item__meta__thumb {
    flex: 0 1 85px;
    position: relative;
    margin: 20px 0 20px 20px;
}
.basket-list__item__thumb__container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;

    .mobile-list & {
        position: relative;
    }
    img {
        max-width: 100%;
        height: auto;
        width: auto;
        vertical-align: middle;
        @include mix-shadow-product-img();
    }
}
.basket-list__item__meta__info {
    padding: 20px;
    flex: 1 1 230px;
    width: 10px; // Needed - otherwise it cannot scrink!!!
    overflow: hidden;
}

.basket-list__item__info__title__container {
    display: flex;
}

.basket-list__item__systime-text {
    font-size: 0.8em;
    margin-top: 5px;
}

.basket-list__item__info__title {
    @include mix-theme-headline-color;
    font-weight: $fontweight-gu-bold;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 15px;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 20px;
        background: rgba(255, 255, 255, 0);
        background: linear-gradient(to left, #fff 25%, rgba(255, 255, 255, 0));
    }
}
.basket-list__item__info__type-tag {
    margin-right: 4px;
    margin-bottom: 2px;
    @include mix-tags--secondary;

    &.type-tag--inspection-copy {
        @include mix-tags--primary;
    }
    &.type-tag--licence-type {
        @include mix-tags-hollow--secondary;
    }
}
.basket-list__item__info__subtitle {
    @include mix-theme-label-text();
    word-wrap: break-word;
}
.basket-list__item__unit-price {
    padding: 20px;
    flex: 0 1 250px;
    align-self: center;

    .mobile-list & {
        padding: 0 0 5px;
    }
}
.basket-list__item__unit-price__label {
    @include mix-theme-label-text();
}
.basket-list__item__unit-price__price {
    position: relative;
    font-weight: bold;

    .mobile-list & {
        @include mix-theme-label-text();
    }

    span {
        white-space: nowrap;
        display: inline-block;
    }

    .original-price {
        margin-right: 5px;
    }
}

.basket-list__item__counter {
    padding: 20px 0;
    min-width: 160px;
    width: calc(37.5% - 25%);
    align-self: center;

    .basket--editable & {
        width: calc(37.5% - 25% - 50px);
    }

    .mobile-list & {
        padding: 20px;
        background: $color-gu-fade-15;
        border-top: 1px solid $color-gu-fade-20;
        width: 100%;

        .amount-input {
            margin-top: 15px;
        }
    }
}

.basket-list__item__counter__license-description {
    @include mix-theme-label-text();
}
.basket-list__item__counter__license-link {
    @include reset__button("true");
    @include mix-theme-like-a();
    text-align: left;
}

.basket-list__item__total-price {
    width: 25%;
    padding: 20px;
    text-align: right;
    line-height: 1.2em;
    align-self: center;

    .mobile-list & {
        width: 100%;
        padding: 20px 0 15px;
        text-align: left;
    }
}
.basket-list__item__total-price__price {
    @include mix-theme-headline-color;
    font-weight: $fontweight-gu-bold;
    font-size: 20px;
    position: relative;

    &.price--discounted .original-price {
        @include mix-theme-discounted-old-price;
        display: inline-block;

        .mobile-list & {
            margin-right: 5px;
        }
    }
    .discount-price {
        @include mix-theme-discounted-new-price;
        margin-left: 5px;
        display: inline-block;

        .mobile-list & {
            margin-left: 0;
        }
    }
}
.basket-list__item__total-price__calculus {
    @include mix-theme-label-text();
    display: block;
    white-space: nowrap;
}

.basket-list__item__actions {
    cursor: pointer;
    position: relative;
    background-color: $lightgrey;
    width: 50px;
    border-left: 1px solid $color-gu-fade-20;

    .mobile-list & {
        width: 100%;
        display: flex;
        height: 50px;
        background-color: $lightgrey;
        border-top: 1px solid $color-gu-fade-20;
        border-radius: 0 0 5px 5px;
    }
}
.basket-list__item__actions__action {
    @include reset__button();
    display: inline-block;
    height: 100%;
    width: 100%;
    @include mix-content-max-width($breakpoint-w-lion) {
        position: relative;
    }

    &:focus {
        outline: solid black;
    }

    &.basket-list__item__edit {
        border-left: 1px solid $color-gu-fade-20;
    }

    &.isEditFieldOpen {
        background: $color-gu-fade-15;
    }

    .delete-icon,
    .edit-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        svg {
            stroke: $color-gu-dark-grey;
            stroke-width: 1px;
        }
    }
}

// ----------- Basket Summation -----------
.basket-summation-container {
    @include mix-grid-section();
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;

    @include mix-content-max-width($breakpoint-w-dog) {
        flex-direction: column;
    }
}
.basket-notice {
    padding-bottom: $gutter;

    @include mix-content-max-width($breakpoint-w-dog) {
        order: 1;
    }

    @include mix-content-min-width($breakpoint-w-dog) {
        width: 50%;
        padding-right: $gutter;
    }

    @include mix-content-min-width($breakpoint-w-rhino) {
        width: calc(25% + 50px + 160px); // aligning with the basket grid
        padding-right: 0;
    }

    &:before {
        top: 0.5em;
    }

    a {
        @include mix-theme-like-a;
    }
}
.basket-summation {
    list-style: none;
    float: right;
    width: 100%;

    @include mix-content-max-width($breakpoint-w-dog) {
        order: 2;
    }

    @include mix-content-min-width($breakpoint-w-dog) {
        width: 50%;
        padding-left: $gutter;
    }

    @include mix-content-min-width($breakpoint-w-rhino) {
        width: calc(25% + 50px + 160px); // aligning with the basket grid
        padding-left: 0;
    }

    .info-on-the-side & {
        @include mix-content-min-width($breakpoint-w-elephant) {
            width: 50%;
            padding-left: $gutter-small * 2;
        }
    }

    .summation-value {
        float: right;
    }
}

.summation-item__booking-fee {
    &.price--discounted .original-price {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            top: 50%;
            border-bottom: 1px solid $color-gu-dark-grey;
        }
    }
    .discount-price {
        color: $color-gu-green;
        margin-left: 5px;
        float: right;
    }
}
.summation-item__discount-code {
    overflow: hidden;
}
.summation-item__delimiter {
    border-bottom: 1px solid grey;
    margin: 10px 0;
}
.summation-item__final-price {
    @include mix-theme-headline-color;
    font-weight: $fontweight-gu-bold;
    font-size: 20px;
    display: flex;
    justify-content: space-between;

    .summation-value {
        margin-left: 20px;
        white-space: nowrap;
    }
}

.action-button-container {
    .basket-bottom-container & {
        @include mix-content-min-width($breakpoint-w-cat - 200) {
            display: flex;
            justify-content: space-between;
        }
        @include mix-content-min-width($breakpoint-w-dog) {
            float: right;
        }
    }
    a {
        @include mix-content-max-width($breakpoint-w-cat - 200) {
            margin-top: 15px;
            width: 100%;
        }
        @include mix-content-min-width($breakpoint-w-cat - 200) {
            &:not(:first-child) {
                margin-left: 15px;
            }
        }
    }
}

.basket-bottom-container {
    @include mix-grid-section();
    margin-top: 30px;
    overflow: auto;

    @include mix-content-max-width($breakpoint-w-cat - 200) {
        width: 100%;
    }
}

// ----------- Empty Basket -----------
.empty-basket {
    @include mix-grid-section();
    text-align: center;
    margin-top: $gutter * 2;
    margin-bottom: $gutter * 2;

    .empty-basket__description {
        margin-bottom: $gutter * 2;
        font-size: 2em;
        color: $color-gu-fade-20;

        @include themer($theme-hansreitzel) {
            color: $color-hr-blue;
        }
    }
}
