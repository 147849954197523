.profile-my-orders-list-desktop {
    display: none;
    @include mix-content-min-width($breakpoint-w-lion) {
        display: block;
    }
}
.profile-my-orders-list-desktop__headers {
    grid-area: headers;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 5rem;
    padding: 0.625rem 2.5rem;

    border: 1px solid $color-gu-fade-20;
    border-radius: $border-radius $border-radius 0px 0px;

    background-color: $color-gu-fade-00;
    color: $color-gu-dark-grey;

    .profile-my-orders-list-desktop__header {
        font-size: 0.875rem;
        font-weight: $fontweight-gu-light;
    }
}
.profile-my-orders-list-item-desktop {
    border: 1px solid $color-gu-fade-20;
    border-top: none;
    color: $dark-grey;
    &:hover {
        background-color: lighten($color-gu-fade-00, 1%);
    }

    &:nth-of-type(odd) {
        background-color: $color-gu-fade-00;
        &:hover {
            background-color: darken($color-gu-fade-00, 1%);
        }
    }
    &:last-child {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
}
.profile-my-orders-list-item-desktop__row {
    color: inherit;
    grid-area: item;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 5rem;
    gap: 0.5rem;
    padding: 2.25rem 2.5rem;

    [role="cell"] {
        &:nth-of-type(even) {
            @include mix-theme-bold-bodyfont;
        }
    }

    .profile-my-orders-list-item-desktop__link {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}
