.product-list__item {
    position: relative;
    margin-bottom: 22px;

    @include themer($theme-gyldendal) {
        border-radius: 5px;
    }
    @include mix-boxes__border();

    @include mix-content-min-width($breakpoint-w-dog) {
        display: flex;
        flex-direction: row;
    }
}

.product__details {
    @include mix-clearfix-table;
    position: relative;
    width: 100%;
    padding: 26px 20px;
    @include themer($theme-gyldendal) {
        border-radius: 5px 5px 0 0;
    }
    background-color: #fff;

    @include mix-content-min-width($breakpoint-w-dog) {
        float: left;
        width: 75%;
        padding: 28px;
        @include themer($theme-gyldendal) {
            border-radius: 5px 0 0 0;
        }
    }

    @include mix-content-min-width($breakpoint-w-lion) {
        width: 70%;
    }

    @include mix-content-min-width($breakpoint-w-rhino) {
        width: 75%;
    }
}

.product-description__header {
    @include mix-content-min-width(500px) {
        margin-bottom: 1rem;
    }
}

.product-description__headline {
    h3 {
        @include mix-theme-like-h3();
    }

    .product__subtitle {
        @include mix-theme-label-text;
    }

    .product__editors,
    .product__authors {
        @include reset__button("true");
        @include mix-theme-overflow-fade-white;
        @include mix-theme-subline-text();

        // We don't want authors to be styled as linkes here (also not clickable)
        a {
            cursor: text;

            @include themer($theme-munksgaard) {
                color: $color-mg-blue-dusty-light;
            }
            @include themer($theme-hansreitzel) {
                color: $color-hr-red;
            }
        }
        .editor-abbreviation {
            margin-left: 5px;
        }
    }

    > a {
        outline: none;
        &:hover,
        &:focus-visible {
            h3,
            .product__subtitle {
                outline: none;
                text-decoration: underline;
                text-decoration-color: currentColor;
            }
        }
    }
}

.product-description {
    @include mix-clearfix-table;
    width: 100%;

    @include mix-content-min-width($breakpoint-w-dog) {
        padding-bottom: 40px;
    }
}

.product-description__content {
    overflow: hidden;
}

.product-description__copy {
    display: block;
    margin-bottom: 1rem;
    position: relative;
    max-height: 4.8em;
    overflow: hidden;

    &:after {
        content: "";
        display: block;
        background: rgba(255, 255, 255, 0);
        background: linear-gradient(to top, #fff 25%, rgba(255, 255, 255, 0));
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 2.5em;
        z-index: 2;
    }
}

.product-description__system-link {
    font-size: 14px;
    margin-bottom: 0;

    @include mix-content-min-width(500px) {
        font-size: 16px;
    }

    a {
        @include mix-theme-like-a();

        @include themer($theme-gyldendal) {
            @include mix-subtheme-primary-color-props("color");
        }
    }
}

.product-variants {
    position: relative;
    margin-top: 1em;

    @include mix-content-min-width($breakpoint-w-dog) {
        @include mix-boxes__border-color;
        @include mix-boxes__background;
        @include themer($theme-gyldendal) {
            border-radius: 0 0 0 5px;
        }
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-style: solid;
        border-top-width: 1px;
        padding: 10px;
    }
}

.product-variants__label,
.product-variants__tags {
    display: inline-block;
}

.product-variants__label {
    font-size: 14px;
    margin: 0 6px 0 0;

    @include mix-content-min-width($breakpoint-w-dog) {
        @include mix-tags-label;
    }
    &:after {
        content: ":";
        @include mix-content-min-width($breakpoint-w-dog) {
            content: "";
        }
    }
}

.product-variants__tags {
    display: inline-block;
    list-style: none;
}

.product-variants__tag {
    @include mix-tags--white;
    margin: 0 2px 3px;

    @include mix-content-min-width($breakpoint-w-dog) {
        margin-left: 2px;
        margin-bottom: 0;
    }
}

.product-image {
    position: relative;
    float: left;
    width: 78px;
    margin-right: 18px;

    @include mix-content-min-width(500px) {
        width: 85px;
    }

    @include mix-content-min-width($breakpoint-w-dog) {
        margin-right: 35px;
    }

    @include mix-content-min-width($breakpoint-w-rhino) {
        width: 95px;
    }

    &:before {
        position: absolute;
        top: 0;
        left: -5%;
        width: 110%;
        height: 100%;
        display: block;
        content: "";
    }

    img {
        width: 100%;
        height: auto;
        display: block;
        @include mix-shadow-product-img();
    }

    a {
        position: relative;
    }
}

.product__price {
    @include mix-boxes__background;
    position: relative;
    width: 100%;

    @include mix-content-min-width($breakpoint-w-dog) {
        @include mix-boxes__border-color;
        float: left;
        width: 25%;
        border-left-width: 1px;
        border-style: solid;

        @include themer($theme-gyldendal) {
            border-radius: 0 0 5px 0;
        }

        .product--multiple & {
            padding-top: 0px;
        }
    }

    @include mix-content-min-width($breakpoint-w-lion) {
        width: 30%;
    }
    @include mix-content-min-width($breakpoint-w-rhino) {
        width: 25%;
    }

    p {
        margin-bottom: 0;
    }
}

.product-prices {
    @include mix-clearfix-table;
    position: relative;
    padding: 16px 18px;

    @include mix-content-max-width($breakpoint-w-dog) {
        display: flex;
        justify-content: space-between;
    }

    @include mix-content-min-width($breakpoint-w-dog) {
        position: static;
        padding: 18px;
    }
}

.product-price__compartment {
    @include mix-content-max-width($breakpoint-w-dog) {
        flex-grow: 1;
        &:last-child {
            text-align: right;
        }
    }

    @include mix-content-min-width($breakpoint-w-dog) {
        &:first-child {
            display: none;
        }
    }
}

.price-label__desc {
    @include mix-theme-headline-color();
    font-weight: 700;

    @include mix-content-min-width($breakpoint-w-dog) {
        @include mix-theme-label-text();
    }
}

.price-label__stock {
    font-size: 14px;

    &.info-type--info {
        @include mix-theme-notification-text("info");
    }
    &.info-type--alert {
        @include mix-theme-notification-text("alert");
    }
}

.price-label__amount {
    color: $black;
    font-weight: $fontweight-gu-bold;

    @include themer($theme-hansreitzel) {
        font-weight: $fontweight-hr-din-bold;
    }

    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-bold;
    }

    @include mix-content-min-width($breakpoint-w-dog) {
        font-size: 20px;
    }
}

.price-label__amount--outdated {
    @include mix-theme-discounted-old-price;
    display: inline-block;
    margin-right: 6px;
}

.price-label__amount--discount {
    @include mix-theme-discounted-new-price;
}

.price-label__vat {
    @include mix-theme-label-text();
}

.price-label__large-only {
    @include mix-content-max-width($breakpoint-w-dog) {
        display: none;
    }
}

.product-action {
    padding: 16px 18px;
    @include mix-content-min-width($breakpoint-w-dog) {
        padding: 18px;
    }
    @include mix-content-max-width($breakpoint-w-dog) {
        .add-to-basket__button {
            flex-grow: 1;
        }
    }

    a {
        @include mix-button--secondary;
        @include mix-button-is-fluid;

        @include mix-content-min-width($breakpoint-w-dog) {
            @include mix-button-is-slim;
        }
        @include mix-content-max-width($breakpoint-w-dog) {
            border-radius: 0 0 5px 5px;

            @include themer($theme-munksgaard, $theme-hansreitzel) {
                border-radius: 0;
            }
        }
    }
}
