.profile-my-orders-list-mobile {
    display: none;
    @include mix-content-max-width($breakpoint-w-lion) {
        display: block;
    }
}

.profile-my-orders-list-item-mobile {
    margin-bottom: 1rem;
    &:last-of-type {
        margin-bottom: 0;
    }
}
.profile-my-orders-list-item-mobile__body {
    display: flex;
    flex-direction: column;
    border: 1px solid $color-gu-fade-20;
    border-radius: $border-radius $border-radius 0px 0px;
    padding: 1rem;
    color: inherit;
}
.profile-my-orders-list-item-mobile__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.profile-my-orders-list-item-mobile__header-order-id {
    @include mix-theme-bold-bodyfont;
    font-size: 1.5rem;
    line-height: 1;
    color: $dark-grey;
}
.profile-my-orders-list-item-mobile__header-link {
    text-decoration: underline;
}
.profile-my-orders-list-item-mobile__order-information {
    margin-top: 0.75rem;
    color: $color-gu-blue-dusty;

    > div {
        font-size: 0.875rem;
        line-height: 1.4;
        margin-top: 0.25rem;
        &:last-of-type {
            margin-top: 0;
        }
    }
}
