// --------------- COLORS ---------------
// Primary
// NOTE: These colors are potentially used in the UI, since we are giving the ColorClasses to the Editor i.e. for landingpage modules.
// See all exposed colors in _color-class-themer.scss
$color-elearn-abbey: #464a4c;
$color-elearn-chestnut-rose: #ed6a5b;
$color-elearn-pampas: #f0ebe6;
$color-elearn-hippie-pink: #aa4b5a;
$color-elearn-roti: #c3aa3c;
$color-elearn-breaker-bay: #559b96;
$color-elearn-soft-amber: #cdc8af;
$color-elearn-east-bay: #3c5f7d;
$color-elearn-yellow: #ffb900;

// Elearning

// --------------- COLORS ---------------
//Primary
$color-hr-black: #6b6b6b;
$color-hr-red: $color-elearn-chestnut-rose; // HR Red - Action color
$color-hr-red-light: lighten($color-hr-red, 10%); // Light red
$color-hr-blue: darken($color-elearn-abbey, 10%); // Blue - Secondary action color
$color-hr-blue-dusty: lighten($color-hr-blue, 25%);
$color-hr-blue-light: darken($color-elearn-pampas, 5%); // Blue - Secondary action color
$color-hr-beige: $color-elearn-pampas; // Beige
$color-hr-green: $color-elearn-pampas;
$color-hr-green-dark: darken($color-hr-green, 30%); // Green dark

//Fades
$color-hr-fade-10: $color-elearn-chestnut-rose; // Menu bg
$color-hr-fade-15: #f39e94; // hamburger
$color-hr-fade-20: #f1887c; // Menu bg - not active
$menu-background-active: #be5549;
$menu-button-active: #d45e50;

//General
$color-field-error: #a2282e;
$color-error-fade: lighten($color-field-error, 25%);

$navigation-bg-color: $color-elearn-pampas;
$navigation-text-color: $color-hr-black;

$navigation-menu-text-color: $color-hr-black;
$navigation-menu-background-color: $white;

$navigation-search-background-color: $color-hr-blue-light;
$navigation-search-input-background-color: $white;
$navigation-search-text-color: $white;

$footer-main-background-color: $color-elearn-pampas;
$footer-main-text-color: $black;
$footer-blocklinks-background-color: $color-elearn-chestnut-rose;
$footer-blocklinks-text-color: $white;

// --------------- FONTS ---------------
// Font weights
$fontweight-hr-regular: 400;
$fontweight-hr-medium: 400;
$fontweight-hr-semibold: 300;
$fontweight-hr-din-normal: normal;
$fontweight-hr-din-bold: bold;

//fonts
$body-font-family: "Source Serif Pro", serif;
$header-font-family: "Lufga", serif;
// $body-font-weight: $fontweight-hr-regular;

// $secondary-font: "Helvetica", "Arial", sans-serif;
// $global-line-height: 1.75;
// $global-font-size: 17px;

// TODO: should our fallback svg's be styled from here???
//       They are not only styled by the color but also depending on the logo
//       (maybe change that size styling to the svg so they can use the same styling)
// TODO: should our fonts be handled here? (right now we handle fonts in _typo.scss)
