@use "sass:math";

$stepcurrent-indicator-color: #fff;
$stepcurrent-indicator-bg-color: $color-gu-orange;
$stepcurrent-bg-color: $color-gu-fade-10;
$step-text-color: $color-gu-dark-grey;
$step-indicator-color: #fff;
$step-indicator-bg-color: $color-gu-dark-grey;
$step-bg-color: #fff;
$step-border-color: $color-gu-fade-20;

@if $site == $theme-hansreitzel {
    $stepcurrent-indicator-bg-color: $color-hr-red;
    $stepcurrent-bg-color: $color-hr-blue-light;
    $step-text-color: black;
    $step-indicator-bg-color: $color-hr-blue;
    $step-bg-color: #fff;
}

@if $site == $theme-munksgaard {
    $stepcurrent-indicator-color: $color-mg-blue;
    $stepcurrent-indicator-bg-color: $color-mg-green-dark;
    $stepcurrent-bg-color: $color-mg-fade-30;
    $step-text-color: $color-mg-blue;
    $step-indicator-bg-color: $color-mg-blue-dusty;
    $step-bg-color: #fff;
}

.checkout-stepbar {
    position: relative;
    border-bottom: 1px solid $step-border-color;
    font-size: 10px;
    text-align: center;

    @media (min-width: $breakpoint-w-wolf + 50) {
        font-size: 16px;
        text-align: left;
    }
}

.checkout-stepbar__inner {
    position: relative;
    z-index: 2;
    max-width: $max-width;
    margin: 0 auto;
    padding: 0 0;
    @include mix-clearfix-block();
}

.stepbar-list {
    list-style-type: none;
}

.stepbar-list__item {
    float: left;
    padding: $gutter * 0.5;
    position: relative;
    background-color: $stepcurrent-bg-color;
    height: 55px;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (min-width: $breakpoint-w-wolf + 50) {
        padding: $gutter * 0.5 $gutter;
    }

    @media (min-width: $breakpoint-w-elephant) {
        padding: $gutter * 0.5 $gutter * 1.5;
    }

    &:nth-of-type(1):nth-last-of-type(5) {
        width: 20%;
        & ~ .stepbar-list__item {
            width: 20%;
        }
    }

    &:nth-of-type(1):nth-last-of-type(4) {
        width: 25%;
        & ~ .stepbar-list__item {
            width: 25%;
        }
    }
    &:nth-of-type(1):nth-last-of-type(3) {
        width: 33.33332221%;
        & ~ .stepbar-list__item {
            width: 33.33332221%;
        }
    }
    &:nth-of-type(1):nth-last-of-type(2) {
        width: 50%;
        & ~ .stepbar-list__item {
            width: 50%;
        }
    }
    &:nth-of-type(1):nth-last-of-type(1) {
        width: 100%;
        & ~ .stepbar-list__item {
            width: 100%;
        }
    }

    &.isCurrent ~ .stepbar-list__item {
        background-color: $step-bg-color;

        &:after {
            border-left: 10px solid $step-bg-color;
        }
    }

    &:before {
        content: "";
        border-left: 10px solid $step-border-color;
        border-top: 28px solid transparent;
        border-bottom: 28px solid transparent;
        position: absolute;
        top: 0;
        left: 1px;
    }
    &:after {
        content: "";
        border-left: 10px solid $stepcurrent-bg-color;
        border-top: 28px solid transparent;
        border-bottom: 28px solid transparent;
        position: absolute;
        top: 0;
        left: 100%;
        z-index: 20;
    }

    &:first-of-type:before,
    &:last-child:after {
        content: none;
    }
}

.stepbar-list__item__link {
    color: $step-text-color;
    font-size: 1em;
}

.step-number {
    color: $step-indicator-color;
    background-color: $step-indicator-bg-color;
    border-radius: 50%;
    margin-right: 0.5em;
    display: inline-block;
    width: math.div(30, 16) * 1em;
    height: math.div(30, 16) * 1em;
    text-align: center;
    line-height: math.div(30, 16) * 1em;
    @include mix-theme-bold-bodyfont;

    .isCurrent & {
        color: $stepcurrent-indicator-color;
        background-color: $stepcurrent-indicator-bg-color;
    }
    @media (max-width: $breakpoint-w-wolf + 50) {
        display: block;
        margin: 0 auto;
    }
}

.step-title {
    line-height: 1.1em;
    max-width: calc(100% - 15px);
    padding-top: 0.3em;
    overflow: visible;
}
