.teaser-grid-simple {
    $root: &;
    $gutter: 1rem;

    max-width: $max-width;
    margin: auto;
    padding: 0 1.5rem;

    @include mix-content-max-width($breakpoint-w-dog) {
        padding: 0 1rem;
    }

    &__tiles {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 1rem;

        @include mix-content-max-width($breakpoint-w-dog) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
    &__tile {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 315px;

        @include mix-content-max-width($breakpoint-w-dog) {
            height: auto;
            grid-column: span 2;
        }

        &--big {
            grid-column: span 2;
        }

        &-image {
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &-content {
            display: flex;
            position: absolute;
            flex-direction: column;
            align-items: center;
            gap: $gutter;
            padding: $gutter;
            max-width: 100%;
            text-align: center;
            word-break: break-word;
            hyphens: auto;

            .button {
                @include mix-content-max-width($breakpoint-w-dog) {
                    max-width: 150px;
                }
            }

            p {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                font-family: $header-font-family;
                font-size: 1.5em;
            }
        }
    }
}
