.inline-search {
    @include mix-content-padding-sides;
    width: 100%;

    .content-row {
        display: flex;
        justify-content: center;
    }
}

.inline-search__input-wrapper {
    position: relative;
    width: 100%;
    max-width: 667px;
    @include mix-theme-headline-color;

    svg {
        position: absolute;
        top: 50%;
        left: 1rem;
        transform: translateY(-50%);
    }
}

.inline-search__input {
    padding: 1rem 2rem 1rem 4rem;
    border-radius: 9999px;
    font-size: 1rem;
    font-family: inherit;
    font-style: normal;
    background-color: $white;
    border: none;
    @include mix-theme-headline-color;
    width: 100%;

    @include themer($theme-gyldendal) {
        border: solid 2px $color-gu-fade-20;
    }
    @include themer($theme-hansreitzel) {
        border-radius: 0;
        // &::placeholder {
        //     color: $white;
        // }
        background-color: transparent;
    }
    &:focus {
        @include themer($theme-munksgaard) {
            outline: 2px solid;
            outline-color: $color-mg-fade-40;
        }
        @include themer($theme-gyldendal) {
            border-color: $color-gu-fade-20;
            outline: none;
        }
        @include themer($theme-hansreitzel) {
            outline: 2px solid;
            outline-color: $color-hr-red;
        }
        @if variable-exists("subtheme") and $subtheme == "elearning" {
            outline: 2px solid;
            outline-color: $color-elearn-pampas;
        }
    }
    .inline-search--isRedirecting & {
        animation: pulse 1s infinite ease-in-out;
    }
}

@keyframes pulse {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.8;
    }
}
