.header__item {
    position: relative;
    z-index: 15;
    border-left: 1px solid rgba(255, 255, 255, .1);
    border-right: 1px solid rgba(255, 255, 255, .1);

    @media (max-width: $breakpoint-header) {
        flex-grow: 1;

        &:last-child {
            border-right: 0;
        }
    }

    svg {
        pointer-events: none;
        stroke: #fff;
        stroke-width: 1px;
    }

    &:not(.header-search) {
        @media (max-width: $breakpoint-header) {
            .search-isOpen & {
                transition: opacity .2s;
                opacity: 0;
            }
        }
    }
}

.header__item__button {
    cursor: pointer;
}

.header__item__label {
    position: absolute;
    visibility: hidden;
    width: 0;
    height: 0;
}

.header__item__icon {
    display: inline-block;
}