.content-icon-block {
    position: relative;
    padding-left: $gutter * 1.4;
}

.content-icon-block__icon {
    width: $gutter * 1.4;
    height: 1.2em;
    position: absolute;
    top: .1em;
    left: 0;

    svg, img {
        position: absolute;
        top: 50%;
        left: 40%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;

        stroke: currentColor;
        fill: currentColor;

        &.stroke-only {
            fill: none;
        }
    }
}

.video-link{
    @include mix-video-button();
}
