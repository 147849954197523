.form-submit-error {
    margin-bottom: rem(16);
    padding: rem(16);
    display: flex;
    border: 1px solid $color-error;
    border-radius: $border-radius;
    color: $color-error;
}

.form-submit-error__icon {
    margin-right: rem(8);
}

.form-submit-error__inner {
    max-width: 65ch;
}
