.product-slider__product-item {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 1.3rem 1rem 3rem 1.3rem;

    @include mix-content-min-width($breakpoint-w-cat) {
        padding: $gutter $gutter 48px;
    }

    .product__meta-container {
        display: grid;
        grid-template-columns: 135px minmax(0, 1fr);
        grid-template-rows: auto;
        grid-column-gap: 2rem;
        grid-template-areas: 
        "product__img product__titles"
        "product__img product__prices-area"
        "product__img btn-container";

        @include mix-content-max-width($breakpoint-w-cat) {
            grid-template-columns: 62px minmax(0, 1fr);
            grid-column-gap: 1.5rem;
            grid-template-areas: 
            "product__img product__titles"
            "product__img product__prices-area"
            "btn-container btn-container";
        }

        .product__img {
            grid-area: product__img;
            display: block;
            margin: auto;
            margin-bottom: $gutter;
    
            @include mix-content-min-width($breakpoint-w-cat) {
                margin: 0 0 $gutter 0;
            }
            img {
                display: block;
                filter: drop-shadow(0px 17px 6px rgb(0 0 0 / 30%)) drop-shadow(0px 7px 5px rgb(0 0 0 / 57%));
            }
        }

        .product__titles {
            grid-area: product__titles;

            .product__title {
                @include mix-theme-overflow-fade-white;
                @include mix-theme-bold-bodyfont();
                font-size: 1.25rem;
                color: $dark-grey;
                
                @include mix-content-max-width($breakpoint-w-cat) {
                    font-size: 1rem;
                }

            }
        
            .product__subtitle {
                @include mix-theme-label-text;
                @include mix-theme-overflow-fade-white;
                min-height: 24px; // to align horizontally...
                margin-bottom: 0.5rem;
            }
        
            .product__editors,
            .product__authors {
                @include mix-theme-subline-text;
        
                // We don't want authors to be styled as linkes here (also not clickable)
                a {
                    cursor: text;
        
                    @include themer($theme-munksgaard) {
                        color: $color-mg-blue-dusty-light;
                    }
                    @include themer($theme-hansreitzel) {
                        color: $color-hr-red;
                    }
                }
        
                .editor-abbreviation {
                    margin-left: 5px;
                }
            }

        }

        .product__prices-area {
            grid-area: product__prices-area;

            .product__price-label,
            .product__price-secondary {
                font-size: 0.75rem;
                color: $color-gu-blue-dusty;

                &.product__price-label {
                    @include mix-theme-medium-bodyfont();
                }
            }
        
            .product__price-label__stock {
                &:before {
                    content: "\00b7";
                    padding: 0 5px;
                    @include mix-theme-paragraph-color;
                }
        
                &.info-type--info {
                    @include mix-theme-notification-text("info");
                }
                &.info-type--alert {
                    @include mix-theme-notification-text("alert");
                }
            }
            .product__price-button-container {
                @include mix-content-min-width($breakpoint-w-cat) {
                    display: flex;
                    justify-content: space-between;
                }
            }
            .product__prices {
                font-size: 20px;
        
                &.discounted .product__price-primary {
                    @include mix-theme-discounted-old-price;
                }
        
                .product__price-primary {
                    @include mix-theme-bold-bodyfont;
                    margin-right: 6px;
                    color: $dark-grey;
                    display: inline-block;
                    font-size: 1.25rem;

                    @include mix-content-max-width($breakpoint-w-cat) {
                        font-size: 1rem;
                    }
                }
                .product__price-discount {
                    @include mix-theme-discounted-new-price;
                    display: inline-block;
                }
            }
        }

        .btn-container {
            grid-area: btn-container;
            display: flex;
            justify-content: end;
            margin-top: auto;
            margin-bottom: 1.5rem;
    
            @include mix-content-max-width($breakpoint-w-dog) {
                .button-secondary {
                    height: 50px;
                    line-height: 48px;
                    padding: 0 !important;
                }
            }
    
            @include mix-content-max-width($breakpoint-w-cat) {
                margin-top: 3rem;
                margin-bottom: 1rem;
                
               .add-to-basket, .add-to-basket__button {
                    flex-grow: 1;
                }
                
            }
        }
    }





    .product__variants {
        @include mix-boxes__background;
        text-transform: uppercase;
        padding: 10px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        margin: 0;
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 50px;
            background: rgba(255, 255, 255, 0);
            background: -webkit-linear-gradient(to left, $color-gu-fade-10, rgba(255, 255, 255, 0));
            background: linear-gradient(to left, $color-gu-fade-10, rgba(255, 255, 255, 0));

            @include themer($theme-hansreitzel) {
                background: -webkit-linear-gradient(to left, $color-hr-blue-light, rgba(255, 255, 255, 0));
                background: linear-gradient(to left, $color-hr-blue-light, rgba(255, 255, 255, 0));
            }

            @include themer($theme-munksgaard) {
                background: -webkit-linear-gradient(to left, $color-mg-fade-30, rgba(255, 255, 255, 0));
                background: linear-gradient(to left, $color-mg-fade-30, rgba(255, 255, 255, 0));
            }
        }
    }
}
