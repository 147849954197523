.article-image-addon {
    @include mix-clearfix-block();
    margin-bottom: 1em;
    width: calc(100% - 1em);
    position: relative;
    transition: all 0.5s;

    @include mix-content-min-width($breakpoint-w-rhino - $gutter * 3) {
        width: calc(125% - 1.5em) !important;

        &.flip-horizontally {
            margin-left: -25%;
        }
    }
    @include mix-content-min-width($breakpoint-w-elephant + $gutter * 3) {
        width: calc(150% - 2em) !important;

        &.flip-horizontally {
            margin-left: -50%;
        }
    }

    img {
        width: 100%;
        height: auto;
        display: block;
    }
}

.article-image-addon__large {
    width: calc(100% / 3 * 2);
    float: left;

    .flip-horizontally & {
        float: right;
        margin-right: -1em;

        @include mix-content-min-width($breakpoint-w-rhino - $gutter * 3) {
            margin-right: -1.5em;
        }
        @include mix-content-min-width($breakpoint-w-elephant + $gutter * 3) {
            margin-right: -2em;
        }
    }
}

.article-image-addon__small {
    width: 33.3333333%;
    float: right;
    margin-right: -1em;

    @include mix-content-min-width($breakpoint-w-rhino - $gutter * 3) {
        margin-right: -1.5em;
    }
    @include mix-content-min-width($breakpoint-w-elephant + $gutter * 3) {
        margin-right: -2em;
    }

    .flip-horizontally & {
        float: left;
        margin-right: 0;
    }
}
