.article-archive {
    margin-bottom: $gutter * 2;
}

.article-archive-filter-container {
    margin-bottom: $gutter * 2;
}

.article-archive-filter__header-container {
    @include mix-grid-section();
    margin-bottom: $gutter;

    @include mix-content-min-width($breakpoint-w-dog) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.article-archive-filter__header {
    @include mix-theme-like-h2();
}

.article-archive-filter__panel-container {
    background: $lightgrey;
    border-top: 1px solid $color-gu-fade-20;
    border-bottom: 1px solid $color-gu-fade-20;
}
.article-archive-filter__panel {
    max-width: $max-width;
    margin: 0 auto;
    height: 50px;
    line-height: 50px;
    display: flex;
    justify-content: space-between;
    padding: 0 $gutter-small;

    @include mix-content-min-width($breakpoint-w-cat) {
        padding: 0 $gutter;
    }
}

.article-archive-filter__subject-filter-btn {
    @include reset__button("true");
    position: relative;
    min-width: 230px;
    font-weight: $fontweight-gu-bold;
    color: black;
    border-left: 1px solid $color-gu-fade-20;
    border-right: 1px solid $color-gu-fade-20;
    padding: 0 50px 0 20px;
}
.article-archive-filter__panel__btn__arrow {
    position: absolute;
    top: 50%;
    right: 1.5em;
    transform: translateY(-50%);
    transition: transform 0.3s;

    svg {
        fill: transparent;
        stroke: $color-gu-dark-grey;
    }
    .isOpen & {
        transform: translateY(-50%) rotate(-180deg);
    }
}
.article-archive-filter__clear-filter-btn {
    @include reset__button("true");
    text-align: left;
    position: relative;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: $fontweight-gu-bold;
    cursor: pointer;

    .clear-btn-icon {
        background: $color-gu-fade-23;
        border-radius: 50%;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        display: inline-block;
        vertical-align: text-top;

        svg {
            stroke: white;
            stroke-width: 3px;
            width: 1.6em;
            height: 1.6em;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .clear-btn-text {
        padding-left: 1em;
        @include mix-content-max-width($breakpoint-w-cat) {
            display: none;
        }
    }
}

.article-archive-filter__content-container {
    border-top: 1px solid $color-gu-fade-20;
}
.article-archive-filter__content {
    max-width: $max-width;
    margin: 0 auto;
}

.article-archive-filter__list {
    margin: $gutter 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}

.article-archive-filter__list__checkbox {
    padding: 1em $gutter;
    width: 100%;

    @include mix-content-min-width($breakpoint-w-cat) {
        width: 50%;
    }
    @include mix-content-min-width($breakpoint-w-rhino) {
        width: 25%;
    }
}

.article-archive-filter__list__amount {
    margin-left: 0.25em;
    color: tint($color-gu-dark-grey, 30);

    @include themer($theme-hansreitzel) {
        color: tint($color-hr-blue, 30);
    }

    @include themer($theme-munksgaard) {
        color: tint($color-mg-blue, 30);
    }
}
