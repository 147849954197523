.event-archive__intro {
    @include mix-grid-section();
}
.event-archive__intro__trumpet {
    @include mix-theme-trumpet();
    margin-bottom: 15px;
}
.event-archive__intro__header {
    @include mix-theme-like-h2();
    margin-bottom: 15px;
}
.event-archive__intro__description {
    @include mix-content-min-width($breakpoint-w-dog) {
        width: 50%;
    }
}

.event-archive__list-container {
    background: $color-gu-fade-00;
    border-top: 1px solid $color-gu-fade-20;
    border-bottom: 1px solid $color-gu-fade-20;
    padding: $gutter*2 0;
    margin: $gutter*2 0;
}
.event-archive__list {
    @include mix-grid-section();
    list-style: none;
}

.event-archive__list__item {
    background: white;
    border: 1px solid $color-gu-fade-20;
    border-radius: 5px;
    margin-bottom: $gutter*1.5;
    display: flex;
    flex-wrap: wrap;

    @include mix-content-min-width($breakpoint-w-dog) {
        flex-wrap: nowrap;
        margin-bottom: $gutter;
    }
}

.event-archive__list__item__thumb-wrapper {
    position: relative;
    overflow: hidden;
    flex: 0 0 100%;
    width: 100%;
    height: 60vw;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    @include mix-content-min-width($breakpoint-w-rabbit) {
        height: 60vw;
        margin: auto;
        border-radius: 0;
        padding: 25px 25px 0 25px;
    }
    @include mix-content-min-width($breakpoint-w-cat) {
        flex: 0 0 33%;
        width: 33%;
        height: 25vw;
        padding: 20px;
    }
    @include mix-content-min-width($breakpoint-w-dog) {
        flex: 0 0 200px;
        width: 200px;
        height: 160px;
        padding: 0;
        min-height: 160px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    @include mix-content-min-width($breakpoint-w-lion) {
        flex: 0 0 250px;
        width: 250px;
    }

    .thumb__img {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .5);
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            max-height: 110%;
            max-width: none;
            transform: translate(-50%, -50%);
        }
    }

    .thumb__date {
        display: flex;
        justify-content: center;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;

        &.date-interval {
            @include mix-content-min-width($breakpoint-w-cat) {
                font-size: 12px;
            }
            @include mix-content-min-width($breakpoint-w-dog) {
                font-size: 16px;
            }
        }

        .date-delimiter {
            font-size: 2em;
            line-height: 2em;
            margin: 0 0.2em;

            &:before {
                content: '-';
            }
        }

        .day {
            font-weight: $fontweight-gu-medium;
            font-size: 4em;
            line-height: 1;
            text-align: center;
        }
        .month {
            @include mix-theme-trumpet();
            color: white;
            text-align: center;
        }
    }
}

.event-archive__list__item__meta {
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    padding-left: 20px;

    .event-archive__list__item__meta__inner {
        padding: 20px 20px 20px 0;
    }

    @include mix-content-min-width($breakpoint-w-cat) {
        flex: 1 1 66%;
        padding-left: 0;
    }
    @include mix-content-min-width($breakpoint-w-dog) {
        padding-left: 20px;
    }

    .meta__trumpet {
        @include mix-theme-trumpet();
    }

    .meta__title {
        font-weight: $fontweight-gu-bold;
        color: black;
    }

    .meta__when,
    .meta__where {
        @include mix-theme-label-text();
    }
    .meta__info {
        @include mix-theme-paragraph-color;
    }
}

.event-archive__list__item__actions {
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    position: relative;

    @include mix-content-min-width($breakpoint-w-dog) {
        flex: 0 0 200px;
        padding: 20px;
        border-left: 1px solid $color-gu-fade-20;
    }
    @include mix-content-min-width($breakpoint-w-lion) {
        flex: 0 0 250px;
    }

    .event-archive__list__item__actions__inner {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .actions__subscribe {
        @include mix-button--primary();
    }
    .actions__read-more {
        @include mix-button--secondary();
    }
    .actions__button {
        @include mix-content-max-width($breakpoint-w-dog){
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
        @include mix-content-min-width($breakpoint-w-dog){
            @include mix-button-is-slim();
        }
    }

    .actions__info {
        @include mix-theme-label-text();
        text-align: center;

        @include mix-content-max-width($breakpoint-w-dog) {
            order: -1;
            border-top: 1px solid $color-gu-fade-20;
            padding: 10px;
            background: $lightgrey;
        }
        @include mix-content-min-width($breakpoint-w-dog) {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin-top: 2em;
        }

        .deadline-label {
            font-weight: 600;
        }

        .deadline {
            margin-left: 5px;
            @include mix-content-min-width($breakpoint-w-dog) {
                display: block;
                margin-left: 0;
            }
        }
    }
}

.empty-archive-text {
    @include mix-grid-section();
    padding: 1em;
    font-size: 2em;
    color: $color-gu-fade-23;
    text-align: center;
}