#WorkArea-error,
#workplaceId-error{
    background-color: $color-error;
    color: #fff;
    font-weight: 100;
    display: inline-block;
    padding: 0 .4em;
    position: relative;
    height: auto;
}

#workplaceId-error{
    display: block;
}

.workplace-display {
    font-size: 14px;
    position: relative;
    padding-left: 25px;
    padding-top: 5px;

    &.disabled {
        opacity: .5;
    }
}

.workplace-display__icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: auto;

    svg{
        width: 100%;
        height: auto;
        stroke-width: 1px;
        stroke: $color-gu-fade-25;

        @include themer($theme-munksgaard) {
            stroke: $color-mg-blue-dusty;
        }
    }
}

.workplace-display__address {
    opacity: .5;
}

.profile-work__newsletterBox {
    .checkbox__indicator {
        border: 1px solid #878787;
    }

    .checkbox__text {
        font-weight: 600;
    }
}