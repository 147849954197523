.discount-code {
    position: relative;
    display: flex;
    justify-content: space-between;

    .delete-icon {
        @include reset__button("true");
        display: inline-block;
        cursor: pointer;
        width: 50px;
        vertical-align: super;

        svg {
            stroke: $color-gu-dark-grey;
            stroke-width: 1px;
            position: absolute;
            transform: translateY(-50%);
        }
        .loader {
            position: absolute;
            transform: translate(50%, -50%);
        }
    }
}

.discount-code__value {
    float: right;
}

.discount-code__link {
    float: right;
    @include mix-theme-like-a();
}

// ------- MODAL -------
.discount-code-modal {
    background-color: $white;
}

.discount-code__form {
    margin-bottom: $gutter !important;

    @include mix-content-min-width($breakpoint-w-cat - 200) {
        display: flex;
    }
}

.discount-code__submit-btn {
    @include mix-content-max-width($breakpoint-w-cat - 200) {
        width: 100%;
        margin-top: $gutter;
    }
    @include mix-content-min-width($breakpoint-w-cat - 200) {
        margin-left: $gutter;
        align-self: center;
        flex: auto 0 0;
    }
}
