.searchpage-header {
}

.searchpage-header__content {
    max-width: 700px;
    margin: 0 auto;
    padding: $gutter $gutter $gutter * 2;
    text-align: center;

    @media (min-width: $breakpoint-w-rhino) {
        padding: $gutter $gutter $gutter * 3;
    }
    .search-box {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }
}
