.block-center,
.block-full,
.block-full-push,
.block-left-narrow,
.block-left,
.block-left-push,
.block-right-narrow,
.block-right,
.block-right-push {
    display: block;
    transition: all .5s;
    margin-bottom:0.5em;
    @include mix-content-min-width($breakpoint-w-rhino){
        margin-bottom:1em;
    }
    img {
        width: 100% !important;
        display: inline-block;
    }
}

.block-center {
    width: calc(75% - 2rem);
    margin-left: auto;
    margin-right: auto;
}
.block-full {
    width: 100% !important;
    margin-top: .3em;
}
.block-full-push {
    margin-top: 1em;
    margin-bottom: 1em;
    height: auto !important;
    width: 100% !important;

    @include mix-content-min-width($breakpoint-w-rhino - 200px){
        width: 130% !important;
        margin-left: -15% !important;
    }

    @include mix-content-min-width($breakpoint-w-rhino - (2*$gutter) ){
        width: 150% !important;
        margin-left: -25% !important;
    }
}

.block-left-narrow {
    width: 66% !important;
    margin-left: auto;
    margin-right: auto;

    @include mix-content-min-width($breakpoint-w-rabbit) {
        float: left;
        width: 33% !important;
        height: auto !important;
        margin-left: 0;
        margin-right: 1em;
    }
    @include mix-content-min-width($breakpoint-w-rhino){
        margin-right: 2em;
    }
}
.block-left {
    @include mix-content-min-width($breakpoint-w-rabbit) {
        float: left;
        width: 50% !important;
        margin-right: 1em;
        height: auto !important;
    }
    @include mix-content-min-width($breakpoint-w-rhino){
        margin-right: 2em;
    }
}
.block-left-push {
    @include mix-content-min-width($breakpoint-w-rabbit) {
        float: left;
        width: 50% !important;
        margin-right: 1em;
        height: auto !important;
    }

    @include mix-content-min-width($breakpoint-w-rhino){
        width: 75% !important;
        margin-left: -25%;
        margin-right: 2em;
    }
}

.block-right-narrow {
    width: 66% !important;
    margin-left: auto;
    margin-right: auto;

    @include mix-content-min-width($breakpoint-w-rabbit) {
        float: right;
        width: 33% !important;
        height: auto !important;
        margin-right: 0;
        margin-left: 1em;
    }
    @include mix-content-min-width($breakpoint-w-rhino){
        margin-left: 2em;
    }
}
.block-right {
    @include mix-content-min-width($breakpoint-w-rabbit) {
        float: right;
        width: 50% !important;
        height: auto !important;
        margin-left: 1em;
    }

    @include mix-content-min-width($breakpoint-w-rhino){
        margin-left: 2em;
    }
}
.block-right-push {
    @include mix-content-min-width($breakpoint-w-rabbit) {
        float: right;
        width: 50% !important;
        height: auto !important;
        margin-left: 1em;
    }
    @include mix-content-min-width($breakpoint-w-rhino){
        width: 75% !important;
        margin-right: -25% !important;
        margin-left: 2em;
    }
}



//addons
.block-right-push-addon {
    @include mix-content-max-width($breakpoint-w-rhino + 200) {
        width: 32% !important;
        height: auto !important;
        float: right;
        & + img{
            width: 65% !important;
            float: left;
        }
    }

    @include mix-content-min-width($breakpoint-w-rhino + 200) {
        position: absolute;
        left: 100%;
        width: 50% !important;
        height: auto !important;
        margin-left: $gutter;
    }
}

.image-byline {
    color:$color-gu-fade-23;
    font-size: .9rem;
}