
.error-404{
    margin-bottom: $gutter*2;
    .button,
    .search-box{
        width: 400px;
        max-width: 100%;
        margin:auto;
    }
    p+p{
        margin-top: 1em;
    }
}

.error-404__image{
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding-bottom: 28%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 80% auto;
    @include themer($theme-gyldendal){
        background-image: url("/dist/assets/gfx/404_gu.png");
    }
    @include themer($theme-hansreitzel){
        background-image: url("/dist/assets/gfx/404_hr.png");
    }
    @include themer($theme-munksgaard){
        background-image: url("/dist/assets/gfx/404_mg.png");
    }
    @media (min-width: $breakpoint-w-lion) {
    padding-bottom: 230px;
    }
}
