@use "sass:math";

#main-footer {
    position: relative;
    width: 100%;
    background-color: $footer-main-background-color;
    color: $footer-main-text-color;
    padding-top: 80px;

    img {
        max-width: 90%;
        max-height: 60px;
    }
}

.main-footer__inner {
    max-width: $max-width;
    margin: auto;
    overflow: auto;
    padding: 0;
    position: relative;

    @include mix-content-min-width(500px) {
        display: flex;
        flex-wrap: wrap;
    }
    @include mix-content-min-width($breakpoint-w-dog) {
        padding: 0 5%;
    }
    border-bottom: 1px solid rgba(black, 0.1);
}

.main-footer__top {
    display: none;
}

.footer-top-logo {
    display: none;
}

.footer-top-shape {
    display: none;
}

.footer-top-shape__inner {
    position: absolute;
    height: 40px;
    width: 100%;
    min-width: 1300px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    svg {
        position: absolute;
        bottom: -4px;
        height: 100%;
        width: 100%;
        fill: $color-gu-dark-grey;
    }
}
