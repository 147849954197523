.subscribe-to-newsletter {
    .subscribe-to-newsletter__checkbox {
        margin-top: 1.25rem;
    }

    .subscribe-to-newsletter__header {
        @include themer($theme-gyldendal) {
            @include mix-theme-like-h5;
            font-weight: $fontweight-gu-bold;
            text-transform: uppercase;
        }
        @include themer($theme-hansreitzel) {
            @include mix-theme-like-h4;
        }
        @include themer($theme-munksgaard) {
            @include mix-theme-like-h4;
        }
    }
}
