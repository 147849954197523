@use "sass:math";

.password-changer {
    margin-top: 1em;
}
.password-changer__submit {
    margin-top: math.div($gutter, 2);
    button {
        min-width: 120px;
        @include mix-button--primary();
        @include mix-button-submit();
        @include mix-button-is-slim();
    }
}

.password-changer__form {
    overflow: hidden;
    /*max-height: 0;
    animation: maxHeightRowAnim 1s ;
    animation-fill-mode: forwards;*/
}
