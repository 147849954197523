@use "sass:math";

.search-box {
    --pad: 10px;

    position: relative;
    width: Min(calc(100% - (var(--pad) * 2)), 400px);
    margin-inline: auto;
    @include mix-clearfix-block();

    @media (min-width: 640px) {
        --pad: 25px;

    }
}

.search-box__input {
    height: math.div(50, 16) * 1em;
    border: 1px solid $color-gu-fade-20;
    background-color: #fff;
    border-radius: 4px;
    padding: 0 math.div(60, 16) * 1em 0 1em;
    font-size: 16px;
    line-height: 50px;
    width: 100%;
    font-family: inherit;

    @include themer($theme-gyldendal) {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
    }
}

.search-box__submit {
    @include mix-button--secondary();

    display: flex;
    justify-content: center;
    align-items: center;
    width: math.div(50, 16) * 1em;
    height: math.div(50, 16) * 1em;
    position: absolute;
    top: 0;
    right: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    overflow: hidden;
    svg {
        stroke: #fff;
        position: absolute;
    }
}
