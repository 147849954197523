.navigation-menu-container {
    background-color: white;
    position: absolute;
    margin: 0 -1rem;
    top: $navigation-header-height;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 80px 1fr;
    transform: translateY(-100%);
    transition: transform 500ms ease;
    width: 100%;
    max-width: 1170px;
    z-index: -1;
    @include mix-content-max-width($breakpoint-navigation) {
        grid-template-rows: 60px 1fr;
        z-index: auto;
        transform: translateX(-100%);
        position: fixed;
        inset: $navigation-header-height 0 0;
        padding-top: 3rem;
        margin: 0;
        max-width: 400px;
    }
    &.nav-menu-exit {
        @include mix-content-min-width($breakpoint-navigation) {
            transition-delay: 250ms;
        }
    }
    &.nav-menu-exit-done,
    &.nav-menu-exit-active {
        transform: translateY(-100%);
        @include mix-content-max-width($breakpoint-navigation) {
            transform: translateX(-100%);
        }
        @include mix-content-min-width($breakpoint-navigation) {
            transition-delay: 250ms;
        }
    }
    &.nav-menu-enter-done,
    &.nav-menu-enter-active {
        transform: translateY(0);
        @include mix-content-max-width($breakpoint-navigation) {
            transform: translateX(0);
        }
    }
}

.navigation-menu {
    grid-row: 2 / 3;
    background-color: $white;
    z-index: 20;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    width: 33vw;
    max-width: 400px;
    position: relative;
    @include mix-theme-headline-color;
    &::before {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        width: 1px;
        background-color: black;
        opacity: 0;
        transition: opacity 500ms ease;
    }
    &--submenuShown::before {
        opacity: 0.05;
    }
    @include mix-content-min-width($breakpoint-navigation) {
        padding: 2.5rem 30px 4.5rem 6rem;
        &::before {
            inset: 2.5rem 0 3rem auto;
        }
    }
    @include mix-content-max-width($breakpoint-navigation) {
        inset: 0;
        padding: 0 2rem 2rem;
        width: 100%;
        background-color: $white;
    }
}

.navigation-menu,
.navigation-submenu {
    grid-column: 1 / 2;
    // grid-row: 2 / 3;
    color: $black;
    max-height: calc(100vh - $navigation-header-height);
    overflow-y: scroll;
    @include hide-scrollbars;
}

.navigation-menu__inner {
    display: flex;
}

.navigation-menu__title {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    padding: 2.5rem 0 0 6rem;
    font-size: 2rem;
    // margin-bottom: 2rem;
    @include themer($theme-hansreitzel) {
        font-family: $header-font-family;
    }
    @include mix-content-max-width($breakpoint-navigation) {
        padding: 0 0 0 2rem;
        font-size: 1.8rem;
        margin-bottom: 1rem;
        @include themer($theme-gyldendal) {
            font-weight: $fontweight-gu-medium;
        }
        @include themer($theme-hansreitzel) {
            font-weight: $fontweight-hr-medium;
        }
        @include themer($theme-munksgaard) {
            font-weight: $fontweight-mg-regular;
        }
    }
    @include mix-content-max-width($breakpoint-w-rabbit) {
        font-size: 1.5rem;
    }
}

.navigation-menu__items {
    list-style-type: none;
    padding-right: 4rem;
    @include mix-content-max-width($breakpoint-navigation) {
        padding-right: 1rem;
    }
}

.navigation-menu-item__button {
    @include button-reset;
    color: inherit;
    font-size: inherit;
    display: inline-flex;
    align-items: center;
}

.navigation-menu-item {
    margin-bottom: 1.25rem;
    @include mix-content-min-width($breakpoint-navigation) {
        margin-bottom: 0.75rem;
    }

    svg {
        margin-left: 0.5em;
        margin-bottom: -1px;
        fill: none;
        stroke: currentColor;
    }

    path {
        stroke-width: 1px;
    }

    &:hover path {
        stroke-width: 2px;
    }

    &--isActive,
    &:hover {
        path {
            stroke-width: 2px;
        }
        @include themer($theme-gyldendal) {
            font-weight: $fontweight-gu-medium;
        }
        @include themer($theme-hansreitzel) {
            font-weight: $fontweight-hr-semibold;
        }
        @include themer($theme-munksgaard) {
            font-weight: $fontweight-mg-regular;
        }
    }
}

.navigation-menu-item__link {
    color: inherit;
    font-size: inherit;
}

.navigation-menu-item__link,
.navigation-menu-item__button {
    font-weight: inherit;
    display: flex;
    text-align: left;
    line-height: 1.5;
    @include themer($theme-gyldendal) {
        text-transform: uppercase;
    }
    &:hover {
        outline: none;
        @include themer($theme-gyldendal) {
            font-weight: $fontweight-gu-medium;
        }
        @include themer($theme-hansreitzel) {
            font-weight: $fontweight-hr-semibold;
        }
        @include themer($theme-munksgaard) {
            font-weight: $fontweight-mg-regular;
        }
    }
}

.navigation-menu-item__label {
    width: 230px;
    @include mix-content-max-width($breakpoint-navigation) {
        width: calc(min(100vw, 400px) - 3rem);
    }
}

.navigation-overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    inset: 0;
}

.navigation-submenu-container {
    position: absolute;
    top: 0;
}

.navigation-submenu {
    grid-row: 1/3;
    z-index: 19;
    padding: 7.25rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 390px;
    background-color: white;
    transition: transform 500ms ease;
    @include mix-content-min-width($breakpoint-navigation) {
        background-color: transparent;
        &--isActive {
            transform: translateX(100%);
        }
        &.navigation-submenu--1.navigation-submenu--isActive {
            transform: translateX(200%);
            z-index: 1;
        }
    }
    @include mix-content-max-width($breakpoint-navigation) {
        position: fixed;
        inset: 0;
        padding: 0 2rem;
        transition: transform 500ms ease;
        transform: translateX(-100%);
        max-width: none;
        z-index: 1;
        &--isActive {
            transform: translateX(0);
            z-index: 20;
        }
    }
}

.navigation-submenu__title {
    display: inline-block;
    font-size: 2rem;
    margin-bottom: 1rem;
    @include themer($theme-gyldendal) {
        font-weight: $fontweight-gu-medium;
    }
    @include themer($theme-hansreitzel) {
        font-weight: $fontweight-hr-medium;
    }
    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-regular;
    }
    @include mix-content-min-width($breakpoint-navigation) {
        display: none;
    }
}

.navigation-submenu__items {
    list-style-type: none;
    padding-left: 4rem;
    @include mix-content-max-width($breakpoint-navigation) {
        padding-left: 0;
    }
    &--desktop {
        display: none;
        @include mix-content-min-width($breakpoint-navigation) {
            display: block;
        }
    }
    &--mobile {
        overflow-y: auto;
        @include mix-content-min-width($breakpoint-navigation) {
            display: none;
        }
    }
}
