.teaser-tile {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem 0;

    &__interactive {
        display: block;
    }

    &.teaser-tile--focused,
    &:focus-within {
        .teaser-tile__image-container,
        .teaser-tile__content-container {
            outline: 1px solid;
            @include themer($theme-gyldendal) {
                outline-color: $color-gu-orange;
            }
            @include themer($theme-hansreitzel) {
                outline-color: $color-hr-red;
            }
            @include themer($theme-munksgaard) {
                outline-color: $color-mg-green-dark;
            }
        }
    }
}

.teaser-tile__video-wrapper {
    position: relative;
    cursor: pointer;

    .teaser-tile__image-container {
        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            width: 3.5em;
            height: 3.5em;
            z-index: 1;
            background: $color-gu-orange;
            pointer-events: none;

            @include themer($theme-gyldendal) {
                border-top-left-radius: 5px;
            }

            @include themer($theme-hansreitzel) {
                background: $color-hr-red;
                border-top-left-radius: 2px;
            }
            @include themer($theme-munksgaard) {
                background: $color-mg-green-dark;
                border-top-left-radius: 2px;
            }
        }
        // play icon
        &:after {
            content: "";
            position: absolute;
            bottom: 0.8em;
            right: 0.8em;
            border-top: 0.9em solid transparent;
            border-bottom: 0.9em solid transparent;
            border-left: 1.5em solid #fff;
            z-index: 2;
            pointer-events: none;

            @include themer($theme-munksgaard) {
                border-left: 1.5em solid $color-mg-blue;
            }
        }
    }
}

.teaser-tile__image-wrapper,
.teaser-tile__content-wrapper {
    width: 100%;

    @include mix-content-min-width($breakpoint-w-cat) {
        padding: 0 1rem;
    }
}
.teaser-tile__content-wrapper {
    flex-grow: 1;
}

// Image container
.teaser-tile__image-container {
    width: 100%;
    padding-bottom: calc(100% * (3 / 4));
    position: relative;
    overflow: hidden;

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @include object-fit(cover, center);
    }
}

// Content container
.teaser-tile__content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    background-color: white;
    border: 1px solid $color-gu-fade-20;
    padding: 0.75rem;
    @include mix-content-max-width($breakpoint-w-rabbit) {
        padding: 1rem;
    }
    @include mix-content-max-width($breakpoint-w-cat) {
        padding: 0.75rem;
    }
    @include mix-content-max-width($breakpoint-w-horse) {
        padding: 1rem;
    }
    @include mix-content-max-width($breakpoint-w-rhino) {
        padding: 1.25rem;
    }

    .teaser-tile--tile-type-col-1 &,
    .teaser-tile--tile-type-col-2 & {
        border-top: none;
    }
    .teaser-tile--tile-type-col-4 & {
        @include mix-content-max-width($breakpoint-w-lion) {
            border-top: none;
        }
        @include mix-content-min-width($breakpoint-w-lion) {
            border-left: none;
        }
    }

    @include mix-content-min-width($breakpoint-w-rhino) {
        padding: 2rem;
    }
}
.teaser-tile__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    flex-grow: 1;
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 30px;
        pointer-events: none;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
    }

    &--expand-disabled {
        &:after {
            display: none;
        }
        .teaser-tile__rich-text-button {
            cursor: default;
            color: $black;
        }
    }

    &--expanded {
        .teaser-tile__rich-text {
            max-height: none;
        }

        &:after {
            display: none;
        }
    }
}

.teaser-tile__tag {
    margin-bottom: 0.75rem;
}

.teaser-tile__title-link {
    text-decoration: none;
}

.teaser-tile__title {
    @include mix-theme-like-h2;
    @include mix-content-min-width($breakpoint-w-cat) {
        font-size: rem(20);
        margin-bottom: 0.75rem;
    }
    @include mix-content-min-width($breakpoint-w-rhino) {
        .teaser-tile--tile-type-col-4 & {
            font-size: rem(30);
        }
    }
}

.teaser-tile__subtitle {
    color: $color-gu-orange;
    @include mix-theme-subline-text;
    @include mix-subtheme-primary-color;
    margin-top: 0;
    margin-bottom: 0.25rem;
}

.teaser-tile__rich-text {
    display: block;
    margin-bottom: auto;
    max-height: 120px;
    overflow: hidden;
    > *:first-child {
        margin-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
    }

    &-button {
        @include reset__button("true");
        text-align: left;
    }
}

.teaser-tile__button,
.teaser-tile__arrow-link {
    margin-top: 1rem;
}
