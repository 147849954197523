.navigation-profile {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    @include mix-content-max-width($breakpoint-navigation) {
        display: none;
    }
}

.navigation-profile__button {
    color: inherit;
    width: 32px;
    height: 32px;
}

.navigation-profile-list {
    position: absolute;
    background-color: $white;
    @include mix-theme-headline-color;
    right: 0;
    bottom: 0;
    min-width: 320px;
    z-index: -1;
    transition: transform 300ms ease-in-out, visibility 300ms ease-in-out;
    visibility: hidden;
    &--isOpen {
        visibility: visible;
        transform: translateY(100%);
    }
    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        padding: 0;
    }

    button {
        @include button-reset;
        cursor: pointer;
        text-align: left;
        svg {
            display: block;
        }
    }

    a,
    button {
        color: inherit;
        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }
        width: 100%;
        display: block;
        padding: 0.75rem 1rem;
    }
}
