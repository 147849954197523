.u-text-align-center {
    text-align: center !important;
}

.u-text-align-left {
    text-align: left !important;
}

.u-text-align-right {
    text-align: right !important;
}

.u-text-inline-block {
    display: inline-block;
}

.u-text-space {
    &-left {
        margin-left: .5em;
    }
    &-right {
        margin-right: .5em;
    }
}

.u-text-word-break-word {
    word-wrap:break-word;
    -ms-word-wrap:break-word;
    overflow-wrap:break-word;
}

.u-text-green {
    color: $color-gu-green;
}

.u-text-red {
    color: $color-gu-red;
}