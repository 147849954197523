.image-slider-container {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    &.light {
        color: #fff;

        &.custom-slick-slider {
            button {
                &::before {
                    border: 1px solid #fff !important;
                }
            }

            .slick-active {
                button {
                    &::before {
                        background: #fff !important;
                    }
                }
            }
        }
    }
}