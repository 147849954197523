@use "sass:math";

.amount-input {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    position: relative;
    z-index: 1;
    font-size: 16px;
    border-radius: 5px;
    padding: 0px 10px;
    border: 1px solid $dark-grey-op10;
    background-color: white;

    &--disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    label {
        display: inline-block;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}

// Overwriting the element for full width
.amount-input--fullWidth {
    width: 100%;

    label {
        width: 100%;
    }
}

.amount-input__button {
    width: 25px;
    height: 38px;
    background-color: transparent;
    border: 0;
    font-size: 16px;
    color: $black;
    cursor: pointer;
    position: absolute;
    top: 0;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-of-type {
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        left: 0;
    }
    &:last-of-type {
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        right: 0;
    }
    svg {
        position: absolute;
    }
}

.amount-input__input {
    height: 40px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 25px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 0;
    opacity: 0;
    text-align: center;
    outline: none;
    border: none;
    z-index: 2;
    background-color: transparent;
    &:focus {
        opacity: 1;
    }
}

.amount-input__input {
    height: 38px;
    border-radius: 5px;
    font-family: inherit;
    color: inherit;
    -moz-appearance: textfield;
}

.amount-input__input,
.amount-input__label-text {
    max-width: 100%;
    min-width: 70px;
    width: 100%;
    font-size: 0.875rem;
}

.amount-input__label-text {
    cursor: auto;
    padding: 0 math.div($gutter, 2);
    display: inline-block;
    position: relative;
    text-align: center;
    top: -1px;

    .amount-input__input:focus ~ & {
        opacity: 0;
        z-index: 1;
    }

    span {
        display: inline-block;
        height: 38px;
        vertical-align: top;
    }
}

.amount-input__label-text__units {
    display: inline-block;
    padding: 0;
    max-width: 110px;
    margin-left: 0.5em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.amount-input__loader {
    position: absolute;
    top: 100%;
    left: 50%;
    height: 10px;
    line-height: 10px;
    z-index: 10;
    transform: translateX(-50%);
}
