.create-profile-modal {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    grid-gap: 2.5rem;
    margin-inline: auto !important;

    .modal-container__content {
        border: 1px solid black;
    }

    @include mix-content-max-width($breakpoint-w-rabbit) {
        padding: 3rem;
    }

    svg {
        height: 4.5rem;
        width: 4.5rem;
        color: black;
    }
}

.create-profile-modal__header {
    color: black;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: $fontweight-gu-bold;
    text-transform: uppercase;
    text-align: center;
}

.create-profile-modal__options {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;

    @include mix-content-max-width($breakpoint-w-cat) {
        flex-direction: column;
    }

    .option {
        flex-shrink: 0;
        text-align: center;
        flex-basis: 50%;

        @include mix-content-max-width($breakpoint-w-cat) {
            margin-bottom: 2rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        div {
            color: black;
            margin-bottom: 1rem;
        }
        a {
            @include mix-button($color-gu-dark-grey, #fff);
            @include mix-button-is-slim;
            padding: 0.625em 3.18em !important;
            width: 100%;
            &.green {
                @include mix-button($color-gu-green, #fff);
            }
        }
    }
}
