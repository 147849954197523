.searchpage-results {
    background-color: $lightgrey;
    border-top: 1px solid $color-gu-fade-20;
    border-bottom: 1px solid $color-gu-fade-20;
    margin-bottom: $gutter*2;

    @media (min-width: $breakpoint-w-cat) {
        margin-bottom: $gutter*3;
    }
}

.searchpage-results__content {
    max-width: 720px;

    @include mix-content-max-width($breakpoint-w-cat) {
        margin: 0 $gutter-small;
    }
    @include mix-content-min-width($breakpoint-w-cat) {
        margin: $gutter auto;
    }
    @include mix-content-min-width($breakpoint-w-lion) {
        margin: $gutter*2 auto;
    }
}

.searchpage-results__content-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.searchpage-results__content-list__item {
    padding: $gutter*1.5 0;

    & + .searchpage-results__content-list__item {
        border-top: 1px solid $color-gu-fade-20;
    }
    a {
        font-weight: $fontweight-gu-medium;
        @include mix-theme-like-a();
    }
}

.searchpage-results__empty-text {
    margin: $gutter*2 0;
    text-align: center;
}
