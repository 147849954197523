@use "sass:math";

.feed-post {
    border: 1px solid $color-gu-fade-20;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    overflow: visible;
    position: relative;
}

.feed-post__content {
    flex-grow: 1;
}
//------------------------------------------------------
//image
//------------------------------------------------------

.feed-post__image {
    height: 200px;
    position: relative;

    overflow: hidden;

    img {
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.feed-post__image__expandlink {
    display: block;
    position: absolute;
    z-index: 2;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.1);
    width: 45px;
    height: 45px;
    svg {
        width: 24px;
        height: 24px;
        fill: transparent;
        stroke: #fff;
        stroke-width: 1px;
        position: absolute;
        top: 0;
        left: 0;
    }
}

//------------------------------------------------------
//Message
//------------------------------------------------------

.feed-post__message {
    position: relative;
    padding: $gutter;
    flex-grow: 2;
    max-height: 400px;
    overflow: hidden;
    text-decoration: none;
    @include mix-theme-paragraph-color;

    .feed-post--hasImage & {
        max-height: 200px;
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: $gutter;
        width: 100%;
        display: block;
        background: rgba(255, 255, 255, 0);
        background: -webkit-linear-gradient(to top, #fff 50%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(to top, #fff 50%, rgba(255, 255, 255, 0) 100%);
    }
}

.feed-post__message__link {
    overflow: hidden;
    word-wrap: break-word;
    padding: 0 $gutter $gutter * 1.1 $gutter;
    flex-grow: 1;
    max-width: 100%;
    word-break: break-all;
    position: relative;
    max-height: 100px;
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: $gutter;
        width: 100%;
        display: block;
        background: rgba(255, 255, 255, 0);
        background: -webkit-linear-gradient(to top, #fff 50%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(to top, #fff 50%, rgba(255, 255, 255, 0) 100%);
    }

    a {
        position: relative;
        display: inline-block;
        @include mix-theme-like-a();
    }
    svg {
        float: left;
        width: 20px;
        height: 20px;
        fill: transparent;
        stroke: $color-gu-dark-grey;
        stroke-width: 1px;
        position: absolute;
        top: 0;
        left: 0;
    }
    span {
        display: inline-block;
        padding-left: math.div($gutter, 2) + 20px;
    }
}

//------------------------------------------------------
//feed-post__user footer
//------------------------------------------------------

.feed-post__user {
    width: 100%;
    border-top: 1px solid $color-gu-fade-20;
    padding: $gutter;
    display: flex;
    max-height: 100px;
    flex-direction: row;
    flex-grow: 0;
}

.feed-post__user_linkwrap {
    display: block;
    widows: 100%;
}

.feed-post__user__image {
    width: 50px;
    height: 50px;
    margin-right: $gutter;
    float: left;
}

.feed-post__user__name {
    color: black;
    @include mix-theme-bold-bodyfont();
}

//------------------------------------------------------
// feed-post-popup
//------------------------------------------------------

.feed-post-popup {
    position: fixed;
    z-index: 8000;
    top: 0;
    left: 0;
    width: 100%;

    background-color: #fff;
    border: 1px solid $color-gu-fade-20;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: visible;

    @media (max-width: $breakpoint-w-cat) {
        height: 100%;
    }

    @media (min-width: $breakpoint-w-cat) {
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
        max-width: 500px;
        position: absolute;
    }

    .bodyHas-feed-post-popup {
        border: 4px solid red;
    }
}
