
//used on span
.arrow-icon {
    width: 0.625rem;
    height: 0.625rem;
    border-top: 1px solid $color-gu-dark-grey;
    border-left: 1px solid $color-gu-dark-grey;
    cursor: pointer;
    margin: auto;
    transition: transform .25s $easeOutQuint;
}
.arrow-icon-down {
    -webkit-transform: translateY(-0.1875rem) rotate(225deg);
    -ms-transform: translateY(-0.1875rem) rotate(225deg);
    transform: translateY(-0.1875rem) rotate(225deg);
}
.arrow-icon-up {
    -webkit-transform: translateY(0.1875rem) rotate(45deg);
    -ms-transform: translateY(0.1875rem) rotate(45deg);
    transform: translateY(0.1875rem) rotate(45deg);
}


