@use "sass:math";

.profile-work__profession {
    width: 100%;
    display: block;
    @media (min-width: $breakpoint-w-lion) {
        display: flex;
    }
    @media (max-width: $breakpoint-w-lion) {
        .content-arrow {
            left: 50%;
            bottom: math.div($gutter, 2) * -1;
            transform: translateX(-50%) rotate(45deg);
        }
    }
    @media (min-width: $breakpoint-w-lion) {
        .content-arrow {
            top: 50%;
            right: math.div($gutter, 2) * -1;
            transform: translateY(-50%) rotate(-45deg);
        }
    }
}

.profile-work__profession__choices {
    border-bottom: 1px solid $color-gu-fade-20;
    z-index: 5;

    @media (min-width: $breakpoint-w-lion) {
        border-bottom: none;
        border-right: 1px solid $color-gu-fade-20;
    }
}

.profile-work__profession__location {
    > * {
        transition: opacity 0.3s;
        opacity: 0;
        transition-delay: 0.3s;
    }
    .showLocation & {
        .content-block__inner {
            opacity: 1;
        }
    }
    .showLocation.showDescription & {
        .content-arrow {
            opacity: 1;
        }
    }
    @media (max-width: $breakpoint-w-lion) {
        max-height: 0;
        border-bottom: 1px solid transparent;
        .showLocation & {
            max-height: 800px;
            border-bottom: 1px solid $color-gu-fade-20;
        }
    }
    @media (min-width: $breakpoint-w-lion) {
        max-width: 0;
        border-right: 1px solid transparent;
        .showLocation & {
            max-width: 50%;
            border-right: 1px solid $color-gu-fade-20;
        }
    }
}

.profile-work__profession__description {
    .content-block__inner {
        opacity: 0;
        transition: opacity 0.6s;
        transition-delay: 0.6s;
        width: 100%; // For IE
        .showDescription & {
            opacity: 1;
        }
    }
    @media (max-width: $breakpoint-w-lion) {
        height: auto;
    }
    @media (min-width: $breakpoint-w-lion) {
        max-width: 0%;
        .showDescription & {
            max-width: 50%;
        }
    }
}

.profile-work__profession__choices,
.profile-work__profession__location,
.profile-work__profession__description,
.profile-work__profession__newsletter {
    position: relative;
    transition: max-width 0.2s, max-height 0.2s, width 0.2s, border 0.2s 0.2s;
    overflow: visible;
    @media (min-width: $breakpoint-w-lion) {
        flex-grow: 1;
    }
}

#jobdescription-error {
    text-indent: 0.6rem;
    background-color: $color-error;
    color: #fff;
    font-weight: 100;
}

.profileprofession-description__icon {
    width: 120px;
    height: 120px;
    margin: 0 auto 1em;
    svg {
        width: 120px;
        height: 120px;
        stroke: $color-gu-fade-25;
    }
}
