.custom-checkbox {
    max-width: 70ch;

    &--disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;
        height: 1.5rem;
        width: 1.5rem;
        margin: 0;
        border: 1px solid $input-border-color;
        border-radius: $border-radius;
        transition: 0.3s;
        transition-property: border-color;
        flex-shrink: 0;
        background-color: $white;

        &:hover {
            cursor: pointer;
            border-color: $color-gu-blue-dusty;
        }

        &:checked {
            position: relative;

            border-color: $color-gu-blue-dusty;

            &::before {
                background-color: currentColor;
                position: absolute;
                left: 0;
                top: 0.5625rem;
                height: 0.375rem;
                width: 0.125rem;
                content: "";
                transform: translateX(0.625rem) rotate(-45deg);
                transform-origin: left bottom;
                border-radius: 0.125rem 0.125rem 0px 0px;
            }

            &::after {
                background-color: currentColor;
                position: absolute;
                left: 0;
                top: 0.8125rem;
                height: 0.125rem;
                width: 0.625rem;
                content: "";
                transform: translateX(0.625rem) rotate(-45deg);
                transform-origin: left bottom;
                border-radius: 0px 0.125rem 0.125rem 0px;
            }
        }

        .custom-checkbox--error & {
            border-color: $color-error;
        }
    }

    label {
        display: flex;
        gap: 1rem;
    }

    span {
        font-size: 0.75rem;
        line-height: 1.65;
        cursor: pointer;

        a {
            color: inherit;
            text-decoration: underline;
        }
    }

    &__error-message {
        color: $color-error;
    }
}
