.tabs__menu {
    text-align: center;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    position: relative;
    bottom: -2px;
}
.tabs__menu-inner {
    width: auto;
    white-space: nowrap;
    padding-bottom: 0.4rem;
}

.tabs__menu__tab {
    display: inline-block;
    margin-right: $gutter;
    cursor: pointer;
    text-transform: uppercase;
    transition: color 0.3s $easeInOutCubic, border 0.3s $easeInOutCubic;
    color: $dark-grey;

    background: none;
    font-family: inherit;
    border: none;
    padding: 0 0 0.5rem;

    //GU
    @include themer($theme-gyldendal) {
        font-weight: $fontweight-gu-book;
    }
    //munk
    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-regular;
    }
    //hans
    @include themer($theme-hansreitzel) {
        font-weight: $fontweight-hr-regular;
    }

    &.active {
        //border-bottom: 2px solid;
        @include mix-theme-active-tab;

        @include themer($theme-gyldendal) {
            font-weight: $fontweight-gu-medium;
        }
    }

    > span {
        margin-left: 0.5em;
    }
    &:not(.active) > span {
        color: lighten($color-gu-fade-23, 5);
    }

    &.disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }
}

.tabs__menu__tab:last-child {
    margin: 0px;
}

.tabs__content-inner {
    background: white;
    @include mix-clearfix-table;
}

.tabs__content-inner[role="tabpanel"]:focus-visible {
    outline: solid;
}
