.teacher-sample-basket {
    padding: 0;
}

.teacher-sample__newsletter-permission {
    padding-left: 2em;
    position: relative;

    .checkbox {
        position: absolute;
        left: 0;
    }
}