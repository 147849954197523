.image-slide-container {
    width: 100%;
    height: 375px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .image-slide-image {
        z-index: -1;
        position: absolute;
        inset: 0;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .image-slide-input {
        text-align: center;

        &.image-slide-title {
            @include mix-theme-like-h2();
        }

        &.image-slide-teaser {
            margin-top: 0.2rem;
            font-weight: 200;
        }

    }

    .image-slide-cta {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 2rem;

        a {
            @include themer($theme-gyldendal) {
                @include mix-button();
            }
            @include themer($theme-hansreitzel) {
                @include mix-button($color-hr-red, #fff);
            }
            @include themer($theme-munksgaard) {
                @include mix-button($color-mg-green-dark, $color-mg-blue);
            }
            @include mix-button-is-slim();
        }
    }
}