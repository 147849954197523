/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);

  https://github.com/bfred-it/object-fit-images/blob/master/preprocessors/mixin.scss
*/
@mixin object-fit($fit: fill, $position: null) {
    object-fit: $fit;
    @if $position {
        object-position: $position;
    }
}
