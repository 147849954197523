@keyframes anim-modal-in {
    from {
        transform: translateY(-10%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes anim-modal-out {
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(-10%);
        opacity: 0;
    }
}

@keyframes overlay-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes overlay-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

div[role="presentation"] {
    height: 100vh;
    width: 100%;
}

.modal-overlay {
    position: fixed;
    z-index: 9000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    &.isOpen {
        animation: overlay-in 250ms forwards;
    }
    .isClosing & {
        animation: overlay-out 250ms forwards;
    }
}

.modal-container-wrap {
    animation: anim-modal-in 0.2s forwards;
    display: flex;
    padding: 2rem;
    align-items: center;
    justify-self: center;
    position: relative;
    z-index: 9100;
    height: 100%;
    width: 100%;

    .isClosing & {
        animation: anim-modal-out 0.2s forwards;
    }

    @include mix-content-max-width($breakpoint-w-cat) {
        padding: 1rem;
    }
}

.modal-container {
    position: relative;
    max-height: calc(100% - 50px);
    max-width: 1014px;
    overflow: auto;
    margin: auto;
    background-color: $white;
    border-radius: $border-radius;

    @include mix-content-max-width($breakpoint-w-cat) {
        padding: 2rem;
    }

    @include mix-content-max-width($breakpoint-w-rabbit) {
        padding: 3rem 1rem 1rem;
    }
}

.modal-container__content {
    position: relative;
    padding: 3rem;
    max-height: calc(100vh - 50px);
    max-width: 100%;
    margin: auto;
    word-break: break-word;

    .modal-size--big & {
        width: 840px;
    }

    .modal-size--normal & {
        width: 550px;
    }

    .modal-size--auto & {
        width: auto;
    }

    .modal--no-padding & {
        padding: 0;
    }

    > * {
        margin: 0;
    }
}

.modal-close-button {
    z-index: 9300;
    margin-top: 0 !important;
    &:before,
    &:after {
        @include mix-theme-primary-color-props("background-color");
    }
    top: 10px;
    right: 10px;
}

.modal-block {
    border: 1px solid $color-gu-fade-20;
    @include themer($theme-gyldendal) {
        border-radius: 5px;
    }
}
