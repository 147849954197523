@use "sass:math";

.article-factbox {
    background-color: $lightgrey;
    border: 1px solid $color-gu-fade-20;
    padding: $gutter;
    margin-top: 1em;
    margin-bottom: 1em;

    @include themer($theme-gyldendal) {
        border-radius: 5px;
    }
    > *:first-child {
        margin-top: 0;
    }
    > *:last-child {
        margin-bottom: 0 !important;
    }
    &__title {
        @include mix-theme-like-header();
        @include mix-theme-like-h2();
        font-size: 18px;
        margin-bottom: math.div($gutter, 2);
    }
    &.right {
        @media (min-width: $breakpoint-w-cat) {
            float: right;
            width: 50%;
            margin-left: 1em;
        }
        @include mix-content-min-width($breakpoint-w-rhino) {
            margin-left: 2em;
            &.push {
                margin-right: -25%;
            }
        }
    }
    &.left {
        @media (min-width: $breakpoint-w-cat) {
            float: left;
            width: 50%;
            margin-right: 1em;
        }
        @include mix-content-min-width($breakpoint-w-rhino) {
            margin-right: 2em;
            &.push {
                margin-left: -25%;
            }
        }
    }
    &.push {
        @include mix-content-min-width($breakpoint-w-rhino) {
            width: 75%;
        }
    }
    &.center {
        width: calc(75% - 2rem);
        display: block;
        margin-left: auto;
        margin-right: auto;
        overflow: auto;
    }
}
