.facet-overlay {
    position: fixed;
    top: $navigation-header-height;
    left: 0;
    width: 100%;
    height: calc(100% - #{$navigation-header-height});
    z-index: 3000;
    overflow: hidden;
    transition: transform 500ms ease-out;
    transform: translateY(0vh);
}

.facet-overlay__scroll {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
}

.facet-overlay__content {
    width: 100%;

    margin: auto;
    padding: $gutter $gutter $gutter + 100px;
    height: auto;
}

.facet-overlay__options {
    position: fixed;
    width: 100%;
    height: auto;
    left: 0;
    bottom: 0px;
    z-index: 1;
    transition: transform 500ms ease-out;
    transform: translateY(50px);
    &--active {
        transform: translateY(0);
    }
}

.facet-overlay__option--show {
    @include mix-button--primary;
}

.facet-overlay__option--reset {
    @include mix-button--secondary;
}

.facet-overlay__option {
    @include mix-button-is-fluid;
    border-radius: 0 !important;
}

.facet-overlay-enter {
    transform: translateY(-100vh);
}
.facet-overlay-enter-active {
    transform: translateY(0vh);
}
.facet-overlay-exit {
    transform: translateY(0vh);
}
.facet-overlay-exit-active {
    transform: translateY(-100vh);
}
.facet-overlay-appear {
    transform: translateY(-100vh);
}
.facet-overlay-appear-active {
    transform: translateY(0vh);
}
