.products-sort {
    &.products-sort--inline {
        display: inline-flex;
        align-items: center;
        @include mix-content-max-width($breakpoint-w-lion) {
            display: none;
        }
    }

    &.products-sort--overlay {
        display: block;
        margin-bottom: 40px;
    }
}

.products-sort__headline,
.products-sort__options {
    display: block;

    .products-sort--inline & {
        display: inline-block;
    }

    .products-sort--overlay & {
        overflow-x: scroll;
    }
}

.products-sort__headline {
    margin: 0 6px 0 0;
    @include mix-tags-label;

    .products-sort--overlay & {
        margin-bottom: 18px;
    }
}

.products-sort__options-wrapper {
    display: inline-block;

    .products-sort--overlay & {
        width: calc(100% + #{$gutter * 2});
        margin-left: -$gutter;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
    }
}

.products-sort__options {
    .products-sort--overlay & {
        padding: 0 $gutter 0 $gutter;
        display: flex;
        float: left;
    }
    .products-sort--inline & {
        display: inline-flex;
        list-style-type: none;
    }
}

.products-sort__option {
    @include mix-tags-hollow--secondary;
    margin: 0 3px 0;
    white-space: normal;
    font-family: $body-font-family;
    cursor: pointer;

    .products-sort--overlay & {
        padding: 22px;
        max-width: 122px;
        text-align: center;
        display: flex;
        align-items: center;
    }
}

.products-sort__option--active {
    @include mix-tags--primary;
    white-space: inherit;
}
