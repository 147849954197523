.event-info-list-item {
    display: grid;
    grid-template-columns: auto 1fr;
    color: $color-gu-dark-grey;
    margin-bottom: 1.75rem;

    &:last-of-type {
        margin-bottom: 0rem;
    }
    
    .icon {
        width: 24px;
        height: 24px;
    }

    .content {
        margin-left: 0.75rem;
        display: flex;
        flex-direction: column;

        .info-title {
            font-weight: $fontweight-gu-black;
            font-size: .75rem;
            line-height: 1rem;
            text-transform: uppercase;
            padding-bottom: 0.375rem;
        }

        .info-text {
            font-size: .875rem;
            line-height: 1.25rem;
        }
    }
}