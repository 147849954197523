.header__content__utils {
    width: 100%;
    height: 50px;
    z-index: 60;
    position: relative;
    display: flex;
    box-shadow: 0 4px 0 rgba(0, 0, 0, .1);

    @media (max-width: $breakpoint-header) {
        justify-content: space-between;
        padding-left: 50px;

        .hide & {
            box-shadow: none;
        }
    }
    @media (max-width: $breakpoint-navigation) {
        transition: transform .1s;
        transform: translateY(100%);

        .hide &,
        .navigation_is-open & {
            transform: translateY(0);
            transition: transform .4s;
        }
    }
    @media (min-width: $breakpoint-navigation) {
        position: fixed;
        transform: none;
    }
    @media (min-width: $breakpoint-header) {
        justify-content: flex-end;
        top: 0;
    }
    @media (min-width: $breakpoint-navigation) {
        left: $navigation-width;
        width: calc(100% - #{$navigation-width});
    }

    @include themer($theme-gyldendal) {
        background: $color-gu-dark-grey;
    }
    @include themer($theme-hansreitzel) {
        background-color: $color-hr-red;
    }
    @include themer($theme-munksgaard) {
        background-color: $color-mg-blue;
    }
}
