@mixin mix-arrow-right-after() {
    padding-right: 1em;
    position: relative;

    &:after {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 12px;
        content: "";
        filter: invert(1);
        background-image: url("../assets/icons/icon_arrow-right.svg");
        background-size: contain;
    }
}

@mixin mix-arrow-right-before() {
    padding-left: 0.85em;
    position: relative;

    &:before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 12px;
        content: "";
        filter: invert(1);
        background-image: url("../assets/icons/icon_arrow-right.svg");
        background-size: contain;
    }
}

@mixin mix-theme-arrow-right-after() {
    @include mix-arrow-right-after();
    line-height: 0.9em;
}
