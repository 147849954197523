.newsletter-subscribe {
    margin-bottom: $gutter*2;

    p + p {
        margin-top: 1em;
    }
    .button {
        margin: .5em 1em;
    }
}

.newsletter-unsubscribe__image {
    max-width: 820px;
    margin: 0 auto;
    padding-bottom: 25%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-image: url("/dist/assets/gfx/Newsletter_Unsubscribe.png");

    @media (min-width: $breakpoint-w-rhino) {
        background-size: auto 280px;
        padding-bottom: 280px;
    }
}

.newsletter-subscribe__image {
    max-width: 820px;
    margin: 0 auto;
    padding-bottom: 25%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-image: url("/dist/assets/gfx/Newsletter_all.png");

    @media (min-width: $breakpoint-w-rhino) {
        background-size: auto 280px;
        padding-bottom: 280px;
    }
}