.header-filter {
    overflow: hidden;
    transition: max-width .5s, border-color 1s;
    max-width: 0;
    min-width: auto;
    display: none;

    button {
        outline: none;
        border: 0;
        margin: 0;
        padding: 0;
        text-align: center;
        overflow: hidden;
        line-height: 0;
        transform: translateX(-100%);
        height: 100%;
        width: 100%;
        transition: transform .3s;

        @include themer($theme-gyldendal) {
            background-color: $color-gu-dark-grey;
        }
        @include themer($theme-hansreitzel) {
            background-color: $color-hr-red;
        }
        @include themer($theme-munksgaard) {
            background-color: $color-mg-blue;
        }
    }

    .filterVisible & {
        min-width: 50px;
        max-width: 100%;
        transition: max-width .5s, border-color 0s;

        @media (max-width: $breakpoint-header) {
            border-right-width: 2px;
            border-left: none !important;
        }
        @include mix-content-max-width($breakpoint-w-lion) {
            display: block;
        }
        button {
            transform: translateX(0);
        }
    }
}