.header-basket {
    @media (max-width: $breakpoint-header) {
        border-right: none !important;
    }
    &.isEmpty {
        display: none;
    }
}

.header-basket-button {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
    overflow: hidden;
    line-height: 0;
    border: 0;
    cursor: pointer;
    background-color: transparent;
    font-family: $body-font-family;
    transition: all .5s;

    &:hover {
        background-color: rgba(255, 255, 255, .1);
    }

    @media (min-width: $breakpoint-header) {
        display: flex;
        max-width: 50px;

        .isLoggedIn & {
            margin-right: 50px;
            display: block;
            border-right: none;
        }
    }
    @media (min-width: $breakpoint-header) {
        width: 50px;
        transition: width .15s $easeInOutCubic, max-width .15s $easeInOutCubic;
        transition-delay: .25s;
        .isOpen & {
            max-width: 500px;
            width: 500px;
            transition: width .15s $easeInOutCubic, max-width .15s $easeOutExpo;
            transition-delay: 0;
        }
    }
}

.header-basket__icon {
    position: relative;
    z-index: 1;
    min-width: 50px;

    svg {
        position: relative;
        left: -1px;
    }
}

.header-basket__icon__count {
    position: absolute;
    top: 16px;
    left: 0;
    width: 100%;
    display: block;
    color: #fff;
    z-index: 1;

    .hasHighcount & {
        font-size: 10px;
    }
}

.header-basket__label {
    line-height: 50px;
    color: #FFF;
    font-size: 16px;
    text-transform: uppercase;
    white-space: nowrap;
    display: none;

    @include themer($theme-gyldendal) {
        font-weight: $fontweight-gu-medium;
    }
    @include themer($theme-hansreitzel) {
        font-weight: $fontweight-hr-semibold;
    }
    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-semibold;
    }
    @media (min-width: $breakpoint-header) {
        display: inline-block;
    }
}

.header-basket-button__fx-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 0;
    height: 0;
    z-index: 0;
    border-radius: 50%;
    background-color: rgba($color-gu-green, .8);

    @include themer($theme-hansreitzel) {
        background-color: rgba($color-hr-blue, .8);
    }
    @include themer($theme-munksgaard) {
        background-color: rgba($color-mg-green-dark, .8);
    }
}

.header-basket-errors {
    position: absolute;
    bottom: -100vh;
    margin-bottom: 30px;
    right: 10px;
    width: 300px;
}

.header-basket-error {
    padding: 20px;
    margin: 10px 0;
    background-color: $color-error;
    color: $white;
    border-radius: 5px;
    fint-size: 14px;
    font-weight: $fontweight-gu-medium;
    text-align: left;

    p {
        margin: 0;
    }
}

.header-basket-content {
    position: absolute;
    top: 50px;
    right: 0;
    width: 500px;

    @include mix-content-max-width($breakpoint-w-cat) {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
    }
}