.author-details-container {
     @include mix-grid-section();
    padding: 0;
 }
.author-details {
    width: 100%;

    @include mix-content-min-width($breakpoint-w-wolf) {
        display: flex;
    }
}

.author-details__image-container {
    width: 100%;
    margin-bottom: $gutter;

    @include mix-content-min-width($breakpoint-w-wolf) {
        width: calc(100%/3);
    }
    @include mix-content-min-width($breakpoint-w-rhino) {
        width: 25%;
    }

    img {
        width: 50%;
        margin: 0 auto;
        display: block;
        transition: width .3s;

        @include mix-content-min-width($breakpoint-w-wolf) {
            width: 80%;
        }
        @include mix-content-min-width($breakpoint-w-elephant) {
            width: 80%;
        }
    }
}

.author-details__meta {
    width: 100%;

    @include mix-content-min-width($breakpoint-w-cat) {
        padding: 0 $gutter;
    }
    @include mix-content-min-width($breakpoint-w-wolf) {
        width: calc(100%/3*2);
    }
    @include mix-content-min-width($breakpoint-w-rhino) {
        width: 50%;
    }
}
.author-details__meta__name {
    @include mix-theme-like-h2();
    margin-bottom: 15px;
}

.author-details__meta__description {

    a {
        @include mix-theme-like-a();
    }
}