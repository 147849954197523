@use "sass:math";

.product-media-link {
    border: none;
    border-top: 1px solid $dark-grey-op10;
    background: none;
    font-family: inherit;
    width: 100%;
    padding: 1rem 0rem;
    text-transform: uppercase;
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: $gutter;
    align-items: center;

    svg {
        color: #262626;
    }
}

.product-media-link:hover {
    cursor: pointer;
    .product-media-link__text {
        color: $color-theme-primary;
    }
}

.product-media-link__text {
    color: tint($color-theme-primary, 20);
    font-size: 0.75rem;
    line-height: 1rem;
    @include mix-theme-bold-bodyfont();
    text-align: left;
}

.product-media-link:last-child {
    border-bottom: 1px solid $dark-grey-op10;
}
