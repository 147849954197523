.view-more-section {
    position: relative;
    overflow: hidden;
    display: block;
    max-height: 17em;
    padding-bottom: 25px;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 40px;
        background: linear-gradient(to top, #fff 50%, rgba(255, 255, 255, 0) 100%);
    }

    &.open {
        max-height: none;
    }

    &.no-shadow {
        &:after {
            background: none;
        }
    }
}

.view-more-btn {
    @include reset__button("true");
    margin: auto;
    display: block;
    position: relative;
    z-index: 1;
    outline-offset: 5px;
}
