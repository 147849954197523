@use "sass:math";

.dropdown-checkbox {
    position: relative;
    display: block;
}

@keyframes dropdown-checkbox__popup__anim {
    0% {
        transform: translate(-50%, -20%) scale(0);
    }

    100% {
        transform: translate(-50%, -20%) scale(1);
    }
}

.dropdown-checkbox__popup {
    width: 115%;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba($color-gu-dark-grey, 0.7);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -20%);
    z-index: 60;
    overflow: hidden;
    min-width: 320px;
    max-width: 460px;
    animation: dropdown-checkbox__popup__anim 0.15s;
    animation-fill-mode: forwards;
}
.dropdown-checkbox__popup__footer {
    background-color: #fff;
    padding: $gutter * 0.5;
}

.dropdown-checkbox__options {
    max-height: 380px;
    overflow-y: auto;
    background-color: $lightgrey;
}
.dropdown-checkbox__option {
    position: relative;
    height: 50px;
    line-height: 50px;

    .checkbox {
        display: block;
        padding: 0;
        cursor: pointer;
    }
    .checkbox__indicator {
        position: absolute;
        right: $gutter;
        top: 50%;
        transform: translateY(-50%);
        left: auto;
    }
    & + .dropdown-checkbox__option {
        border-top: 1px solid $color-gu-fade-20;
    }
}

.dropdown-checkbox__option__text {
    padding-left: $gutter;
    padding-right: $gutter * 3;
    font-size: 1rem;

    &:hover {
        background-color: darken($lightgrey, 5);
    }
}

.dropdown-checkbox__option__text__details {
    float: right;
    font-size: math.div(14, 16) * 1em;
}
