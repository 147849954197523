@use "sass:math";

.product-picker__trumpet {
    @include mix-theme-bold-bodyfont();
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-size: 0.75rem;
}

.product-picker__item {
    padding: 0;
    border-radius: $border-radius;
    background-color: $white;
    border: 1px solid $color-gu-fade-20;

    & + .product-picker__item {
        margin-top: 10px;
    }
}

.product-picker__item--selected {
    @include mix-primary-theme-border(1px, solid);
}

.product-picker__item__label {
    border: 1px solid $color-gu-fade-20;
    border-radius: $border-radius;
    background-color: $lightgrey;
    display: flex;
    padding: $gutter * 0.5 $gutter * 0.5 $gutter * 0.5 $gutter * 2;
    position: relative;
    line-height: 1.4em;

    .selected & {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.product-picker__item__link {
    color: inherit !important;
    display: flex;
    padding: $gutter * 0.5 $gutter * 0.5 $gutter * 0.5 $gutter * 2;
    position: relative;
    line-height: 1.4em;

    .product-picker__item__link__content {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
    }
}

.product-picker__item__radiowrap {
    position: absolute;
    display: block;
    left: $gutter * 0.5;
    width: 25px;
    height: 25px;
}

.product-picker__item__name {
    flex-grow: 1;
}

.product-picker__item__price {
    text-align: right;
    display: flex;
    align-items: end;
    flex-direction: column;
}

.product-picker__item__price-primary {
    font-weight: 600;
    display: inline-block;
    .discounted & {
        @include mix-theme-discounted-old-price;
    }
}

.product-picker__item__price-discount {
    @include mix-theme-discounted-new-price;
    margin-left: 6px;
    display: inline-block;
    & ~ .product-picker__item__price-primary {
        @include mix-theme-discounted-old-price;
    }
}

.product-picker__unit-text {
    @include mix-theme-medium-bodyfont();
    color: $color-gu-blue-dusty;
    font-size: 0.75rem;
}

.product-picker__note {
    color: $color-gu-blue-dusty;
    font-size: 0.75rem;
}
.product-picker--primary-text {
    @include mix-theme-bold-bodyfont();
    color: $dark-grey;
    text-transform: uppercase;
    font-size: 1rem;
}

.product-picker__total {
    padding-top: 1.5rem;

    .product-picker__total-primary {
        display: flex;
        justify-content: space-between;
    }

    .product-picker__total-secondary {
        text-align: right;
    }
}

//info when selected
.product-picker-selected-details {
    background-color: darken($color-gu-fade-10, 2);
    border-radius: $border-radius;
    font-size: 14px;
    margin-top: 0.5rem;
    padding: 0.5rem 3.5px;

    hr {
        border-top: 1px solid $color-gu-fade-20;
        margin: 0 $gutter * 0.5;
    }
    a {
        @include mix-theme-like-a;
    }
}

@keyframes maxHeightRowAnim {
    0% {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    99% {
        overflow: hidden;
    }
    100% {
        max-height: 500px;
        overflow: auto;
    }
}

.product-picker__item__info__row {
    display: flex;

    &.vertical-align {
        align-items: baseline;
    }
    &.tall {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
    }
    &.tall-bottom {
        padding-bottom: 0.5em;
    }
    &.tall-top {
        padding-top: 0.5em;
    }
    .full,
    .left,
    .right {
        flex-grow: 1;
        padding: 0.5em $gutter * 0.5;
    }
    .right {
        text-align: right;
    }
}
.product-picker__choose-classes-wrapper {
    float: right;
}
.product-picker__item__info__row__header {
    @include mix-theme-bold-bodyfont();
    @include mix-theme-headline-color();
}

.product-picker__item__product-info-text {
    padding: 1em $gutter * 0.5;
    font-size: 16px;
    ul,
    ol {
        padding-left: 20px;
    }
}

//button in the bottom of the product picker
.product-picker__buttons {
    margin-top: 10px;

    [class*="button"] {
        margin-top: 10px;
    }
}

.product-picker-table {
    width: 100%;
    border-spacing: 0px;
    border-collapse: separate;

    td {
        margin: 0;
        padding: 0 $gutter * 0.5;
        text-align: left;
    }
    ul {
        list-style: none;
    }
}

// notification after buttons
.product-picker__notification-container {
    margin-top: 25px;
    margin-left: math.div($gutter, 2);
    margin-right: math.div($gutter, 2);
}

//Quantity select
.product-picker_quantitywrap {
    margin-top: 0.625rem;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;

    .amount-input {
        @include mix-primary-theme-border(1px, solid);
    }
}

.product-picker__quantity-select-text {
    font-size: 14px;
}

.select {
    padding: $gutter * 0.3 $gutter * 0.8 $gutter * 0.3 $gutter * 0.4;
    font-family: inherit;
    font-size: 0.875rem;
    border-radius: $border-radius;
    outline: none;
    @include mix-primary-theme-border(1px, solid);
}

// Only for development - REMOVE
.pptest {
    margin: $gutter;
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.5);
    font-size: 10px;
    padding: 1em;
}
