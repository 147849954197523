.navigation-mobile-profile {
    ul {
        margin-top: 2rem;
        list-style-type: none;
    }
    a,
    button {
        color: inherit;
        @include themer($theme-gyldendal) {
            text-transform: uppercase;
        }
    }
}
