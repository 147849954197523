.full-width-image-text-cta {
    position: relative;
    overflow: hidden;


    display: grid;

    @include mix-content-min-width($breakpoint-w-dog) {
        display: flex;

        &--has-image {
            min-height: 0px;
        }
    }
}

.full-width-image-text-cta__bg-image {
    position: relative;
    // To ensure there is no extra ghost space on the picture-tag
    line-height: 0;

    grid-column: 1/-1;
    grid-row: 1/-1;

    img {
        display: block;
        width: 100%;
        height: 100%;
        max-width: none;
        object-fit: cover;
        object-position: 0% 0%;
        @include mix-content-min-width($breakpoint-w-dog) {
            object-position: unset;
        }
    }

    @include mix-content-min-width($breakpoint-w-dog) {
        // Full height, center horizontally
        position: absolute;
        height: 100%;
        width: 100%;
        inset: 0;
    }
}

.full-width-image-text-cta__content-box {
    @include mix-grid-section;
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    width: 100%;
    grid-column: 1/-1;
    grid-row: 1/-1;
    backdrop-filter: blur(5px);

    @include mix-content-min-width($breakpoint-w-dog) {
        backdrop-filter: none;
    }
}

.full-width-image-text-cta__content-box-inner {
    width: 100%;
    margin: 20px 0;

    @include mix-content-min-width($breakpoint-w-cat) {
        margin: 40px 0;
    }
    @include mix-content-min-width($breakpoint-w-dog) {
        max-width: 50%;
        margin: 80px 0;
    }

    // Make sure that the quote is only off the grid when there is room for it
    .content--text-align-left .article-quote-large {
        @include mix-content-max-width($breakpoint-w-elephant + 40) {
            text-indent: $gutter * 2.3;
        }
    }


    .title-intro-rich-cta-section {
        @include mix-content-max-width($breakpoint-w-dog) {
            text-align: center;
        }
    }
}
