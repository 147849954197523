.fade {
    opacity: 0.01;
    transition: opacity 200ms ease-in-out;
}

.fade-enter,
.fade-enter-active {
    opacity: 1;
}

.fade-exit,
.fade-exit-active {
    opacity: 0.01;
}
