@mixin mix-tags-label {
    font-size: 12px;
    text-transform: uppercase;

    @include themer($theme-gyldendal) {
        font-weight: $fontweight-gu-black;
    }
    @include themer($theme-hansreitzel) {
        color: $color-hr-blue;
        font-weight: $fontweight-hr-din-bold;
    }
    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-bold;
    }
}

@mixin mix-tags {
    display: inline-block;
    padding: 6px;
    border: none;
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
    white-space: nowrap;
    box-shadow: none;
    background-color: black;
    font-weight: $fontweight-gu-bold;

    @include themer($theme-gyldendal) {
        border-radius: 3px;
    }
    @include themer($theme-hansreitzel) {
        font-weight: $fontweight-hr-din-bold;
    }
}

@mixin mix-tags-hollow($color: grey) {
    background-color: transparent;
    border: 1px solid $color;
    color: $color;
    padding: 5px;
}

@mixin mix-tags--white {
    @include mix-tags;
    background-color: $white;
    border: 1px solid $dark-grey-op10;
    color: $color-theme-primary;
    padding: 0.25rem 1.5rem;
    border-radius: $border-radius;
}

@mixin mix-tags-hollow--secondary {
    @include mix-tags;
    @include mix-tags-hollow($color-gu-dark-grey);

    @include themer($theme-hansreitzel) {
        @include mix-tags-hollow($color-hr-blue);
    }

    @include themer($theme-munksgaard) {
        @include mix-tags-hollow($color-mg-blue-dusty);
    }
}

@mixin mix-tags--primary {
    @include mix-tags;
    background-color: $color-gu-orange;
    color: $dark-grey;

    @include themer($theme-gyldendal) {
        background-color: $color-gu-orange;
        color: $dark-grey;
    }

    @include themer($theme-hansreitzel) {
        background-color: $color-hr-red;
        color: $white;
    }

    @include themer($theme-munksgaard) {
        background-color: $color-mg-green-dark;
        color: $white;
    }
}

@mixin mix-tags--secondary {
    @include mix-tags;
    color: $white;
    background-color: $color-gu-dark-grey;

    @include themer($theme-hansreitzel) {
        background-color: $color-hr-blue;
    }

    @include themer($theme-munksgaard) {
        background-color: $color-mg-blue-dusty;
    }
}
