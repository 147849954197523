@use "sass:math";

.social-feed-container {
    background: $color-gu-fade-00;
    border-top: 1px solid $color-gu-fade-20;
    border-bottom: 1px solid $color-gu-fade-20;
    padding: $gutter 0;
}

.social-feed__inner {
    @include mix-grid-section();
}

.social-feed__header {
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    @include mix-scrollbars();
}

.social-feed__header__title {
    @include mix-theme-like-h2();
}

.social-feed__list {
    -webkit-overflow-scrolling: touch;
    overflow-y: visible;
    overflow-x: auto;
    width: 100%;
}

.social-feed__list__inner {
    list-style-type: none;
    margin: 0 auto;
    padding: $gutter 0;
    display: flex;
    float: left;
    min-width: 100%;
}

.social-feed__list__item {
    padding: 0;
    width: 300px;
    min-width: 300px; //ie forever !!!
    display: flex;
    margin: 0 math.div($gutter, 2);

    &:first-of-type {
        margin-left: $gutter-small;
        @include mix-content-min-width($breakpoint-w-cat) {
            margin-left: $gutter;
        }

        @include mix-content-min-width(($max-width + $gutter)) {
            margin-left: calc(
                (((100vw - 15px) - (#{$max-width} - (#{$gutter} * 2))) - #{$navigation-width}) / 2
            );
        }
    }
    &:last-of-type {
        margin-right: $gutter-small;
        @include mix-content-min-width($breakpoint-w-cat) {
            margin-right: $gutter;
        }

        @include mix-content-min-width(($max-width + $gutter)) {
            margin-right: calc(
                (((100vw - 15px) - (#{$max-width} - (#{$gutter} * 2))) - #{$navigation-width}) / 2
            );
        }
    }

    & + .social-feed__list__item {
        margin-left: $gutter;
        @media (min-width: $breakpoint-w-dog) {
            margin-left: $gutter * 2;
        }
    }
}
