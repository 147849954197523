.product-slider__header-container {
    @include mix-grid-section();
    overflow: hidden;
    @include mix-content-min-width($breakpoint-w-dog) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.product-slider__header {
    @include mix-theme-like-h2();

    @include themer($theme-gyldendal) {
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: $fontweight-gu-light;
        line-height: 2.5rem;
        @include mix-content-max-width($breakpoint-w-cat) {
            line-height: 2;
        }
    }
}
.product-slider__go-to-link a {
    @include mix-theme-like-a();
    @include mix-theme-arrow-right-after();
    text-decoration: underline;
    white-space: nowrap;
    display: flex;
    align-items: center;

    &:hover,
    &:focus-visible {
        text-decoration: underline;
    }

    svg {
        margin-left: 0.75rem;
    }
}

.empty-product-slider {
    min-height: 300px;
    border-top: 1px solid $color-gu-fade-20;
    border-bottom: 1px solid $color-gu-fade-20;
    background: $lightgrey;
    position: relative;

    @include themer($theme-hansreitzel) {
        background: $color-hr-blue-light;
    }
}
.empty-product-slider__text {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2em;
    color: $color-gu-fade-20;
    margin: 0 auto;
    padding: 1em;
    text-align: center;

    @include themer($theme-hansreitzel) {
        color: $color-hr-blue;
        opacity: 0.5;
    }
    @include themer($theme-munksgaard) {
        color: $color-mg-blue;
        opacity: 0.08;
    }
}
.product-slider {
    @include mix-grid-section();
    min-height: 292px;

    .slick-list {
        padding-bottom: $gutter * 2 !important; // Has to overwrite inline styling!
        position: relative;
    }
    .slick-track {
        display: flex;
    }
    .slick-slide {
        border: 1px solid $color-gu-fade-20;
        border-radius: $border-radius;
        margin-right: 1.5rem;
    }

    // To even the item-hights
    .slick-initialized .slick-slide,
    .slick-slide {
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: center;
    }

    .product__img {
        display: block;
        text-align: center;

        img {
            display: inline-block;
            vertical-align: middle;
            object-fit: contain;
            object-position: top;
            height: 192px;
            width: 135px;
        }
    }

    // Enable images to lap over the active slide
    @include mix-content-max-width($breakpoint-w-cat) {
        .slick-slide {
            .product__img {
                img {
                    transition: all 0.35s ease;
                    position: relative;
                    z-index: 100;
                    height: 88px;
                    width: 62px;
                }
            }
        }
    }
}
