// GU
$theme-colors: (
    "color-black": $black,
    "color-white": $white,
    "color-lightgrey": $color-gu-fade-10,
    "color-gu-dark-grey": $color-gu-dark-grey,
    "color-gu-orange": $color-gu-orange,
    "color-gu-orange-light": $color-gu-orange-light,
    "color-gu-green": $color-gu-green,
    "color-gu-blue-light": $color-gu-blue-light,
    "color-gu-blue-dusty": $color-gu-blue-dusty,
    "color-gu-pink": $color-gu-pink,
    "color-gu-red": $color-gu-red,
    "color-gu-purple": $color-gu-purple
);

// Munksgaard
@if $site == "munksgaard" {
    $theme-colors: (
        "color-black": $black,
        "color-white": $white,
        "color-lightgrey": $color-gu-fade-10,
        "color-mg-blue": $color-mg-blue,
        "color-mg-green": $color-mg-green,
        "color-mg-green-dark": $color-mg-green-dark,
        "color-mg-blue-dusty": $color-mg-blue-dusty,
        "color-mg-blue-dusty-light": $color-mg-blue-dusty-light,
        "color-mg-blue-light": $color-mg-blue-light
    );
}

// Hans Reitzel
@if $site == "hansreitzel" {
    $theme-colors: (
        "color-black": $black,
        "color-white": $white,
        "color-hr-red": $color-hr-red,
        "color-hr-beige": $color-hr-beige,
        "color-hr-red-light": $color-hr-red-light,
        "color-hr-blue": $color-hr-blue,
        "color-hr-blue-dusty": $color-hr-blue-dusty,
        "color-hr-blue-light": $color-hr-blue-light,
        "color-hr-green": $color-hr-green,
        "color-hr-green-dark": $color-hr-green-dark
    );
}

// In order to support different color-classes for subthemes such as 'elearning', we check for a different variable `$subtheme` instead.
@if variable-exists("subtheme") and $subtheme == "elearning" {
    $theme-colors: (
        "color-black": $black,
        "color-white": $white,
        "color-elearn-dark-grey": $color-elearn-abbey,
        "color-elearn-salmon": $color-elearn-chestnut-rose,
        "color-elearn-light-grey": $color-elearn-pampas,
        "color-elearn-red": $color-elearn-hippie-pink,
        "color-elearn-green": $color-elearn-roti,
        "color-elearn-turquoise": $color-elearn-breaker-bay,
        "color-elearn-beige": $color-elearn-soft-amber,
        "color-elearn-yellow": $color-elearn-yellow,
        "color-elearn-blue": $color-elearn-east-bay
    );
}

@mixin mix-color-classes($color) {
    &--text {
        color: $color !important;

        h1,
        h2,
        h3,
        .content--intro-paragraph {
            color: $color !important;
        }
        form {
            @include mix-form-color($color);
        }
    }
    &--bg {
        background-color: $color !important;
        border-color: $color !important;

        .custom-primary-cta-button & {
            &:hover {
                background-color: darken($color, 4) !important;
                border-color: darken($color, 4) !important;
            }
        }
    }
}

@each $class-name, $color in $theme-colors {
    .#{$class-name} {
        @include mix-color-classes($color);
    }
}
