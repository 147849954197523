@keyframes anim-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@mixin mix-amin-fadein($duration: 300ms, $delay: 0) {
    opacity: 0;
    animation: anim-fade-in $duration;
    animation-fill-mode: forwards;
    animation-delay: $delay;
}
.anim-fade-in {
    @include mix-amin-fadein();
}
