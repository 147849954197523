.teaser-grid-video__modal-content {
    width: 95vw;
    max-width: 800px;

}


.teaser-grid-video__video-wrapper {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;

    iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}
