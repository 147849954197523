html.nav-locked-scroll {
    body {
        height: 100%;
        overflow: hidden;
    }
}

@media (max-width: $breakpoint-navigation) {
    html.navigation_is-open {
        position: fixed;

        body {
            position: fixed;
            height: 100%;
            overflow: hidden;
            max-width: 100%;
        }
    }
}

html {
    scroll-padding-top: $navigation-header-height + 20px;

    @media (prefers-reduced-motion: no-preference) {
        scroll-behavior: smooth;
    }
}

body {
    overflow-anchor: none;
    &.locked-scroll {
        overflow: hidden;
        height: 100%;
    }
}

img {
    max-width: 100%;
}
