@use "sass:math";

@mixin mix-content-max-width($width: $breakpoint-w-lion) {
    @media (max-width: ($width - 1)) {
        @content;
    }
}

@mixin mix-content-min-width($width: $breakpoint-w-lion) {
    @media (min-width: $width) {
        @content;
    }
}

@mixin mix-grid-section() {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    padding: 0 $gutter-small;

    @include mix-content-min-width($breakpoint-w-cat) {
        padding: 0 $gutter;
    }
}

@mixin mix-half-grid-section() {
    @include mix-grid-section;
    max-width: math.div($max-width, 2);
}
