.profile-activate-e-key {
    max-width: 653px;
    margin: 0 auto;

    h2 {
        @include mix-theme-like-h5;
        @include mix-theme-bold-bodyfont;
        text-transform: uppercase;
        color: $dark-grey;
    }
}
.profile-activate-e-key__title {
    @include mix-theme-like-h2;
}
.profile-activate-e-key__description {
    margin-top: 1rem;
    color: $black;
}

.profile-activate-e-key__form {
    margin-top: 2rem;

    .input-field {
        margin-top: 2rem;
    }

    input {
        max-width: 495px;
        @include mix-content-max-width($breakpoint-w-dog) {
            max-width: 100%;
        }
    }
}
.profile-activate-e-key__success {
    margin-top: 2rem;
}
.profile-activate-e-key__button {
    @include mix-button-is-slim;
    @include mix-content-max-width($breakpoint-w-dog) {
        width: 100%;
    }
    &--primary {
        @include mix-button--primary;
    }
    &--hollow {
        @include mix-button-hollow--secondary;
        color: $dark-grey;
    }
    &--primary,
    &--hollow {
        margin-top: 2.5rem;
    }
}
