div:has(header.navigation-header) {
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: 50;
}

.navigation-header {
    height: $navigation-header-height;
    background-color: $navigation-bg-color;
    color: $navigation-text-color;
    position: relative;
    z-index: 51;
    @include themer($theme-munksgaard) {
        svg * {
            stroke-width: 1px;
        }
    }
}

.navigation-header__inner {
    width: 100%;
    max-width: $max-width; // TODO: Check me
    height: 100%;
    margin: 0 auto;
    display: flex;
    @include mix-content-padding-sides;
    align-items: center;
    position: relative;
    justify-content: space-between;
    background-color: inherit;
    @include mix-content-min-width($breakpoint-navigation) {
        justify-content: initial;
    }
}

.navigation-header__menu-button {
    @include reset__button("true");
    color: inherit;
    @include mix-content-min-width($breakpoint-navigation) {
        display: none;
    }
}

.navigation-header__items {
    list-style-type: none;
    display: none;
    align-items: stretch;
    height: 100%;
    @include mix-content-min-width($breakpoint-navigation) {
        display: flex;
    }
}

.navigation-header__logo {
    width: 192px;
    height: 38px;
    margin-right: 20px;
    flex: none;
    display: flex;
    align-items: center;
    @include mix-content-max-width($breakpoint-navigation) {
        width: 142px;
        height: 30px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.navigation-header-item {
    &:first-child {
        margin-left: 20px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigation-header-item__link {
    color: inherit;
    font-size: inherit;
}

.navigation-header__service-menu {
    display: flex;
    height: 100%;
    align-items: center;
    margin-left: auto;
    & > * {
        padding: 0 15px;
        @include mix-content-max-width($breakpoint-navigation) {
            padding: 0 5px;
        }
    }
}

.navigation-header__service-menu-button {
    @include button-reset;
}

.navigation-header__button {
    height: 100%;
    svg {
        display: block;
        color: inherit;
    }
    &--isActive,
    &:hover {
        @include themer($theme-hansreitzel) {
            background-color: $color-hr-fade-15;
        }
        @if variable-exists("subtheme") and $subtheme == "elearning" {
            background-color: transparent;
        }
    }
}

.navigation-header-item__button {
    @include button-reset;
}

.navigation-header-item__button,
.navigation-header-item__link,
.navigation-header__service-menu-link {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    color: inherit;
    @include themer($theme-gyldendal) {
        font-weight: $fontweight-gu-medium;
        font-size: 0.75rem;
        text-transform: uppercase;
    }
    @include themer($theme-munksgaard) {
        font-size: 0.95rem;
    }
    @include themer($theme-hansreitzel) {
        transition: background-color 150ms ease-in-out;
        font-family: $body-font-family;
        font-weight: $fontweight-hr-din-bold;
        font-size: 0.875rem;
        text-transform: uppercase;
        @if variable-exists("subtheme") and $subtheme == "elearning" {
            font-family: $header-font-family;
            text-transform: none;
        }
    }
    &--isActive,
    &:hover {
        @include themer($theme-gyldendal) {
            font-weight: $fontweight-gu-bold;
        }
        @include themer($theme-hansreitzel) {
            background-color: $color-hr-fade-15;
        }
        @if variable-exists("subtheme") and $subtheme == "elearning" {
            background-color: transparent;
        }
        @include themer($theme-munksgaard) {
            font-weight: $fontweight-mg-semibold;
            padding-right: 17px;
        }
    }
}

.navigation-header__service-menu-link {
    @include mix-content-max-width($breakpoint-navigation) {
        display: none;
    }
}

.navigation-header__mobile-menu-button {
    color: inherit;
    line {
        transition: transform 150ms ease;
    }
    line:nth-child(1) {
        transform-origin: top left;
    }
    line:nth-child(2) {
        transform-origin: center left;
    }
    line:nth-child(3) {
        transform-origin: bottom left;
    }
    &--isActive {
        line:nth-child(1) {
            transform: rotate(45deg) scaleX(0.95);
        }
        line:nth-child(2) {
            transform: scaleX(0);
        }
        line:nth-child(3) {
            transform: rotate(-45deg) scaleX(0.95);
        }
    }
}

.navigation-header__skip-link {
    @include mix-theme-link-color();
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    &:focus {
        display: block;
        position: absolute;
        z-index: 100;
        left: 0;
        top: 80px;
        right: 0;
        margin: auto;
        width: 210px;
        height: auto;
        background: black;
        color: white;
        text-align: center;
        border-radius: 0 0 $border-radius $border-radius;
        padding: 4px;
        outline: 1px solid black;
    }
}
