.teaser-grid__load-more-button-wrapper {
    margin-top: 2rem;
}

@mixin mix-teaser-grid-25-col() {
    width: 25%;

    .teaser-tile__image-container {
        @include mix-aspect-ratio-padding-bottom(4, 3);
    }
}
@mixin mix-teaser-grid-33-col() {
    width: 33.3333333%;

    .teaser-tile__image-container {
        @include mix-aspect-ratio-padding-bottom(4, 3);
    }
}
@mixin mix-teaser-grid-50-col() {
    width: 50%;

    .teaser-tile__image-container {
        @include mix-aspect-ratio-padding-bottom(16, 9);
    }
}
@mixin mix-teaser-grid-100-col() {
    width: 100%;

    .teaser-tile__image-container {
        @include mix-aspect-ratio-padding-bottom(16, 9);

        @include mix-content-min-width($breakpoint-w-dog) {
            @include mix-aspect-ratio-padding-bottom(21, 9);
        }
    }
}
@mixin mix-teaser-grid-100-col-side-by-side() {
    width: 100%;

    .teaser-tile {
        flex-direction: row;
    }
    .teaser-tile__image-wrapper {
        width: col(4);
    }
    .teaser-tile__content-wrapper {
        width: col(4);
        padding-left: 0;
        margin-left: -1rem;
    }
    .teaser-tile__image-container {
        @include mix-aspect-ratio-padding-bottom(4, 3);

        @include mix-content-min-width($breakpoint-w-elephant) {
            @include mix-aspect-ratio-padding-bottom(16, 9);
        }
    }
}
