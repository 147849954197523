.box-link-list {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
}

.box-link-list-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
}

.box-link-list__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    // Compensating for the item padding
    margin: -$gutter-small 0;

    @include mix-content-min-width($breakpoint-w-cat) {
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        // Compensating for the item padding
        margin: -$gutter 0;
    }
}

.box-link-list__list-item {
    padding: $gutter-small;

    @include mix-content-min-width($breakpoint-w-cat) {
        padding: $gutter;
        width: 50%;
    }
    @include mix-content-min-width($breakpoint-w-lion) {
        width: 33.333333%;
    }
}

.box-link-list__list-item-inner {
    @include mix-boxes__background;
    @include mix-boxes__border;
    @include mix-theme-headline-color;

    @include themer($theme-gyldendal) {
        font-weight: $fontweight-gu-bold;
        border-radius: 0.3em;
    }
    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-bold;
    }
    @include themer($theme-hansreitzel) {
        font-weight: $fontweight-hr-din-bold;
    }

    // For IE only
    display: flex;
    flex-direction: column;

    a {
        color: inherit;
        padding: 16px 30px;
        line-height: 1.2;
        text-align: center;
        min-height: 80px; // Make room for 2 lines
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
