.product-list-wrapper {
    @include mix-grid-section;
    @include mix-clearfix-table;
}

.product-list-facets {
    float: left;
    width: 100%;
    padding-bottom: $gutter;
    display: none;

    @include mix-content-min-width($breakpoint-w-lion) {
        width: 26%;
        display: block;
    }
}

.product-list-container {
    width: 100%;

    .product-list-facets + & {
        @include mix-content-min-width($breakpoint-w-lion) {
            float: right;
            width: 74%;
            padding-left: $gutter;
        }
    }
}

.product-list-options {
    @include mix-clearfix-table;
    margin-bottom: 16px;
}

.product-list-options__item {
    float: left;
    &:last-child {
        float: right;
    }
}

.product-list__amount {
    margin: 4px 0 0;
    @include mix-tags-label;
}

.product-list {
    position: relative;
    margin-bottom: 2rem;
}

.product-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mix-content-min-width($breakpoint-w-lion) {
        justify-content: flex-end;
    }
}

.product-list-header__filter-button {
    display: flex;
    align-items: center;
    // All this text styling can probably be found in an existing mixin
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.75rem;
    color: black;
    height: 1rem;
    margin-bottom: -0.5rem;
    @include mix-content-min-width($breakpoint-w-lion) {
        display: none;
    }

    path {
        stroke: black;
    }
}
