.receipt-newsletter {
    position: relative;
    background-image: url("/dist/assets/gfx/letters.png");
    background-repeat: no-repeat;
    background-position: calc(100% + (369px / 3)) calc(100% + (330px / 5));
    background-color: $color-gu-fade-30;

    @include themer($theme-munksgaard) {
        background-color: $color-mg-blue;
    }
    @include themer($theme-hansreitzel) {
        background-color: $color-hr-red;

        a {
            color: #fff !important;
        }
    }
}

.receipt-newsletter__header {
    @include mix-theme-like-h2();
    @include themer($theme-gyldendal) {
        @include mix-gu-header-styling();
    }
    @include themer($theme-munksgaard) {
        font-size: 35px;
    }
    @include themer($theme-hansreitzel) {
        font-size: 35px;
    }
    color: #fff;
}

.receipt-newsletter__description {
    color: white;
    margin-bottom: 1em;
    line-height: 1.3em;
    top: -.2em;
    position: relative;
}
.receipt-newsletter__field-error-container {
    position: relative;
}

.receipt-newsletter__btn {
    @include themer($theme-hansreitzel) {
        @include mix-button(#fff, $color-gu-dark-grey);
        @include mix-button-is-slim();
    }
}