@use "sass:math";

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__container--open .autosuggest__addlink {
    display: block;
}

.react-autosuggest__suggestions-container {
    margin-top: -0.5em; //((sorry))
    position: absolute;
    z-index: 90;
    max-height: 300px;
    width: 100%;
    overflow-y: auto;

    .react-autosuggest__container--open & {
        border: 1px solid $color-gu-fade-20;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
}

.react-autosuggest__suggestion {
    padding: 0.25em 0.5em;
    background-color: $color-gu-fade-10;
    & + .react-autosuggest__suggestion {
        border-top: 1px solid $color-gu-fade-20;
    }
}

.react-autosuggest__suggestion--focused {
    background-color: $color-gu-fade-20;
}

.suggestions__item--link {
    color: $color-gu-orange;
}

.suggestions__item__address {
    opacity: 0.5;
    font-size: math.div(14, 16) * 1em;
}
