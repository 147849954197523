.helper-text {
    display: flex;
    align-items: flex-start;
    margin-top: 0.5rem;
    font-size: 0.75rem;
    line-height: 1.33;
    color: $color-gu-dark-grey;
    svg {
        color: $color-gu-blue-dusty;
        flex-shrink: 0;
    }
    span {
        margin-left: 0.5rem;
    }
}
