@use "sass:math";

.event-slider-container {
    overflow: hidden;
}

// THE HEADER
.event-slider__header-container {
    @include mix-grid-section();

    @include mix-content-min-width($breakpoint-w-dog) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.event-slider__header {
    @include mix-theme-like-h2();
}
.event-slider__go-to-link a {
    @include mix-theme-like-a();
    @include mix-theme-arrow-right-after();
    text-decoration: underline;
    white-space: nowrap;

    &:hover,
    &:focus-visible {
        text-decoration: underline;
    }
}

// THE SLIDER
.event-slider__slider-container {
    border-top: 1px solid $color-gu-fade-20;
    border-bottom: 1px solid $color-gu-fade-20;
    background: $lightgrey;

    @include themer($theme-hansreitzel) {
        background: $color-hr-blue-light;
    }
    @include themer($theme-munksgaard) {
        background: $color-mg-fade-30;
    }
}
.event-slider__slider-container__inner {
    @include mix-content-min-width($breakpoint-w-cat) {
        padding: 0 $gutter;
    }
    @include mix-content-min-width($breakpoint-w-elephant) {
        @include mix-grid-section();
    }
}
.event-slider__slider-wrapper {
    position: relative;

    @include mix-content-min-width($breakpoint-w-cat) {
        padding-left: 50%;
    }
    @include mix-content-min-width($breakpoint-w-horse) {
        padding-left: 33.3333333%;
    }
    @include mix-content-min-width($breakpoint-w-elephant) {
        padding-left: 25%;
    }

    // ----
    // FIX: server side rendering issue > too high event-slider on first rendering
    max-height: calc(100vw - 22px);

    @include mix-content-min-width($breakpoint-w-cat) {
        max-height: calc((100vw - 50px) / 2);
    }
    @media (min-width: $breakpoint-w-horse) {
        max-height: calc((100vw - 50px) / 3);
    }
    @media (min-width: $breakpoint-navigation) {
        max-height: calc((100vw - 300px - 50px) / 2);
    }
    @media (min-width: $breakpoint-w-horse + 320px) {
        max-height: calc((100vw - 300px - 50px) / 3);
    }
    @include mix-content-min-width($breakpoint-w-elephant) {
        max-height: calc((1440px - 50px) / 4);
    }
    // ----

    .slick-list {
        overflow: visible;
    }
    .slick-slide {
        border-right: 1px solid $color-gu-fade-20;

        &:first-child {
            border-left: 1px solid $color-gu-fade-20;
        }

        .event-slider--isEmpty & {
            border-color: transparent;
        }
    }
}
.event-slider__item {
    width: 100%;
    padding-bottom: 100%;
    position: relative;

    .event-slider--isEmpty & {
        @include mix-content-min-width($breakpoint-w-cat) {
            padding-bottom: 100%;
        }
        @include mix-content-min-width($breakpoint-w-horse) {
            padding-bottom: 50%;
        }
        @include mix-content-min-width($breakpoint-w-elephant) {
            padding-bottom: 33.33333333%;
        }
    }
}
.empty-event-slider-text {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2em;
    color: $color-gu-fade-23;
    margin: 0 auto;
    padding: 1em;
    text-align: center;

    @include themer($theme-hansreitzel) {
        color: $color-hr-blue;
        opacity: 0.8;
    }
}

.event-slider__item__date-indicator {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    top: $gutter;
    right: $gutter;
    left: $gutter;
    font-size: 28vw;
    text-align: center;

    @include mix-content-min-width($breakpoint-w-cat) {
        font-size: 13vw;
    }
    @include mix-content-min-width($breakpoint-w-horse) {
        font-size: 8vw;
    }
    @include mix-content-min-width($breakpoint-w-elephant) {
        font-size: 7em;
    }

    @include themer($theme-gyldendal) {
        font-weight: $fontweight-gu-medium;
        color: $color-gu-fade-20;
    }
    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-semibold;
        color: $color-mg-blue;
        opacity: 0.08;
    }
    @include themer($theme-hansreitzel) {
        font-weight: $fontweight-hr-semibold;
        color: $color-hr-blue;
        opacity: 0.08;
    }

    .date-interval & {
        justify-content: center;
    }

    .indicator__date-delimiter {
        font-size: 0.5em;

        &:before {
            content: "-";
        }
    }

    .day {
        text-align: center;

        @include themer($theme-gyldendal) {
            line-height: 0.8;
            margin-bottom: 10px;
        }
        @include themer($theme-munksgaard) {
            line-height: 0.8;
        }
        @include themer($theme-hansreitzel) {
            line-height: 1;
            margin-bottom: -10px;
        }
    }
    .month {
        font-size: 0.15em;
        text-transform: uppercase;
    }
}

.event-slider__item__meta {
    position: absolute;
    bottom: 0;
    padding: $gutter 20px;

    @include mix-content-min-width($breakpoint-w-cat) {
        padding: $gutter;
    }

    .meta__trumpet {
        @include mix-theme-trumpet();
    }

    .meta__title {
        font-weight: $fontweight-gu-bold;
        color: black;
    }

    .meta__where {
        font-style: normal;
    }

    .meta__when,
    .meta__where {
        @include mix-theme-label-text();
    }
    .meta__info {
        @include mix-theme-paragraph-color;
    }
}

// THE CALENDAR
.event-slider__slider-container__calender-container {
    cursor: pointer;
    overflow: visible;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    display: none;

    @include mix-content-min-width($breakpoint-w-cat) {
        width: 50%;
        display: block;
    }
    @include mix-content-min-width($breakpoint-w-horse) {
        width: 33.3333333%;
    }
    @include mix-content-min-width($breakpoint-w-elephant) {
        width: 25%;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 7px;
    }

    &:before {
        left: -7px;
        background: rgba(255, 255, 255, 0);
        background: linear-gradient(to left, $color-gu-fade-20, rgba(255, 255, 255, 0));
    }
    &:after {
        right: -7px;
        background: rgba(255, 255, 255, 0);
        background: linear-gradient(to right, $color-gu-fade-20, rgba(255, 255, 255, 0));
    }
}
.calender-container__calender-inner {
    padding-bottom: 100%;
}

// REACT-DATEPICKER CALENDAR TWEAKING/OVERWRITING
// TODO: Add the class to the element instead of using the .event-slider__calender-container
.event-slider__slider-container__calender-container {
    // THE FLEXY GRIDS
    .react-datepicker {
        font-family: $body-font-family;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        font-size: 16px;
        border-radius: 0;
        border-color: $color-gu-fade-20;
        border-top: none;
    }
    .react-datepicker__month-container {
        float: none;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .react-datepicker__header {
        background: transparent;
        border: none;
        padding: 0;
    }
    .react-datepicker__month {
        margin: 0;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-bottom: 3vw;

        @include mix-content-min-width($breakpoint-w-elephant) {
            padding-bottom: $gutter;
        }
    }
    .react-datepicker__day-names {
        padding-top: 3vw;

        @include mix-content-min-width($breakpoint-w-elephant) {
            padding-top: $gutter;
        }
    }
    .react-datepicker__day-names,
    .react-datepicker__week {
        display: flex;
        justify-content: space-between;
        padding-left: 3vw;
        padding-right: 3vw;

        @include mix-content-min-width($breakpoint-w-elephant) {
            padding-left: $gutter;
            padding-right: $gutter;
        }
    }
    .react-datepicker__day-name,
    .react-datepicker__day {
        width: 2rem;
        line-height: 2rem;
        margin: 0;
    }

    // THE COLORING
    .react-datepicker__day {
        color: $color-gu-fade-23;

        &:hover {
            border-radius: 50%;
            background: $lightgrey;
        }
    }
    .react-datepicker__day--today {
        font-weight: $fontweight-gu-bold;

        &.react-datepicker__day--keyboard-selected {
            color: white;
            @include themer($theme-gyldendal) {
                color: $dark-grey;
            }
        }

        &.react-datepicker__day--selected {
            color: white !important;
            @include themer($theme-gyldendal) {
                color: $dark-grey !important;
            }
            @include mix-theme-calendar-color-props("background");
        }
    }
    .react-datepicker__day--highlighted {
        position: relative;
        background-color: transparent;
        color: $color-gu-fade-23;

        &:after {
            content: "";
            width: 5px;
            height: 5px;
            border-radius: 50%;
            @include mix-theme-calendar-color-props("background");
            position: absolute;
            bottom: -2px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
        &.react-datepicker__day--selected {
            &:after {
                bottom: -7px;
            }
        }
    }
    .react-datepicker__day--outside-month {
        color: $color-gu-fade-20;
    }
    .react-datepicker__day--selected {
        color: white;
        @include themer($theme-gyldendal) {
            color: $dark-grey;
        }
        font-weight: $fontweight-gu-bold;
        @include mix-theme-calendar-color-props("background");
        border-radius: 50%;

        &:hover {
            @include mix-theme-calendar-color-props("background");
        }
    }

    // THE NAVIGATION
    .react-datepicker__current-month {
        padding: 15px 0;
        border-bottom: 1px solid $color-gu-fade-20;
    }
    .react-datepicker__navigation {
        border-color: transparent;
        top: 20px;
        font-size: 20px;

        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 0.4em;
            height: 2px;
            @include mix-theme-calendar-color-props("background");
            transform-origin: 90% 50%;
        }
    }
    .react-datepicker__navigation--previous {
        &:before,
        &:after {
            left: -5px;
        }
        &:after {
            transform: rotate(-125deg);
        }
        &:before {
            transform: rotate(125deg);
        }
    }
    .react-datepicker__navigation--next {
        &:before,
        &:after {
            right: 1px;
        }
        &:after {
            transform: rotate(-55deg);
        }
        &:before {
            transform: rotate(55deg);
        }
    }
}
