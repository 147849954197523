@use "sass:math";

.on-page-navigation {
    max-width: $max-width;
    margin: 0 auto $gutter;

    @include mix-content-min-width($breakpoint-w-dog) {
        padding: 0 $gutter * 0.6;
    }

    a {
        @include mix-button($lightgrey);
        @include mix-theme-regular-bodyfont();
        @include mix-theme-link-color();
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        text-transform: none;
        border: 1px solid $color-gu-fade-20;
        margin: 0 $gutter * 0.3 math.div($gutter, 2);
    }
}
