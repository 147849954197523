.header-search {
    position: static;

    @media (min-width: $breakpoint-header) {
        position: relative;

        &.keepOpen {
            border-left: none;
            border-right: none;
        }
    }
    @media (max-width: $breakpoint-header) {
        border-left: none !important;

        &.isOpen {
            z-index: 99999;
            border-right: none;
        }
    }
}

.header-search__button {
    top: 0;
    left: 0;
    border: 0;
    margin: 0;
    padding: 0;
    text-align: center;
    overflow: hidden;
    line-height: 0;
    display: block;
    visibility: visible;
    z-index: 5;
    width: 100%;
    height: 100%;

    @include themer($theme-gyldendal) {
        background-color: $color-gu-dark-grey;
    }
    @include themer($theme-hansreitzel) {
        background-color: $color-hr-red;
    }
    @include themer($theme-munksgaard) {
        background-color: $color-mg-blue;
    }
    .isOpen & {
        width: 50px;
        height: 50px;
    }
    @media (min-width: $breakpoint-header) {
        width: 50px;
        height: 50px;
        position: absolute;
        .isOpen & {
            width: 50px;
            height: 50px;
        }
    }
}

.header-search__content {
    width: 100%;
    overflow: hidden;

    @media (max-width: $breakpoint-header) {
        position: relative;
        left: 0;

        .isOpen & {
            position: absolute;
            width: 100vw;
            left: 0;
        }
    }

    @media (min-width: $breakpoint-header) {
        position: relative;
        width: 50px;
        height: 50px;
        z-index: 1;

        .isOpen & {
            width: 360px;
        }
        .keepOpen & {
            width: 310px;
        }
    }
}

.header-search__content__input {
    height: 50px;
    font-size: 16px;
    padding: 0 1em;
    line-height: 50px;
    border-radius: 0;
    border: none;
    width: calc(100% - 50px);
    font-family: inherit;

    @media (max-width: $breakpoint-navigation) {
        outline: none;
        &:focus {
            outline: none;
        }
    }

    color: #fff;
    @include themer($theme-gyldendal) {
        background-color: lighten($color-gu-dark-grey, 10);
    }
    @include themer($theme-hansreitzel) {
        background-color: lighten($color-hr-red, 10);
    }
    @include themer($theme-munksgaard) {
        background-color: lighten($color-mg-blue, 10);
    }

    &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #fff;
    }
    &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #fff;
    }
    &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #fff;
    }
    &:-ms-input-placeholder {
        /* Internet Explorer 1.5+ */
        color: #fff;
    }

    &:focus {
        &::-webkit-input-placeholder {
            /* WebKit browsers */
            opacity: 0.5;
        }
        &:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            opacity: 0.5;
        }
        &::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            opacity: 0.5;
        }
        &:-ms-input-placeholder {
            /* Internet Explorer 1.5+ */
            // IE hides placeholder on focus!!
        }
    }
}

.header-search__content__button {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    text-align: center;
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0;
    margin: 0;
    padding: 0;
    line-height: 0;

    .isOpen & {
        border-left: 1px solid rgba(255, 255, 255, 0.2);
    }

    @include themer($theme-gyldendal) {
        background-color: $color-gu-dark-grey;

        .isOpen & {
            background-color: lighten($color-gu-dark-grey, 10);
        }
    }
    @include themer($theme-hansreitzel) {
        background-color: $color-hr-red;

        .isOpen & {
            background-color: lighten($color-hr-red, 10);
        }
    }
    @include themer($theme-munksgaard) {
        background-color: $color-mg-blue;

        .isOpen & {
            background-color: lighten($color-mg-blue, 10);
        }
    }
}

.header-search__content__form {
    position: absolute;
    top: 0;
    left: 50px;
    width: 0%;
    overflow: hidden;

    .isOpen & {
        width: calc(100% - 50px);
    }

    .keepOpen & {
        width: 100%;
    }

    @media (min-width: $breakpoint-header) {
        height: 50px;
        left: 50px;
        right: 0;
        width: 5%;

        .isOpen & {
            width: calc(100% - 50px);
        }

        .keepOpen & {
            left: 0;
            width: 100%;
        }
    }
}
