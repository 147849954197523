.tag {
    @include mix-tags--secondary();

    @include themer($theme-gyldendal){
        [data-article-area="subtheme-grundskolen"] & {
            background: $color-gu-green;
        }
        [data-article-area="subtheme-gymnasier"] & {
            background: $color-gu-blue-light;
        }
        [data-article-area="subtheme-voksenuddannelse"] & {
            background: $color-gu-pink;
        }
        [data-article-area="subtheme-ordboeger"] & {
            background: $color-gu-red;
        }
        [data-article-area="subtheme-erhvervsuddannelse"] & {
            background: $color-gu-purple;
        }
    }
}