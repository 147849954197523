.profile-navigation{
    margin-bottom: 1em;
}

.profile-navigation__list{
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: auto;

    li{
        margin-right:1em;
        float: left;
    }
    li.current{
        a{
            @include mix-button--primary();
        }
    }
}