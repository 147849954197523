.links-tabs {
    margin-top: 2.5rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid $color-gu-fade-20;
    white-space: nowrap;
    overflow-x: auto;
    @include hide-scrollbars;

    @include mix-content-max-width($breakpoint-w-dog) {
        border: none;
        margin-top: 1.5rem;
    }

    ul {
        list-style-type: none;
    }

    li {
        @include mix-theme-bold-bodyfont;
        display: inline-block;
        margin-right: 2rem;
        color: $color-gu-dark-grey;
        position: relative;
        transition: 0.3s;
        transition-property: color;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 1rem;
        line-height: 1.5;

        &:last-of-type {
            margin-right: 0;
        }

        &:hover,
        &.isActive {
            @include mix-theme-link-color;
            &::after {
                content: "";
                display: block;
                position: absolute;
                bottom: -8px;
                height: 1px;
                width: 100%;
                @include mix-theme-primary-color-props("background-color");
            }
        }

        .links-tab__link {
            color: inherit;
        }

        @include mix-content-max-width($breakpoint-w-dog) {
            color: $dark-grey;
            padding: 0.6rem 1.6rem;
            border: 1px solid tint($dark-grey, 90);
            border-radius: $border-radius;
            line-height: 1rem;
            margin-right: 0.5rem;
            &:hover,
            &.isActive {
                @include mix-subtheme-border(1px, solid);
                @include mix-theme-primary-background-color-tint(90);

                &:after {
                    height: 8px;
                    width: 8px;
                    transform: rotate(-45deg);
                    border-radius: 1.5px;
                    z-index: -1;
                    right: 50%;
                    bottom: -4px;
                }
            }
        }
    }
}
