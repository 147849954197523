//button reset;
button {
    border-radius: 0px;
}

@mixin mix-button($bg: $color-gu-green, $text: $white) {
    @if ($bg == null) {
        $bg: $color-gu-green;
    }
    @if ($text == null) {
        $text: $white;
    }
    //reset
    //outline:none;
    margin: 0;
    box-shadow: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-appearance: none;
    cursor: pointer;
    display: inline-block;
    text-overflow: ellipsis;
    border: 2px solid $bg;
    min-height: 3.125rem;
    height: auto;
    line-height: 1.3em;
    padding: 0.8em 1.3em;
    color: $text;
    font-size: 1rem;
    font-family: $body-font-family;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    background-color: $bg;
    transition: background-color 0.3s $easeInOutCubic;

    @media (hover: hover) {
        &:hover {
            background-color: darken($bg, 4);
            border-color: darken($bg, 4);
        }
    }

    &:focus-visible,
    &:focus-within {
        background-color: darken($bg, 4);
        border-color: darken($bg, 4);
    }

    .loader span,
    .loader:before,
    .loader:after {
        background-color: $text;
    }

    border-radius: $border-radius;

    &:disabled {
        opacity: 0.7;
        cursor: inherit;
    }
}

@mixin mix-button-is-slim {
    text-overflow: ellipsis;
    min-height: 2.5rem !important;
    height: auto;
    padding: 0.5em 1.3em !important;
}

@mixin mix-button-is-fluid {
    display: block;
    width: 100%;
}

@mixin mix-button-hollow($bg: $color-gu-dark-grey, $text: $color-gu-dark-grey) {
    @include mix-button(transparent, $text);
    border: 2px solid $bg;

    @media (hover: hover) {
        &:hover {
            border: 2px solid $bg;
        }
    }
}

@mixin mix-button--primary() {
    @include themer($theme-gyldendal) {
        @include mix-button($color-gu-green, $white);
    }

    @include themer($theme-hansreitzel) {
        @include mix-button($color-hr-red, $white);
    }

    @include themer($theme-munksgaard) {
        @include mix-button($color-cta, $white);
    }
}

@mixin mix-button--secondary() {
    @include mix-button($color-gu-dark-grey);

    @include themer($theme-hansreitzel) {
        @include mix-button($color-hr-blue);
    }

    @include themer($theme-munksgaard) {
        @include mix-button($color-mg-blue-dusty);
    }
}

@mixin mix-button-hollow--secondary {
    @include mix-button-hollow($color-gu-dark-grey, $color-gu-dark-grey);

    @include themer($theme-hansreitzel) {
        @include mix-button-hollow($color-hr-blue, $color-hr-blue);
    }

    @include themer($theme-munksgaard) {
        @include mix-button-hollow($color-mg-blue-dusty, $color-mg-blue-dusty);
    }
}

@mixin mix-button-submit {
    overflow: hidden;

    > span {
        display: block;
        transition: all 250ms $easeInOutQuart;
    }

    &.button-is-loading {
        > span {
            opacity: 0;
            transform: scale(0.95);
        }
    }

    .loader {
        span,
        &:before,
        &:after {
            background-color: currentColor !important;
        }
    }
}

@mixin mix-button-buy {
    @include themer($theme-gyldendal) {
        @include mix-button($color-gu-green, $white);
    }
    @include themer($theme-hansreitzel) {
        @include mix-button($color-hr-red, #fff);
    }
    @include themer($theme-munksgaard) {
        @include mix-button($color-cta, white);
    }
}

@mixin mix-button-login {
    @include themer($theme-gyldendal) {
        @include mix-button($color-gu-green, #fff);
    }
    @include themer($theme-hansreitzel) {
        @include mix-button($color-hr-green, #fff);
    }
    @include themer($theme-munksgaard) {
        @include mix-button($color-mg-green-dark, $color-mg-blue);
    }
}

.button {
    @include themer($theme-gyldendal) {
        @include mix-button($color-gu-green, $white);
    }
    @include themer($theme-hansreitzel) {
        @include mix-button($color-hr-red, #fff);
    }
    @include themer($theme-munksgaard) {
        @include mix-button($color-cta, white);
    }
}

.button-secondary {
    @include mix-button--secondary();
}

.button-hollow {
    @include mix-button-hollow($color-gu-dark-grey, $color-gu-dark-grey);
}

.button-login {
    @include mix-button-login();
}

.button-load-more {
    @include mix-button-hollow--secondary();
    @include mix-button-submit();
    display: block;
    margin: 0 auto;
}

.button--slim {
    @include mix-button-is-slim();
}

.button--wide {
    width: 100%;
}

.button-close-block {
    position: relative;
    width: 20px;
    height: 20px;
    border: none;
    overflow: hidden;
    text-indent: -999em;
    background-color: transparent;
    cursor: pointer;

    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 15px;
        height: 2px;
        background-color: #fff;
    }
    &:before {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:after {
        transform: translate(-50%, -50%) rotate(45deg);
    }
}

.button--top-right {
    position: absolute;
    top: 13px;
    right: 13px;
}

@mixin button-reset() {
    appearance: none;
    border-radius: 0;
    font-family: inherit;
    font-size: inherit;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: inherit;
}

.button-reset {
    @include button-reset;
}

.button--flex {
    display: inline-flex;
    gap: 0.875rem;
    align-items: center;
    justify-content: center;
    text-align: left;
}
.button--has-icon svg {
    height: 1.3em;
    flex: none;
    width: auto;
}
