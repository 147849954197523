.create-profile-institution {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem 1rem;

    @include mix-content-min-width($breakpoint-w-cat) {
        grid-template-columns: 0.5fr;
    }
}
.create-profile-institution__header {
    font-size: rem(16);
    text-transform: uppercase;
    margin-bottom: rem(16);
    color: $black;
}

.create-profile-institution__contact-customersupport {
    font-size: 0.75rem;
    line-height: 1.33;
    color: $black;
    margin-top: 1rem;
}

.create-profile-institution__error {
    color: $color-error;
}
