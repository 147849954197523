@use "sass:math";

.price-box-list-item {
    padding: 0 $gutter-small;
    margin-bottom: $gutter-small * 2;
    display: flex;
    margin: 0;

    @include mix-content-min-width($breakpoint-w-rabbit) {
        width: 50%;
        margin-bottom: $gutter;
        padding-left: math.div($gutter, 2);
        padding-right: math.div($gutter, 2);
    }
    @include mix-content-min-width($breakpoint-w-cat) {
        padding-left: $gutter;
        padding-right: $gutter;
    }
    @include mix-content-min-width($breakpoint-w-lion) {
        width: 33.3333333%;
    }
    .price-box-list--hasHighlightedItem & {
        @include mix-content-min-width($breakpoint-w-rabbit) {
            padding-top: 36px;
            margin-bottom: 0;
        }
    }
    .custom-primary-cta-button {
        display: inline-block;
        width: 100%;

        a {
            width: 100%;
        }
    }
}

.price-box-list-item__inner {
    flex: 1;
    max-width: 100%;
    padding: 4px;
    position: relative;
    @include mix-content-min-width($breakpoint-w-rabbit) {
        display: flex;
    }

    @include themer($theme-gyldendal) {
        background-color: $color-gu-fade-23;
        border-radius: 5px;
    }
    @include themer($theme-hansreitzel) {
        background-color: $color-hr-blue;
    }
    @include themer($theme-munksgaard) {
        background-color: $color-mg-blue-light;
    }
}

.price-box-list-item__content {
    flex-grow: 1;
    flex-basis: 0;
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    background-color: $white;
    overflow: hidden;

    @include mix-theme-headline-color;
    @include themer($theme-gyldendal) {
        border-radius: 3px;
    }
}

.price-box-list-item__highlighted-title {
    width: 100%;
    padding: 0 15px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include themer($theme-gyldendal) {
        background-color: $color-gu-fade-23;
        font-weight: $fontweight-gu-bold;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        color: $white;
    }
    @include themer($theme-hansreitzel) {
        font-weight: $fontweight-hr-din-bold;
        background-color: $color-hr-blue;
        color: $white;
    }
    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-bold;
        background-color: $color-mg-blue-light;
        color: $color-mg-blue;
    }
    @include mix-content-min-width($breakpoint-w-rabbit) {
        position: absolute;
        top: -36px;
        left: 0;
    }
}

.price-box-list-item__title {
    width: 100%;
    padding: 10px;
    display: block;
    font-size: 1rem;
    @include mix-boxes__background;
    @include mix-boxes__border;
    border-top: none;
    border-right: none;
    border-left: none;
    @include themer($theme-gyldendal) {
        font-weight: $fontweight-gu-bold;
    }
    @include themer($theme-hansreitzel) {
        font-weight: $fontweight-hr-din-bold;
    }
    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-bold;
    }
}

.price-box-list-item__price {
    width: 100%;
    padding: 20px;

    @include themer($theme-gyldendal) {
        font-weight: $fontweight-gu-bold;
    }
    @include themer($theme-hansreitzel) {
        font-weight: $fontweight-hr-din-bold;
    }
    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-bold;
    }
}

.price-box-list-item__price-prefix {
    display: inline-block;
    margin-right: 5px;
}

.price-box-list-item__price-value {
    display: inline-block;
    font-size: 3rem;
    line-height: 1;
}

.price-box-list-item__price-unit {
    display: block;
}

.price-box-list-item__info {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 15px 15px;
    position: relative;
    z-index: 1;
    @include mix-boxes__border;
    @include mix-boxes__background;
    border-right: none;
    border-bottom: none;
    border-left: none;

    &::before,
    &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border: solid transparent;
        left: 50%;
        bottom: 100%;
        transform: translateX(-50%);
    }
    &::before {
        border-width: 8px;
        @include themer($theme-gyldendal) {
            border-bottom-color: $color-gu-fade-20;
        }
        @include themer($theme-hansreitzel) {
            border-bottom-color: shade($color-hr-blue-light, 6);
        }
        @include themer($theme-munksgaard) {
            border-bottom-color: $color-mg-fade-40;
        }
    }
    &::after {
        border-width: 6px;
        @include themer($theme-gyldendal) {
            border-bottom-color: $lightgrey;
        }
        @include themer($theme-hansreitzel) {
            border-bottom-color: $color-hr-blue-light;
        }
        @include themer($theme-munksgaard) {
            border-bottom-color: $color-mg-fade-30;
        }
    }
}

.price-box-list-item__info-text {
    flex-grow: 1;
    font-size: 0.9em;
    margin-bottom: 20px;
    @include mix-theme-label-color;

    @include themer($theme-hansreitzel) {
        color: $color-hr-blue;
    }
    @include themer($theme-munksgaard) {
        color: $color-mg-blue;
    }
}
