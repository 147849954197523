@mixin mix-video-button{
    position: relative;
    display: block;
    font-size: 12px;

    @media (min-width: $breakpoint-w-cat) {
        font-size: 20px;
    }

    img{
        width:100% !important;
        height: auto!important;
        margin-top: 0 !important;
        margin-bottom:0 !important;
        display: block !important;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 3.5em;
        height: 3.5em;
        z-index: 1;
        background: $color-gu-orange;

        @include themer($theme-gyldendal){
            border-top-left-radius: 5px;
        }

        @include themer($theme-hansreitzel) {
            background: $color-hr-red;
            border-top-left-radius: 2px;
        }
        @include themer($theme-munksgaard) {
            background: $color-mg-green-dark;
            border-top-left-radius: 2px;
        }
    }
    // play icon
    &:after {
        content: '';
        position: absolute;
        bottom: 0.8em;
        right: 0.8em;
        border-top: 0.9em solid transparent;
        border-bottom: 0.9em solid transparent;
        border-left: 1.5em solid #fff;
        z-index: 2;

        @include themer($theme-munksgaard) {
            border-left: 1.5em solid $color-mg-blue;
        }
    }
}