.product__img--fallback {
    .product-page-container & {
        max-width: 70%;
        margin: auto;
    }

    svg {
        @include mix-shadow-product-img();
        border: 1px solid $lightgrey;
        height: auto;
        width: 100%;

        .product-slider__product-item & {
            max-width: 80%;
        }

        &.icon-logo-gu {
            padding: 20px;

            .product-page-container & {
                padding: 30px 35px 40px;
            }

            path {
                fill: $color-gu-fade-10;
            }
        }
        @include themer($theme-hansreitzel) {
            &.icon-logo-hr {
                padding: 10px 10px 20px;

                .product-page-container & {
                    padding: 25px 25px 55px;
                }

                .basket-list & {
                    padding: 7px 7px 20px;
                }

                rect {
                    fill: white;
                }
                path {
                    fill: $color-hr-blue;
                    fill-opacity: .2;
                }
            }
        }

        @include themer($theme-munksgaard) {
            &.icon-logo-mg {
                padding: 35px 20px;

                .product-page-container & {
                    padding: 60px 40px 75px;
                }

                .basket-list & {
                    padding: 25px 17px;
                }

                fill: $color-mg-blue;
                fill-opacity: .2;
            }
        }
    }
}