.error-500{
    .content-header{
        margin-bottom:$gutter;
    }
}

.error-500__image{
    max-width: 820px;
    margin: 0 auto;
    padding-bottom: 25%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-image: url("/dist/assets/gfx/500_all.png");
    @media (min-width: $breakpoint-w-rhino) {
        background-size: auto 280px;
        padding-bottom: 280px;
    }
}


.error-500__logo{
    margin-top: $gutter*2;
    margin-bottom: $gutter*2;
    img{
        max-width: 200px;
        margin: 0 auto;
    }
    @media (min-width: $breakpoint-navigation) {

    }
}