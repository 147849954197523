.color-link-list {
    .content-row {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        justify-content: center;
        @include mix-content-max-width($breakpoint-w-wolf) {
            grid-template-columns: 1fr;

            .color-link-list__item {
                width: 100%;
                grid-column: span 1 !important;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

.color-link-list__item {
    padding: 1rem;
    max-width: 480px;
    grid-column: span 2;

    &.color-link-list__item-centered {
        grid-column: 3 / span 2;
    }
    &.color-link-list__item-two-first {
        grid-column: 2 / span 2;
    }
    &.color-link-list__item-two-last {
        grid-column: 4 / span 2;
    }
}

.color-link-list__item-inner {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: $white;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05), 0 0 24px rgba(0, 0, 0, 0.15);
    overflow: hidden;

    .color-link-list__richText {
        color: #000;
    }

    .color-link-list__arrow {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
    }

    .color-link-list__background-color {
        position: absolute;
        top: 0;
        left: 0;
        width: 15px;
        height: 100%;
    }

    ul {
        :first-child {
            &::before {
                display: none;
            }
        }
        li {
            &::before {
                content: "\2022";
                margin: 3px;
            }
        }
    }

    h1 {
        margin-bottom: 15px;
    }

    h1,
    li {
        color: #000;
    }

    li {
        display: inline-block;
    }
}
