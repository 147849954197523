@use "sass:math";

.product-review-block {
    @include themer($theme-gyldendal) {
        color: $dark-grey;
    }
}

.product-review-block__header {
    @include mix-theme-like-h2();
    @include mix-grid-section();
    margin-bottom: $gutter;
    text-align: center;

    @include themer($theme-gyldendal) {
        color: white;
    }
}

.product-review-block__slider {
    .slick-track {
        display: flex;
    }

    // To even the item-hights
    .slick-initialized .slick-slide,
    .slick-slide {
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: center;
    }
}

.product-review__item {
    text-align: center;
    padding: 0 $gutter-small;
    margin: 0 auto;

    @include mix-content-min-width($breakpoint-w-cat) {
        padding: 0 $gutter;
    }
    @include mix-content-min-width($breakpoint-w-lion) {
        max-width: 50vw;
    }
    @include mix-content-min-width($breakpoint-w-elephant) {
        max-width: math.div($max-width, 2);
    }
}

.product-review__item__body {
    position: relative;
    padding: 2em 1.5em;
    color: $dark-grey;
    font-size: 1rem;

    @include mix-content-min-width($breakpoint-w-cat) {
        padding: 2em 3em;
    }
    @include mix-content-min-width($breakpoint-w-lion) {
        padding: 2.5em 4em;
    }

    &:before,
    &:after {
        content: "“";
        position: absolute;
        font-weight: $fontweight-gu-bold;
        z-index: -1;
        font-family: $font-family-gotham;
        line-height: 0.75em;
        font-size: 10rem; //rem for IE11
        opacity: 0.1;

        @include mix-content-min-width($breakpoint-w-lion) {
            font-size: 12rem;
        }

        //GU
        @include themer($theme-gyldendal) {
            color: $color-gu-orange; // Fallback color
            @include mix-subtheme-primary-color-props("color");
        }
        //munk
        @include themer($theme-munksgaard) {
            color: $color-mg-blue;
        }
        //hans
        @include themer($theme-hansreitzel) {
            @include mix-theme-primary-color-props("color");
        }
    }
    &:before {
        top: 0;
        left: 0;
    }
    &:after {
        bottom: 0;
        right: 0;
        transform: rotate(180deg);
    }

    a {
        @include mix-theme-link-color();
    }
}

.product-review__item__author {
    @include mix-theme-medium-bodyfont();
    font-size: 0.875rem;;
    position: relative;
    color: $color-gu-dark-grey;
    
    &:before {
        content: "";
        position: absolute;
        top: -1.25em;
        left: 50%;
        transform: translateX(-50%);
        width: 50px;
        border-top: 1px solid white;
        opacity: 0.2;

        @include themer($theme-munksgaard) {
            border-color: $color-mg-blue;
        }
        @include themer($theme-hansreitzel) {
            border-color: $color-gu-dark-grey;
        }
    }
}

.product-review__item__origin {
    margin-bottom: calc(2.5em - 2px);
    font-size: 0.75rem;
    color: $color-gu-blue-dusty;

    span:nth-child(2) {
        &:before {
            content: "\00b7";
            padding: 0 5px;
        }
    }
}
