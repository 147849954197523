@mixin mix-product-teaser-item--side-by-side() {
    flex-direction: row;
    align-items: center;
    justify-content: initial;
    padding: $gutter;
    margin-bottom: $gutter;
    border-right: 1px solid $color-gu-fade-20;

    &:nth-child(even),
    &:last-child {
        border-width: 0;
    }

    .product__img {
        margin: 0;
    }
    .product__info-wrapper {
        padding-left: $gutter;
        width: 65%;
        flex: 0 0 65%;
    }
    .product__title {
        text-align: left;
    }

    .product__price-button-container {
        flex-wrap: wrap;
    }
    .product__prices {
        width: 100%;
    }
    .btn-container {
        margin-bottom: 0;
    }
}

.product-teaser-item {
    width: 100%;
    padding: $gutter $gutter-small 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mix-content-min-width($breakpoint-w-cat) {
        width: 50%;
        padding: $gutter $gutter 0;
        margin-bottom: $gutter;
        border-right: 1px solid $color-gu-fade-20;

        &:nth-child(even),
        &:last-child {
            border-width: 0;
        }
    }
    @include mix-content-min-width($breakpoint-w-lion) {
        width: 33.33333333%;
        border-width: 1px;

        &:nth-child(even) {
            border-width: 1px;
        }
        &:nth-child(3n),
        &:last-child {
            border-width: 0;
        }
    }

    .product__img {
        width: 35%;
        display: block;
        margin: 0 auto $gutter;
        flex-shrink: 0;

        img {
            width: 100%;
            height: auto;
            display: block;
            @include mix-shadow-product-img();
        }
    }
    .product__info-wrapper {
        @include mix-content-max-width($breakpoint-w-cat) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-grow: 1;
            width: 100%;
        }
    }
    .product__title {
        @include mix-theme-like-h3;
        @include mix-theme-overflow-fade-white;
        text-align: center;
    }

    .product__subtitle {
        @include mix-theme-label-text;
        @include mix-theme-overflow-fade-white;
        min-height: 24px; // To align horizontally...
        margin-bottom: 0.5rem;
    }

    .product__editors,
    .product__authors {
        @include reset__button("true");
        color: inherit;
        @include mix-theme-subline-text;

        // We don't want authors to be styled as linkes here (also not clickable)
        a {
            cursor: text;

            @include themer($theme-munksgaard) {
                color: $color-mg-blue-dusty-light;
            }
            @include themer($theme-hansreitzel) {
                color: $color-hr-red;
            }
        }

        .editor-abbreviation {
            margin-left: 5px;
        }
    }

    .product__price-label,
    .product__price-secondary {
        @include mix-theme-label-text;
    }

    .product__price-label__stock {
        &:before {
            content: "\00b7";
            padding: 0 5px;
            @include mix-theme-paragraph-color;
        }

        &.info-type--info {
            @include mix-theme-notification-text("info");
        }
        &.info-type--alert {
            @include mix-theme-notification-text("alert");
        }
    }
    .product__price-button-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include mix-content-min-width($breakpoint-w-rhino) {
            flex-wrap: nowrap;
        }
    }
    .product__prices {
        font-size: 20px;
        margin-right: 10px;
        margin-bottom: $gutter-small;
        width: 100%;

        @include mix-content-min-width($breakpoint-w-rabbit) {
            width: auto;
        }
        @include mix-content-min-width($breakpoint-w-horse) {
            min-width: 200px; // To make the add-to-basket-btn break the line
        }
        @include mix-content-min-width($breakpoint-w-rhino) {
            min-width: auto;
        }

        &.discounted .product__price-primary {
            @include mix-theme-discounted-old-price;
        }

        .product__price-primary {
            font-weight: 600;
            margin-right: 6px;
            color: black;
            display: inline-block;
        }
        .product__price-discount {
            @include mix-theme-discounted-new-price;
            display: inline-block;
        }
    }

    .btn-container {
        margin-bottom: $gutter;
        width: 100%;

        @include mix-content-min-width($breakpoint-w-rabbit) {
            width: 140px; // Widest btn width to ensure all break evenly
            flex: 0 0 140px;
        }
        @include mix-content-min-width($breakpoint-w-cat) {
            margin-top: 7px;
        }

        @include mix-content-max-width($breakpoint-w-dog) {
            .button-secondary {
                height: 50px;
                line-height: 48px;
                padding: 0 !important;
            }
        }
    }

    .product__variants {
        @include mix-boxes__background;
        @include mix-boxes__border-color;
        border-style: solid;
        border-top-width: 1px;
        text-transform: uppercase;
        padding: 10px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        margin: 0;
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 50px;
            background: rgba(255, 255, 255, 0);
            background: -webkit-linear-gradient(to left, $color-gu-fade-10, rgba(255, 255, 255, 0));
            background: linear-gradient(to left, $color-gu-fade-10, rgba(255, 255, 255, 0));

            @include themer($theme-hansreitzel) {
                background: -webkit-linear-gradient(to left, $color-hr-blue-light, rgba(255, 255, 255, 0));
                background: linear-gradient(to left, $color-hr-blue-light, rgba(255, 255, 255, 0));
            }

            @include themer($theme-munksgaard) {
                background: -webkit-linear-gradient(to left, $color-mg-fade-30, rgba(255, 255, 255, 0));
                background: linear-gradient(to left, $color-mg-fade-30, rgba(255, 255, 255, 0));
            }
        }
    }

    // Side-by-side styling
    @media only screen and (min-width: $breakpoint-w-rabbit) and (max-width: $breakpoint-w-cat) {
        @include mix-product-teaser-item--side-by-side();
        border-right: none;
    }

    &:only-child, // One item
    &:first-child:nth-last-child(2), // Two items
    &:first-child:nth-last-child(2) ~ .product-teaser-item {
        @include mix-content-min-width($breakpoint-w-lion) {
            @include mix-product-teaser-item--side-by-side();
            width: 50%;

            .product__info-wrapper {
                width: 70%;
                flex: 0 0 70%;
            }
        }
        @include mix-content-min-width($breakpoint-w-horse) {
            .product__info-wrapper {
                width: 65%;
                flex: 0 0 65%;
            }
        }
    }
}
