.product-page-container {
    padding: 0 0 50px 0;

    .product-slider-container {
        margin: 0;

        @include mix-content-max-width($breakpoint-w-cat) {
            margin: 0 $gutter;

            .product-slider,
            .product-slider__header-container {
                padding: 0;
            }
        }
    }
}

.product-page__sliders {
    margin-top: 3rem;
}

.product-details-container {
    display: grid;
    max-width: 1440px;
    margin: 0 auto $gutter;
    grid-template-columns: minmax(0, 1fr);
    grid-gap: $gutter;

    @include mix-content-min-width($breakpoint-w-lion) {
        grid-template-columns: 1fr minmax(0, 2fr) 1fr;
        grid-gap: 2rem;
    }

    @include mix-content-min-width($breakpoint-w-rhino) {
        grid-gap: 3.4rem;
    }

    @include mix-content-min-width($breakpoint-w-elephant) {
        grid-gap: 6.875rem;
    }

    .product-left-container {
        padding: 0 $gutter;

        @include mix-content-min-width($breakpoint-w-lion) {
            padding: 0 0 0 $gutter;
        }

        .product__img {
            padding: $gutter-small $gutter $gutter;
            width: 100%;
            min-height: 1px; // fix for IE to ajust the height depending on the scaled img

            @include mix-content-max-width($breakpoint-w-rabbit) {
                min-height: 120px; // fix for LG phones
            }

            img {
                @include mix-shadow-product-img();
                margin: auto;
                display: block;

                @include mix-content-max-width($breakpoint-w-lion) {
                    max-width: 207px;
                    margin: 0 auto;
                }
            }
        }

        .product__media {
            .product__read-test {
                @include mix-content-max-width($breakpoint-w-cat) {
                    display: none;
                }
            }
        }
    }

    .product__meta {
        .product__meta__info {
            @include mix-content-max-width($breakpoint-w-lion) {
                padding: 0 $gutter;
            }

            .product__category {
                @include mix-theme-medium-bodyfont();
                font-size: 0.75rem;
                text-transform: uppercase;
            }

            .product__title {
                color: $dark-grey;
                @include mix-theme-like-h2();
            }

            .product__subtitle {
                @include mix-theme-like-h3();
                margin-bottom: 0.5rem;
                @include themer($theme-hansreitzel) {
                    font-size: rem(18);
                    @include mix-content-max-width($breakpoint-w-cat) {
                        font-size: rem(16);
                    }
                }
            }

            .product__editors,
            .product__authors {
                @include mix-theme-subline-text();

                // We don't want authors to be styled as links
                a {
                    @include themer($theme-munksgaard) {
                        color: $color-mg-blue-dusty-light;
                    }
                    @include themer($theme-hansreitzel) {
                        color: $color-hr-red;
                    }
                }

                .editor-abbreviation {
                    margin-left: 5px;
                }
            }

            .product__series-link {
                font-size: 0.875rem;
                line-height: 0.875rem;
                padding-top: 0.7rem;
                color: $dark-grey;
            }

            .product__series-link a {
                @include mix-theme-like-a;
            }
        }

        .product__subtitle {
            @include mix-theme-manchet-text;
            margin-bottom: 15px;
        }
        .product__product-context a {
            @include mix-theme-like-a;
        }

        .tabs__content {
            margin-bottom: 1.5rem;

            @include mix-content-max-width($breakpoint-w-lion) {
                margin: 0 25px 1.5rem 25px;
            }
        }

        .tabs__menu {
            text-align: left;
            padding-bottom: 1px;
            margin: 1.75rem 0rem 2rem 0rem;

            @include mix-content-max-width($breakpoint-w-lion) {
                margin: 1.25rem 0rem 1.5rem 0rem;
                padding: 0 $gutter;
            }

            .tabs__menu-inner {
                border-bottom: 1px solid $dark-grey-op10;
                padding-bottom: 0.25rem;

                @include mix-content-max-width($breakpoint-w-lion) {
                    border: none;
                    padding-bottom: 0.5rem;
                }

                .tabs__menu__tab {
                    font-size: 0.875rem;
                    padding-bottom: 0;

                    &.active {
                        @include mix-theme-bold-bodyfont();
                        &:after {
                            bottom: -7px;
                        }
                    }

                    @include mix-content-max-width($breakpoint-w-lion) {
                        padding: 0.6rem 1.6rem;
                        font-weight: 500;
                        border: 1px solid tint($dark-grey, 90);
                        border-radius: $border-radius;
                        line-height: 0.875rem;
                        margin-right: 0.5rem;
                        &.active {
                            @include mix-subtheme-border(1px, solid);
                            @include mix-theme-primary-background-color-tint(90);

                            &:after {
                                height: 8px;
                                width: 8px;
                                transform: rotate(-45deg);
                                border-radius: 1.5px;
                                z-index: -1;
                                right: 50%;
                                bottom: -4px;
                            }
                        }

                        &:last-of-type {
                            margin-right: $gutter;
                        }
                    }
                }
            }
        }

        .product-details__description {
            color: $dark-grey;
        }
    }

    .buy-container {
        padding: 0 $gutter;

        @include mix-content-min-width($breakpoint-w-lion) {
            padding: 0 $gutter 0 0;
        }
    }

    .product__variants {
        order: 4;
        padding: 0 $gutter-small 30px;

        @include mix-content-min-width($breakpoint-w-cat) {
            padding: 0 $gutter 30px;
        }

        @include mix-content-min-width($breakpoint-w-lion) {
            display: none;
        }
    }
}

.product-details__contributors-container {
    margin-top: 15px;
    margin-bottom: 20px;

    //gu
    @include themer($theme-gyldendal) {
        color: $color-gu-fade-25;
        font-weight: normal;
    }
    //munk
    @include themer($theme-munksgaard) {
        color: $color-mg-blue-dusty-light;
        font-weight: $fontweight-mg-regular;
    }
    //hans
    @include themer($theme-hansreitzel) {
        color: $color-hr-red;
        font-weight: $fontweight-hr-regular;
    }
}

.product-details__authors-wrapper {
    :not(.isOpen) & {
        @include mix-theme-subline-text();
    }
    .isOpen & {
        // To remove subline-text styling on Hans Reitzel
        &:before {
            border-color: transparent;
        }
        @include mix-theme-authors-wrapper();
    }
}

.product-details__editors-wrapper {
    @include mix-theme-subline-text();

    .isOpen & {
        // To remove subline-text styling on Hans Reitzel
        &:before {
            border-color: transparent;
        }
        @include mix-theme-editors-wrapper();
    }
}

.product-details__authors a,
.product-details__editors a {
    text-decoration: none;

    @include themer($theme-munksgaard) {
        color: $color-mg-blue-dusty-light;
    }
    @include themer($theme-hansreitzel) {
        color: $color-hr-red;
    }

    &:hover {
        @include mix-theme-like-a();
    }
}

.product-details__editor-abbreviation {
    margin-left: 5px;
}

.more-contributors-btn,
.less-contributors-btn {
    @include reset__button("true");
    margin-left: 10px;
    white-space: nowrap;
    @include mix-theme-like-a;
}

.facts-tab-container {
    .facts-tab-content {
        font-size: 1rem;
        color: $dark-grey;

        a {
            @include mix-theme-like-a;
            &:hover {
                text-decoration: underline;
            }
        }

        .facts-tab__facts-list {
            list-style: none;
            .label {
                @include mix-theme-medium-bodyfont();
                padding-right: 20px;
                min-width: 110px;
                display: inline-block;
                @include mix-content-min-width($breakpoint-w-cat) {
                    min-width: 230px;
                }
            }
            .value {
                color: $dark-grey;
            }
        }
    }
}

.free-materials-tab-container {
    .free-materials-tab-content {
        .free-materials__group {
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;

            a {
                color: $dark-grey;
                display: flex;
                margin-bottom: 20px;

                &:last-of-type {
                    margin-bottom: 0px;
                }

                &:hover {
                    text-decoration: underline;
                }

                svg {
                    @include mix-theme-link-color();

                    &.icon-website {
                        path {
                            stroke: $color-gu-orange;
                        }
                    }
                }

                span {
                    font-size: 0.875rem;
                }
            }
        }

        .free-materials__group-title__icon {
            width: 35px;
            height: 1.2em;
            position: absolute;
            top: 0.1em;
            left: 0;
        }

        .free-materials__group-title__text {
            @include mix-theme-bold-bodyfont();
            margin-bottom: 0.5rem;
            color: $dark-grey;
        }

        svg {
            margin-right: 1rem;
        }
    }
}

.product__variants__label {
    display: inline-block;
    font-size: 12px;
    text-transform: uppercase;
    margin-right: 6px;

    @include themer($theme-gyldendal) {
        font-weight: $fontweight-gu-bold;
    }
    @include themer($theme-hansreitzel) {
        color: $color-hr-blue;
        font-weight: $fontweight-hr-din-bold;
    }
    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-bold;
    }
}

.product__variants__tags {
    display: inline-block;
    list-style: none;
}

.product__variants__tag {
    @include mix-tags--secondary;
    margin-left: 2px;
    margin-bottom: 0;
}
