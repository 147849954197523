.searchpage {
    .tabs__menu-inner {
        padding-left: 0.625rem;

        .tabs__menu__tab:last-of-type {
            margin-right: 0.625rem;
        }
    }

    .product-list__item {
        .product-variants {
            margin-top: 2rem;
        }

        .add-to-basket {
            justify-content: end;
            margin: 0 1rem 1rem;

            .add-to-basket__button {
                border-radius: $border-radius;
                overflow: visible;
            }
        }
    }
}
