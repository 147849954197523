.profile-my-orders-list {
    display: flex;
    flex-direction: column;
    .loader {
        margin: 0 auto;
    }
}

.profile-my-orders-list__no-orders {
    text-align: center;
}

.profile-my-orders-list__pagination {
    padding: 1rem;
    display: flex;
    justify-content: center;
}
