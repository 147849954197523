.page-intro-container {
    @include mix-grid-section();
}
.page-intro__trumpet {
    @include mix-theme-trumpet();
    @include mix-subtheme-primary-color;
}
.page-intro__header {
    @include mix-theme-like-h2();
}
.page-intro__paragraph {
    @include mix-content-min-width($breakpoint-w-lion) {
        width: 80%;
    }
}
