.searchpage-pagination{
    text-align: center;
    @include mix-clearfix-block();
    margin-bottom: $gutter*1.5;
    line-height: $gutter*2;
    position: relative;
}
.searchpage-pagination__label{
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    font-weight: $fontweight-gu-bold;
    @include mix-theme-bold-bodyfont();
}

.searchpage-pagination__prev{
    float: left;
    position:relative;
    z-index: 2;
}

.searchpage-pagination__next{
    float: right;
    position:relative;
    z-index: 2;
}