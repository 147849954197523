@mixin mix-theme-headline-color {
    color: $black;

    @include themer($theme-munksgaard) {
        color: $color-mg-blue;
    }
}

@mixin mix-theme-paragraph-color {
    color: $color-gu-dark-grey;

    @include themer($theme-munksgaard) {
        color: $color-mg-blue-dusty;
    }
}

@mixin mix-theme-intro-paragraph-color {
    color: tint($color-gu-dark-grey, 15);

    @include themer($theme-munksgaard) {
        color: tint($color-mg-blue-dusty, 10);
    }
}

@mixin mix-theme-label-color {
    color: $color-gu-fade-23;

    @include themer($theme-hansreitzel) {
        color: $color-hr-blue;
    }

    @include themer($theme-munksgaard) {
        color: $color-mg-blue-dusty;
    }
}
@mixin mix-theme-link-color() {
    @include themer($theme-gyldendal) {
        color: $color-gu-orange-dark;
        @include mix-subtheme-primary-color-props("color");
    }

    @include themer($theme-hansreitzel) {
        color: $color-hr-red;
    }

    @include themer($theme-munksgaard) {
        color: $color-cta;
    }
}

@mixin mix-subtheme-primary-color() {
    @include mix-subtheme-primary-color-props("color");
}

@mixin mix-subtheme-border($border-width, $border-style) {
    @include themer($theme-gyldendal) {
        border: $border-width $border-style $color-gu-orange;

        @include themer-sub($subtheme-grundskolen) {
            border: $border-width $border-style $color-gu-green;
        }
        @include themer-sub($subtheme-gymnasier) {
            border: $border-width $border-style $color-gu-blue-light;
        }
        @include themer-sub($subtheme-voksenuddannelse) {
            border: $border-width $border-style $color-gu-pink;
        }
        @include themer-sub($subtheme-ordboeger) {
            border: $border-width $border-style $color-gu-red;
        }
        @include themer-sub($subtheme-erhvervsuddannelse) {
            border: $border-width $border-style $color-gu-purple;
        }
    }

    @include themer($theme-hansreitzel) {
        border: $border-width $border-style $color-hr-red;
    }

    @include themer($theme-munksgaard) {
        border: $border-width $border-style $color-mg-petroleum-blue;
    }
}

@mixin mix-primary-theme-border($border-width, $border-style) {
    @include themer($theme-gyldendal) {
        border: $border-width $border-style $color-gu-orange;
    }

    @include themer($theme-hansreitzel) {
        border: $border-width $border-style $color-hr-red;
    }

    @include themer($theme-munksgaard) {
        border: $border-width $border-style $color-mg-petroleum-blue;
    }
}

@mixin mix-theme-primary-background-color-tint($tint) {
    @include themer($theme-gyldendal) {
        background-color: tint($color-gu-orange, $tint);

        @include themer-sub($subtheme-grundskolen) {
            background-color: tint($color-gu-green, $tint);
        }
        @include themer-sub($subtheme-gymnasier) {
            background-color: tint($color-gu-blue-light, $tint);
        }
        @include themer-sub($subtheme-voksenuddannelse) {
            background-color: tint($color-gu-pink, $tint);
        }
        @include themer-sub($subtheme-ordboeger) {
            background-color: tint($color-gu-red, $tint);
        }
        @include themer-sub($subtheme-erhvervsuddannelse) {
            background-color: tint($color-gu-purple, $tint);
        }
    }

    @include themer($theme-hansreitzel) {
        background-color: tint($color-hr-red, $tint);
    }

    @include themer($theme-munksgaard) {
        background-color: tint($color-mg-petroleum-blue, $tint);
    }
}

@mixin mix-theme-primary-color-props($props...) {
    @include themer($theme-gyldendal) {
        @each $prop in $props {
            #{$prop}: $color-gu-orange-dark;
        }
    }

    @include themer($theme-hansreitzel) {
        @each $prop in $props {
            #{$prop}: $color-hr-red;
        }
    }

    @include themer($theme-munksgaard) {
        @each $prop in $props {
            #{$prop}: $color-cta;
        }
    }
}

@mixin mix-subtheme-primary-color-props($props...) {
    @include themer-sub($subtheme-grundskolen) {
        @each $prop in $props {
            #{$prop}: $color-gu-green;
        }
    }
    @include themer-sub($subtheme-gymnasier) {
        @each $prop in $props {
            #{$prop}: $color-gu-blue-light;
        }
    }
    @include themer-sub($subtheme-voksenuddannelse) {
        @each $prop in $props {
            #{$prop}: $color-gu-pink;
        }
    }
    @include themer-sub($subtheme-ordboeger) {
        @each $prop in $props {
            #{$prop}: $color-gu-red;
        }
    }
    @include themer-sub($subtheme-erhvervsuddannelse) {
        @each $prop in $props {
            #{$prop}: $color-gu-purple;
        }
    }
}

@mixin mix-theme-calendar-color-props($props...) {
    @include themer($theme-gyldendal) {
        @each $prop in $props {
            #{$prop}: $color-gu-orange;
        }
    }

    @include themer($theme-hansreitzel) {
        @each $prop in $props {
            #{$prop}: $color-hr-red;
        }
    }

    @include themer($theme-munksgaard) {
        @each $prop in $props {
            #{$prop}: $color-mg-blue;
        }
    }
}
