@use "sass:math";

.content--inline-block {
    display: inline-block;
}

.content-profile {
    max-width: 43em;
}

.content-p {
    max-width: 42em;
}

.content-p--center {
    margin-left: auto;
    margin-right: auto;
}

.content-em {
    text-transform: uppercase;
    font-weight: $fontweight-gu-bold;
    font-style: normal;
    font-size: 0.9em;
}

.content-text-dimmed {
    opacity: 0.4;
}

.content-rich-text {
    @include mix-rich-text-markup;
}

.content--text-align {
    &-left {
        text-align: left;
    }
    &-center {
        text-align: center;
    }
    &-right {
        text-align: right;
    }
}

.content--intro-paragraph {
    @include mix-theme-intro-paragraph-color;
    font-size: math.div(18, 16) * 1em;
    font-weight: $fontweight-gu-light;
    margin-bottom: 20px;

    @media (min-width: $breakpoint-w-cat) {
        font-size: math.div(20, 16) * 1em;
    }
}
