@use "sass:math";

.app-errors {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    z-index: 200;
}

.app-errors__error {
    @include reset__button("true");
    width: 100%;
    text-align: left;
    color: $color-error;
    position: relative;
    padding: 20px 60px;
    background: #fff;
    border-radius: 5px;
    font-size: 14px;
    font-weight: $fontweight-gu-medium;
    box-shadow: 0px 0px 12px 0px rgba($black, 0.15);
    cursor: pointer;
    margin-top: 15px;
    transition: transform 0.45s ease-in, background-color 250ms ease;
    transform: translateX(0);
    &.entering {
        transform: translateX(calc(100% + 30px));
    }
    &.entered {
        transform: translateX(0);
    }
    &.exiting {
        transform: translateX(calc(100% + 30px));
    }
    &.exited {
        transform: translateX(calc(100% + 30px));
    }

    &:hover:before {
        background-color: tint($color-error, 8%);
    }
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 40px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background: $color-error;
    }
    &:after {
        content: "!";
        font-family: "Georgia", "serif";
        font-weight: 900;
        font-size: math.div(14, 16) * 1rem;
        background-color: #fff;
        display: block;
        position: absolute;
        top: 10px;
        left: 9px;
        width: $gutter * 0.8;
        height: $gutter * 0.8;
        line-height: $gutter * 0.8;
        border-radius: 50%;
        text-align: center;
    }

    p {
        margin: 0;
    }
}

.app-errors__error__close {
    position: absolute;
    top: -5px;
    right: 3px;

    svg {
        stroke: $color-error;
        stroke-width: 2px;
        width: 35px;
    }
}
