@use "sass:math";

.social-share {
    margin: 2em 0;
}

.social-share__text {
    text-transform: uppercase;
    margin-right: math.div($gutter, 2);
    font-size: 14px;
    @include themer($theme-gyldendal) {
        font-weight: $fontweight-gu-bold;
    }
    @include themer($theme-hansreitzel) {
        font-weight: $fontweight-hr-din-bold;
    }
    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-bold;
    }
}

.social-share__iconwrap {
    text-align: center;
    display: inline-block;
    margin: 0 math.div($gutter, 2) 0 0;
    line-height: 1em;
    vertical-align: middle;
    position: relative;
    width: $gutter;

    svg {
        position: absolute;
        left: 0;
        top: 0;
        width: auto;
        max-width: $gutter;
        height: 24px;
        transform: translateY(-55%);
    }

    @include themer($theme-gyldendal) {
        fill: $color-gu-orange;
        stroke: $color-gu-orange;
    }
    @include themer($theme-hansreitzel) {
        fill: $color-hr-red;
        stroke: $color-hr-red;
    }
    @include themer($theme-munksgaard) {
        fill: $color-mg-blue;
        stroke: $color-mg-blue;
    }
}

.social-share__icon-facebook {
}

.social-share__icon-twitter {
}

.social-share__icon-mail {
    stroke-width: 2pt;

    svg {
        height: 20px;
    }
}
