.title-link-top {
    @include mix-grid-section();

    @include mix-content-min-width($breakpoint-w-dog) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.title-link-top__title {
    @include mix-theme-like-h2();
}
