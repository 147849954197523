.input-field {
    display: grid;
    grid-template-rows: auto auto;
    align-self: self-end;
    position: relative;

    label {
        margin-bottom: 1rem;
    }
}

.input-field__field,
.input-field__icon {
    grid-row: 2 / 3;
}

.input-field__field {
    font-family: inherit;
    border: 1px solid $color-gu-fade-20;
    border-radius: $border-radius;
    outline: none;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    transition: 0.3s;
    transition-property: border-color;

    &--error {
        border-color: $color-error;
    }

    &--filled {
        border-color: $color-gu-blue-dusty;
    }

    &--hasIcon {
        padding-right: 2.25rem;
    }

    &:focus {
        @include mix-primary-theme-border(1px, solid);

        @include themer($theme-hansreitzel) {
            border-color: $color-hr-blue;
        }
    }

    &::-webkit-input-placeholder {
        font-size: 0.75rem;
        color: $color-gu-blue-dusty;
        @include themer($theme-gyldendal) {
            font-weight: $fontweight-gu-medium;
        }
        @include themer($theme-hansreitzel) {
            font-weight: $fontweight-hr-din-bold;
        }
        @include themer($theme-munksgaard) {
            font-weight: $fontweight-mg-bold;
        }
    }
}

.input-field__icon {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0.3125rem;
    width: 1.375rem;
    height: 1.375rem;
    margin-block: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-gu-blue-dusty;
    background: none;
    border: none;
    outline: none;

    .tooltip,
    .tooltip__reference-element {
        width: inherit;
        height: inherit;

        svg {
            width: inherit;
            height: inherit;
        }
    }


    &--isButton {
        cursor: pointer;
        &:focus,
        &:hover {
            svg {
                @include mix-theme-primary-color-props(color);
            }
        }
    }
}

.input-field__error-message,
.input-field__helper-text {
    font-size: 0.75rem;
    line-height: 1rem;
    margin-top: 0.375rem;
}

.input-field__error-message {
    color: $color-error;
}
.input-field__helper-text {
    color: $color-gu-blue-dusty;
}
