.custom-slick-slider {
    &:hover .slick-arrow {
        opacity: 1;
    }

    .slick-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        top: calc(50% - 50px);
        bottom: $gutter * 2;
        width: 50px;
        height: 50px;
        border: none;
        color: white;
        opacity: 0;
        transition: opacity 0.3s;
        background-color: white;
        box-shadow: 0px 0px 16px rgb(0 0 0 / 25%);
        border-radius: 100%;

        &.slick-prev {
            left: 20px;
            z-index: 1;
            padding-right: 10px;
        }
        &.slick-next {
            right: 20px;
            padding-left: 10px;
        }
        &.slick-disabled {
            display: none;
        }
        svg {
            fill: transparent;
            stroke-width: 3px;
            width: 7px;
            stroke: $dark-grey;
        }
    }

    .slick-dots {
        text-align: center;
        line-height: 50px;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex !important;
        align-items: center;
        justify-content: center;

        li {
            display: inline-block;
            position: relative;
        }
        button {
            font-size: 0;
            line-height: 0;
            display: block;
            padding: 5px;
            cursor: pointer;
            border: 0;
            outline: none;
            background: transparent;

            &:before {
                content: "";
                display: inline-block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                opacity: 0.2;

                @include themer($theme-gyldendal) {
                    background: $color-gu-blue-dusty;
                }
                @include themer($theme-hansreitzel) {
                    background: $color-hr-red;
                }
                @include themer($theme-munksgaard) {
                    background: $color-mg-blue;
                }
            }
        }
        .slick-active button:before {
            width: 8px;
            height: 8px;
            opacity: 1;
        }
    }
}
