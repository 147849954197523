.create-profile-with-unic {
    h2 {
        @include mix-theme-like-h2;
        margin-bottom: rem(8);
    }

    h5 {
        @include mix-theme-like-h5;
        font-weight: $fontweight-gu-bold;
        text-transform: uppercase;
    }

    p {
        font-size: 1rem;
        line-height: 1.5;
        margin-top: 0.5rem;
    }
}

.create-profile-with-unic__container {
    max-width: 1024px;
}

.create-profile-with-unic__userinfo {
    margin-top: 1rem;
}

.create-profile-with-unic__email,
.create-profile-with-unic__newsletter,
.create-profile-with-unic__submit {
    margin-top: 3rem;
}

.create-profile-with-unic__institutions {
    margin-top: rem(8);
}

.create-profile-with-unic__email {
    label {
        font-weight: $fontweight-gu-bold;
        color: #000;
        text-transform: uppercase;
    }
}

.create-profile-with-unic__submit {
    button {
        @include mix-button-is-slim;
    }
    .gdpr-text {
        margin-top: 1rem;
        font-size: 0.75rem;
        line-height: 1.33;
        a {
            color: inherit;
            text-decoration: underline;
        }
    }
}
