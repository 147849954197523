.banner-newsletter-signup {
    position: relative;
    @include mix-grid-section();
    max-width: $max-width;
    background-color: $color-gu-fade-30;
    border-radius: $border-radius;
    padding: $gutter;
    margin: 1em auto;
    a {
        @include mix-theme-like-a();
    }
    p + p {
        margin-top: 1em;
    }
}

.banner-newsletter-signup__header__h {
    @include mix-theme-like-h2();
    margin-bottom: 0.5em;
}
.banner-newsletter-signup__text {
    color: #fff;
}

@media (min-width: $breakpoint-w-cat) {
    .banner-newsletter-signup {
        @include mix-clearfix-block();
        background-image: url("/dist/assets/gfx/letters.png");
        background-repeat: no-repeat;
        background-position: 5% 15px;
        min-height: 210px;
        padding: $gutter * 1.5;
    }

    .banner-newsletter-signup__header,
    .banner-newsletter-signup__text {
        float: left;
        width: 50%;
    }
    .banner-newsletter-signup__header {
        padding-right: 1em;
    }
    .banner-newsletter-signup__text {
        padding-left: 1em;
    }
}

@media (min-width: $breakpoint-w-rhino) {
    .banner-newsletter-signup {
        background-position: 20% 15px;
    }
}
@media (min-width: $breakpoint-w-elephant) {
    .banner-newsletter-signup {
        background-position: 25% 15px;
    }
}
