.main-footer__blocklinks {
    background-color: $footer-blocklinks-background-color;
    color: $footer-blocklinks-text-color;
}

.main-footer__blocklinks__inner {
    @include mix-grid-section;
    max-width: $max-width;
    margin: auto;
    display: flex;
    flex-flow: row wrap;
    @include mix-content-min-width($breakpoint-w-dog) {
        padding: 0 5%;
    }
}

.footer-blocklink {
    height: 60px;
    color: inherit;
    position: relative;
    display: flex;
    padding-left: 25px;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    font-size: 0.75rem;

    @include mix-content-min-width($breakpoint-w-cat) {
        width: 50%;
    }
    @include mix-content-min-width($breakpoint-w-horse) {
        width: 25%;
    }

    svg {
        display: inline-block;
        height: 1.5em;
        width: 1.5em;
        margin-right: 0.5em;
        margin-bottom: 0.2em;
        vertical-align: middle;
        --footer-social-icon-background-color: #{$white};
        @include themer($theme-gyldendal) {
            --footer-social-icon-text-color: #{$white};
            --footer-social-icon-background-color: #{$color-gu-dark-grey};
        }
        @include themer($theme-hansreitzel) {
            --footer-social-icon-text-color: #{$color-hr-red};
        }
        @include themer($theme-munksgaard) {
            --footer-social-icon-text-color: #{$color-mg-blue};
        }
    }
}

.subtheme--grundskole.footer-blocklink {
    svg {
        --footer-social-icon-background-color: #{$color-gu-green};
    }
}

.subtheme--gymnasiet.footer-blocklink {
    svg {
        --footer-social-icon-background-color: #{$color-gu-blue-light};
    }
}

.subtheme--voksen.footer-blocklink {
    svg {
        --footer-social-icon-background-color: #{$color-gu-pink};
    }
}

.subtheme--ordboeger.footer-blocklink {
    svg {
        --footer-social-icon-background-color: #{$color-gu-red};
    }
}

.subtheme--erhverv.footer-blocklink {
    svg {
        --footer-social-icon-background-color: #{$color-gu-purple};
    }
}
